import _ from 'lodash';

import { useMemo } from 'react';

import { Col, Container, Row, Button, Spinner, Table, Badge, Alert, ListGroup } from 'react-bootstrap';

import { ArrowCounterclockwise } from 'react-bootstrap-icons';

import {
  IFeatureImportanceInfluenceByType,
  IFeatureImportanceVariable,
  IPredictionModelInterface,
} from '../../../services/types';

import {
  useGetPredictionModelFeatureImportanceDataQuery,
  useGetPredictionModelTestDataQuery,
  useGetPredictionModelPredictionDataQuery,
} from '../../../services';

import { formatStudyType, createMarkup } from '../../../utils';

export default function Reporting({
  projectId,
  projectDepvarName,
  model,
  setActiveModeFunction,
}: {
  projectId: string;
  projectDepvarName: string;
  model: IPredictionModelInterface;
  setActiveModeFunction: any;
}) {
  const {
    data: featureImportanceData,
    isFetching: isFetchingFeatureImportanceData,
    isError: isErrorFeatureImportanceData,
  } = useGetPredictionModelFeatureImportanceDataQuery(
    { projectId, modelId: model.id },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: testData,
    isFetching: isFetchingTestData,
    isError: isErrorTestData,
  } = useGetPredictionModelTestDataQuery(
    { projectId, modelId: model.id },
    {
      refetchOnMountOrArgChange: true,
      skip: !model.testFile,
    }
  );

  const {
    data: predictionData,
    isFetching: isFetchingPredictionData,
    isError: isErrorPredictionData,
  } = useGetPredictionModelPredictionDataQuery(
    { projectId, modelId: model.id },
    {
      refetchOnMountOrArgChange: true,
      skip: !model.predictionFile,
    }
  );

  const featureImportanceDataChunks: Array<Array<IFeatureImportanceVariable>> = useMemo(() => {
    if (featureImportanceData?.variables) {
      const pivot = _.ceil(featureImportanceData.variables.length / 2);
      return _.chunk(featureImportanceData?.variables, pivot);
    } else {
      return [[], []];
    }
  }, [featureImportanceData]);

  const studiesInFeatureImportanceData: {
    [key: string]: IFeatureImportanceVariable[];
  } = useMemo(() => {
    if (featureImportanceData?.variables) {
      return _.chain(featureImportanceData.variables).groupBy('study').value();
    } else {
      return {};
    }
  }, [featureImportanceData]);

  return (
    <Container style={{ minHeight: '75vh' }}>
      <Row className="mt-4">
        <Col>
          <h3>Reporte</h3>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            className="d-flex flex-row align-items-center"
            onClick={() => setActiveModeFunction('detail')}
            variant="outline-secondary"
            size="sm"
          >
            <ArrowCounterclockwise size={32} />
            Volver al Modelo
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Descripción de Estadísticas Utilizadas</h4>
          <p>
            Para analizar la calidad de un modelo, se comparan las muestras de sondaje con el contenido de{' '}
            {projectDepvarName} real vs el contenido de {projectDepvarName} predicho por el modelo, a una ley de corte
            definida como ley de interés.
          </p>
          <p>Tener en consideración:</p>
          <ul>
            <li>Precisión: Predichos correctamente sobre (bajo) corte / Predichos sobre (bajo) corte.</li>
            <li>Resolución: Reales sobre (bajo) corte predichos correctamente / Reales sobre (bajo) corte.</li>
            <li>F1: Es el promedio armónico entre Precisión y Resolución.</li>
            <li>
              Influencias: Porcentaje de la varianza de la variable dependiente ({projectDepvarName} en sondajes) que es
              explicada por la variable del modelo.
            </li>
            <li>
              Área de Prueba: Área que representa un 20% de muestras de perforaciones para realizar prueba a ciegas y
              con la cual se configura un modelo.
            </li>
            <li>
              Área de Entrenamiento: Área restante al área de prueba en la que se comparan las leyes predichas con las
              reales.
            </li>
          </ul>
        </Col>
      </Row>
      {isFetchingFeatureImportanceData ? (
        <Row>
          <Col className="loading">
            <Spinner />
          </Col>
        </Row>
      ) : isErrorFeatureImportanceData ? (
        <Alert variant="danger">Lo sentimos: hubo un error al recuperar los estudios y variables.</Alert>
      ) : (
        <Row>
          <Col>
            <h4>Descripción de Modelos Construidos</h4>
            <p>
              A continuación, se describen los estudios utilizados para cada modelo generado y sus variables
              descriptivas:
            </p>
            <ListGroup horizontal className="flex-wrap">
              {Object.keys(studiesInFeatureImportanceData).map((studyName, i) => (
                <ListGroup.Item key={i} style={{ border: 0 }}>
                  <ul>
                    <li>
                      {studyName}{' '}
                      <Badge bg={studiesInFeatureImportanceData[studyName][0].type}>
                        {formatStudyType(studiesInFeatureImportanceData[studyName][0].type.toUpperCase())}
                      </Badge>{' '}
                      <Badge pill bg="light" text="dark">
                        {studiesInFeatureImportanceData[studyName].length}
                      </Badge>
                      <ul>
                        {studiesInFeatureImportanceData[studyName].map((variable, j) => (
                          <li key={j}>{variable.feature}</li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      )}
      {model.notes && (
        <Row className="mb-2">
          <Col>
            <h4>Comentarios sobre los modelos</h4>
            <div dangerouslySetInnerHTML={createMarkup(model.notes)}></div>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <h4>Influencia de Variables</h4>
        </Col>
      </Row>
      {isFetchingFeatureImportanceData ? (
        <Row>
          <Col className="loading">
            <Spinner />
          </Col>
        </Row>
      ) : isErrorFeatureImportanceData ? (
        <Alert variant="danger">Lo sentimos: hubo un error al recuperar la influencia de variables.</Alert>
      ) : (
        <>
          <Row>
            <Col>
              <Table size="sm" hover bordered>
                <thead>
                  <tr className="bg-mf-soft">
                    <th>Variable</th>
                    <th>Influencia (%)</th>
                    <th>Tipo</th>
                    <th>Estudio</th>
                  </tr>
                </thead>
                <tbody>
                  {featureImportanceDataChunks[0].map((variable: IFeatureImportanceVariable, index: number) => (
                    <tr key={`variable-${index}`} className={`bg-${variable.type}`}>
                      <td>{variable.feature}</td>
                      <td>{variable.value}</td>
                      <td>{formatStudyType(variable.type.toUpperCase())}</td>
                      <td>{variable.study}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table size="sm" hover bordered>
                <thead>
                  <tr className="bg-mf-soft">
                    <th>Variable</th>
                    <th>Influencia (%)</th>
                    <th>Tipo</th>
                    <th>Estudio</th>
                  </tr>
                </thead>
                <tbody>
                  {featureImportanceDataChunks[1].map((variable: IFeatureImportanceVariable, index: number) => {
                    return (
                      <tr key={`variable-${index}`} className={`bg-${variable.type}`}>
                        <td>{variable.feature}</td>
                        <td>{variable.value}</td>
                        <td>{formatStudyType(variable.type.toUpperCase())}</td>
                        <td>{variable.study}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table size="sm" className="w-auto" hover bordered>
                <thead>
                  <tr className="bg-mf-soft">
                    <th>Tipo de Variable</th>
                    <th>Influencia Grupal (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {featureImportanceData?.influenceByType.map(
                    (influence: IFeatureImportanceInfluenceByType, index: number) => {
                      return (
                        <tr key={`influence-${index}`} className={`bg-${influence.type}`}>
                          <td>{formatStudyType(influence.type.toUpperCase())}</td>
                          <td>{influence.value}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <h4>Estadísticos</h4>
        </Col>
      </Row>
      {isFetchingTestData ? (
        <Row>
          <Col className="loading">
            <Spinner />
          </Col>
        </Row>
      ) : isErrorTestData ? (
        <Alert variant="danger">Lo sentimos: hubo un error al recuperar los estadísticos de prueba.</Alert>
      ) : (
        <Row>
          {testData && testData.modelType === 'default' && (
            <>
              <Col>
                <Table size="sm" hover bordered className="text-center text-nowrap align-middle">
                  <tbody>
                    <tr>
                      <th rowSpan={2} colSpan={2}>
                        Área de prueba
                      </th>
                      <th colSpan={3}>Predicho</th>
                    </tr>
                    <tr className="bg-mf-soft">
                      <th>{`Ley < ${testData?.depvarCut} ${testData?.depvarUnit} ${testData?.depvarName}`}</th>
                      <th>{`Ley >= ${testData?.depvarCut} ${testData?.depvarUnit} ${testData?.depvarName}`}</th>
                      <th>Total</th>
                    </tr>
                    <tr>
                      <th rowSpan={3}>Real</th>
                      <th className="bg-mf-soft">{`Ley < ${testData?.depvarCut} ${testData?.depvarUnit} ${testData?.depvarName}`}</th>
                      <td>{testData?.ll}</td>
                      <td>{testData?.ul}</td>
                      <td>{testData?.llul}</td>
                    </tr>
                    <tr>
                      <th className="bg-mf-soft">{`Ley >= ${testData?.depvarCut} ${testData?.depvarUnit} ${testData?.depvarName}`}</th>
                      <td>{testData?.lu}</td>
                      <td>{testData?.uu}</td>
                      <td>{testData?.luuu}</td>
                    </tr>
                    <tr>
                      <th className="bg-mf-soft">Total</th>
                      <td>{testData?.lllu}</td>
                      <td>{testData?.uluu}</td>
                      <td>{testData?.total}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col>
                <Table size="sm" hover bordered className="text-nowrap align-middle w-50">
                  <thead>
                    <tr className="text-center bg-mf-soft">
                      <th colSpan={2}>Estadísticos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Precisión</th>
                      <td>{testData?.precisionU}%</td>
                    </tr>
                    <tr>
                      <th>Resolución</th>
                      <td>{testData?.resolutionU}%</td>
                    </tr>
                    <tr>
                      <th>F1</th>
                      <td>{testData?.f1}%</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </>
          )}
          {testData && testData.modelType === 'multiclassifier' && (
            <Col>
              <b>Área de prueba</b>
              <Table size="sm" hover bordered className="text-center text-nowrap align-middle w-75">
                <thead>
                  <tr className="bg-mf-soft">
                    <th></th>
                    <th>Precisión</th>
                    <th>Resolución</th>
                    <th>F1</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(testData.report).map(
                    (key) =>
                      !['accuracy', 'macro avg'].includes(key) && (
                        <tr key={`${key}`}>
                          <td className="bg-mf-soft">{key !== 'weighted avg' ? `class ${key}` : key}</td>
                          <td>{testData.report[key].precision.toFixed(6)}</td>
                          <td>{testData.report[key].recall.toFixed(6)}</td>
                          <td>{testData.report[key]['f1-score'].toFixed(6)}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </Table>
            </Col>
          )}
        </Row>
      )}
      {isFetchingPredictionData ? (
        <Row>
          <Col className="loading">
            <Spinner />
          </Col>
        </Row>
      ) : isErrorPredictionData ? (
        <Alert variant="danger">Lo sentimos: hubo un error al recuperar los estadísticos de entrenamiento.</Alert>
      ) : (
        <Row>
          {predictionData && predictionData.modelType === 'default' && (
            <>
              <Col>
                <Table size="sm" hover bordered className="text-center text-nowrap align-middle">
                  <tbody>
                    <tr>
                      <th rowSpan={2} colSpan={2}>
                        Área de entrenamiento
                      </th>
                      <th colSpan={3}>Predicho</th>
                    </tr>
                    <tr className="bg-mf-soft">
                      <th>{`Ley < ${predictionData?.depvarCut} ${predictionData?.depvarUnit} ${predictionData?.depvarName}`}</th>
                      <th>{`Ley >= ${predictionData?.depvarCut} ${predictionData?.depvarUnit} ${predictionData?.depvarName}`}</th>
                      <th>Total</th>
                    </tr>
                    <tr>
                      <th rowSpan={3}>Real</th>
                      <th className="bg-mf-soft">{`Ley < ${predictionData?.depvarCut} ${predictionData?.depvarUnit} ${predictionData?.depvarName}`}</th>
                      <td>{predictionData?.ll}</td>
                      <td>{predictionData?.ul}</td>
                      <td>{predictionData?.llul}</td>
                    </tr>
                    <tr>
                      <th className="bg-mf-soft">{`Ley >= ${predictionData?.depvarCut} ${predictionData?.depvarUnit} ${predictionData?.depvarName}`}</th>
                      <td>{predictionData?.lu}</td>
                      <td>{predictionData?.uu}</td>
                      <td>{predictionData?.luuu}</td>
                    </tr>
                    <tr>
                      <th className="bg-mf-soft">Total</th>
                      <td>{predictionData?.lllu}</td>
                      <td>{predictionData?.uluu}</td>
                      <td>{predictionData?.total}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col>
                <Table size="sm" hover bordered className="text-nowrap align-middle w-50">
                  <thead>
                    <tr className="text-center bg-mf-soft">
                      <th colSpan={2}>Estadísticos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Precisión</th>
                      <td>{predictionData?.precisionU}%</td>
                    </tr>
                    <tr>
                      <th>Resolución</th>
                      <td>{predictionData?.resolutionU}%</td>
                    </tr>
                    <tr>
                      <th>F1</th>
                      <td>{predictionData?.f1}%</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </>
          )}
          {predictionData && predictionData.modelType === 'multiclassifier' && (
            <Col>
              <b>Área de entrenamiento</b>
              <Table size="sm" hover bordered className="text-center text-nowrap align-middle w-75">
                <thead>
                  <tr className="bg-mf-soft">
                    <th></th>
                    <th>Precisión</th>
                    <th>Resolución</th>
                    <th>F1</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(predictionData.report).map(
                    (key) =>
                      !['accuracy', 'macro avg'].includes(key) && (
                        <tr key={`${key}`}>
                          <td className="bg-mf-soft">{key !== 'weighted avg' ? `class ${key}` : key}</td>
                          <td>{predictionData.report[key].precision.toFixed(6)}</td>
                          <td>{predictionData.report[key].recall.toFixed(6)}</td>
                          <td>{predictionData.report[key]['f1-score'].toFixed(6)}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </Table>
            </Col>
          )}
        </Row>
      )}
    </Container>
  );
}
