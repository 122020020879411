import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

import { IUploadFileS3 } from './types';

import { Storage } from 'aws-amplify';

import { getAccessToken } from './auth';
import { config } from '../config';

const baseUrl = `${config.BACKEND_URL}/api/`;

export const fileUploadApi = createApi({
  reducerPath: 'uploadFileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers: Headers) => {
      const idToken = await getAccessToken();
      headers.set('Authorization', idToken);
      return headers;
    },
  }),
  keepUnusedDataFor: 0.1,
  endpoints: (builder) => ({
    uploadFileToS3: builder.mutation<any, IUploadFileS3>({
      queryFn: async (data: IUploadFileS3) => {
        const url = `${baseUrl}file-uploader/get-upload-file-url/`;
        const form = new FormData();
        form.append('model', data.model);
        form.append('id', data.id);
        form.append('file_attribute_name', data.fileAttributeName);
        form.append('filename', data.file.name);
        try {
          const idToken = await getAccessToken();
          const result = await axios.post(url, form, {
            headers: {
              Authorization: idToken,
            },
          });
          try {
            const logId = result.data.logId;
            await Storage.put(`${result.data.filePath}`, data.file, {
              cacheControl: 'no-cache',
              contentType: data.file.type,
              progressCallback: (progress: any) => {
                data.setUploadProgress((100 * progress.loaded) / progress.total);
              },
            });

            return {
              data: {
                filePath: result.data.filePath,
                filename: data.file.name,
                logId,
              },
            };
          } catch (S3Error) {
            const err: any = S3Error;
            try {
              const idToken = await getAccessToken();
              await axios.post(`${baseUrl}file-uploader/abort-upload-file/`, form, {
                headers: {
                  Authorization: idToken,
                },
              });
            } catch {
              /* TODO: How to handle abort upload file error */
            }
            return {
              error: {
                status: err.response?.status,
                data: err.response?.data.error || err.message,
              },
            };
          }
        } catch (axiosError) {
          const err: any = axiosError;
          return {
            error: {
              status: err.response?.status,
              data: err.response?.data.error || err.message,
            },
          };
        }
      },
    }),
    preprocessingFileInS3: builder.mutation<
      any,
      {
        model: string;
        id: string;
        fileAttributeName: string;
        logId: string;
        spacing: string;
        studyType: string;
        varName: string;
      }
    >({
      queryFn: async ({ model, id, fileAttributeName, logId, spacing, studyType, varName }) => {
        const url = `${baseUrl}file-uploader/preprocessing-request/`;
        const form = new FormData();
        form.append('model', model);
        form.append('id', id);
        form.append('file_attribute_name', fileAttributeName);
        form.append('log_id', logId);
        form.append('spacing', spacing);
        form.append('study_type', studyType);
        form.append('var_name', varName);
        const idToken = await getAccessToken();
        const result = await axios.post(url, form, {
          headers: {
            Authorization: idToken,
          },
        });
        return result;
      },
    }),
    preprocessingStatus: builder.query<any, { logId: string; model: string } | null>({
      query: (data) => {
        const form = new FormData();
        if (data) {
          form.append('log_id', data ? data.logId : '');
          form.append('model', data ? data.model : '');
        }
        return {
          url: 'file-uploader/preprocessing-status/',
          method: 'POST',
          body: form,
        };
      },
      transformResponse: (response: any) => {
        return { state: response.state, message: response.message };
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
  }),
});

export const { useUploadFileToS3Mutation, usePreprocessingFileInS3Mutation, usePreprocessingStatusQuery } =
  fileUploadApi;
