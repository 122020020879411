import { createSlice } from '@reduxjs/toolkit';

import { IAuthStatus } from '../services/types';

const initialState: IAuthStatus = {
  isAuth: false,
  isAdmin: false,
  email: 'no-email',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeAuthState: (state, action) => {
      state.isAuth = action.payload.isAuth;
      state.isAdmin = action.payload.isAdmin;
      state.email = action.payload.email;
    },
  },
});

export const { changeAuthState } = slice.actions;
export default slice.reducer;
