import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux';

import { AuthenticationLayout } from '../../layouts';

import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from 'react-bootstrap/Spinner';
import { Plus, Envelope, X, Trash, PencilSquare, FiletypeJson } from 'react-bootstrap-icons';
import { ReactMultiEmail } from 'react-multi-email';

import ReactCountryFlag from 'react-country-flag';

import DataSets from '../../pages/DataSets/DataSets';
import DataSetDetail from '../DataSetDetail/DataSetDetail';
import CreateDataSet from '../CreateDataSet/CreateDataSet';
import DashboardProjectDetail from '../Dashboard/DashboardProjectDetail';
import DataScience from '../DataScience/DataScience';

import {
  useGetProjectDetailQuery,
  useGetCompanyUsersQuery,
  useCreateProjectResponseMutation,
  useDeleteProjectResponseMutation,
  useSendProjectResponseEmailMutation,
  useRemoveConsolidatedFileMutation,
  useUpdateNotesInConsolidatedFileMutation,
} from '../../services';

import { IConsolidatedFileAssociation, IResponseFile } from '../../services/types';

import { handleInputChange, formatDate, formatCrsCode } from '../../utils';

import './style.css';

interface ProjectDetailProps {
  newDataSet?: boolean;
}

export default function ProjectDetail({ newDataSet = false }: ProjectDetailProps) {
  const params = useParams();
  const auth = useAppSelector((state) => state.auth);
  const projectId: string = params.id ? params.id : '';
  const dataSetId: string = params.dataSetId ? params.dataSetId : '';

  const { data: project, isFetching, isError, refetch: refetchProjects } = useGetProjectDetailQuery(projectId);

  const [activeTab, setActiveTab] = useState<string>('data-sets');
  const [showModalAddResponse, setShowModalAddResponse] = useState<boolean>(false);

  // Response email variables
  const [showModalRecipients, setShowModalRecipients] = useState<boolean>(false);
  const [emailRecipients, setEmailRecipients] = useState<Array<string>>([]);
  const [aditionalEmails, setAditionalEmails] = useState<string[]>([]);

  const [description, setDescription] = useState<string>('');
  const [responseFile, setResponseFile] = useState<File | undefined>(undefined);

  const [
    sendResponseFileTrigger,
    { isLoading: sendingResponse, isError: isErrorSendingResponse, reset: resetSendingResponse },
  ] = useCreateProjectResponseMutation();
  const [deleteResponseTrigger, { isLoading: deletingResponse, isError: isErrorDeletingResponse }] =
    useDeleteProjectResponseMutation();
  const [
    sendResponseEmailTrigger,
    { isLoading: sendingEmail, isError: isErrorSendingEmail, isSuccess: isSuccessSendingEmail },
  ] = useSendProjectResponseEmailMutation();

  const openModalAddResponse = () => {
    resetSendingResponse();
    setShowModalAddResponse(true);
  };
  const closeModalAddResponse = () => {
    setShowModalAddResponse(false);
    setDescription('');
    setResponseFile(undefined);
  };

  const { data: companyMemberEmails } = useGetCompanyUsersQuery(projectId);

  useEffect(() => {
    if (companyMemberEmails) {
      setEmailRecipients(companyMemberEmails);
    }
  }, [companyMemberEmails]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;
    if (files) {
      setResponseFile(files[0]);
    }
  };

  const handleModalRecipients = () => {
    setShowModalRecipients(!showModalRecipients);
  };

  const handleRecipientsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (emailRecipients.includes(e.target.value)) {
      setEmailRecipients(emailRecipients.filter((email: string) => email !== e.target.value));
    } else {
      setEmailRecipients([...emailRecipients, e.target.value]);
    }
  };

  const sendResponseEmail = () => {
    const allRecipients = [...emailRecipients, ...aditionalEmails];
    if (project) {
      sendResponseEmailTrigger({
        projectId: project.id,
        recipients: allRecipients,
      });
    }
    handleModalRecipients();
  };

  const sendResponseFile = () => {
    if (project) {
      const form = new FormData();
      form.append('project', project.id);
      form.append('message', description);
      form.append('response_file', responseFile ? responseFile : '');
      sendResponseFileTrigger(form)
        .unwrap()
        .then(() => {
          closeModalAddResponse();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  //const sendResponseEmail = () => { project && sendResponseEmailTrigger({projectId: project.id}); }

  const deleteResponse = (id: string) => () =>
    deleteResponseTrigger({
      projectResponseId: id,
    });

  const [
    removeConsolidationFileTrigger,
    {
      isLoading: isRemovingConsolidationFile,
      isError: isErrorRemoveConsolidationFile,
      reset: resetRemoveConsolidationFile,
    },
  ] = useRemoveConsolidatedFileMutation();

  const [showRemoveConsolidatedFileConfirmation, setShowRemoveConsolidatedFileConfirmation] = useState<boolean>(false);
  const [consolidatedFileIdInModal, setConsolidatedFileIdInModal] = useState<string>('');
  const [consolidatedFileFinalFileNameInModal, setConsolidatedFileFinalFileNameInModal] = useState<string>('');

  const openRemoveConsolidatedFileAssociationConfirmation = (
    consolidatedFileId: string,
    consolidatedFileFinalFileName: string
  ) => {
    resetRemoveConsolidationFile();
    setConsolidatedFileIdInModal(consolidatedFileId);
    setConsolidatedFileFinalFileNameInModal(consolidatedFileFinalFileName);
    setShowRemoveConsolidatedFileConfirmation(true);
  };
  const closeRemoveConsolidatedFileConfirmation = () => setShowRemoveConsolidatedFileConfirmation(false);

  const removeConsolidationFile = () => {
    removeConsolidationFileTrigger({
      projectId: projectId,
      studyFileId: consolidatedFileIdInModal,
    })
      .unwrap()
      .then(() => {
        closeRemoveConsolidatedFileConfirmation();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [
    updateNotesInConsolidatedFileTrigger,
    {
      isLoading: isUpdatingNotesInConsolidationFile,
      isError: isErrorUpdatingNotesInConsolidationFile,
      reset: resetUpdateNotesInConsolidatedFileTrigger,
    },
  ] = useUpdateNotesInConsolidatedFileMutation();

  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const [associationIdInModal, setAssociationIdInModal] = useState<number>(-1);
  const [associationNotesInModal, setAssociationNotesInModal] = useState<string>('');

  const openNotesModal = (associationId: number, associationNotes: string) => {
    resetUpdateNotesInConsolidatedFileTrigger();
    setAssociationIdInModal(associationId);
    setAssociationNotesInModal(associationNotes);
    setShowNotesModal(true);
  };
  const closeNotesModal = () => setShowNotesModal(false);

  const updateNotes = () => {
    updateNotesInConsolidatedFileTrigger({
      projectId: projectId,
      associationId: associationIdInModal,
      associationNotes: associationNotesInModal,
    })
      .unwrap()
      .then(() => {
        closeNotesModal();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <AuthenticationLayout mustAuth={true} mustAdmin={false}>
      <Container className="px-3 my-3">
        {isError ? <Alert variant="danger">Lo sentimos: hubo un error.</Alert> : <></>}
        {!project && isFetching && (
          <Row className="loading">
            <Col>
              <Spinner />
            </Col>
          </Row>
        )}
        {project && (
          <>
            <Row className="align-items-center">
              <Col>
                <h1>{project.name}</h1>
              </Col>
              <Col>
                <div className="d-flex gap-1 float-end">
                  <Badge bg="secondary">{project.depvarName}</Badge>
                  <Badge bg="secondary">
                    {project.depvarCut} {project.depvarUnit}
                  </Badge>
                  <Badge bg="secondary">{formatCrsCode(project.crsCode)}</Badge>
                  <Badge bg="dark">{project.legibleId}</Badge>
                  <Badge
                    bg=""
                    style={{
                      backgroundColor: project.companyColorHex ? '#' + project.companyColorHex : '',
                    }}
                  >
                    {project.companyLegibleId} <ReactCountryFlag countryCode={project.companyCountryCode} svg />
                  </Badge>
                </div>
              </Col>
            </Row>
            <Tabs id="project-tabs" activeKey={activeTab} onSelect={(key: any) => setActiveTab(key)}>
              <Tab eventKey="data-sets" title="Data Sets">
                {newDataSet ? (
                  <CreateDataSet projectId={project.id}></CreateDataSet>
                ) : !dataSetId ? (
                  <DataSets projectId={project.id}></DataSets>
                ) : (
                  <DataSetDetail
                    changeActiveTab={setActiveTab}
                    projectId={project.id}
                    dataSetId={dataSetId}
                    projectResponsesCount={project.responses.length}
                  ></DataSetDetail>
                )}
              </Tab>
              {auth.isAdmin && (
                <Tab eventKey="consolidated-files" title="Consolidados">
                  <Row className="mt-4">
                    <Col>
                      <h2>Consolidados a utilizar</h2>
                    </Col>
                  </Row>
                  <Row className="border-bottom mt-3">
                    <Col sm="auto">
                      <span className="fw-bold">#</span>
                    </Col>
                    <Col sm="auto">
                      <span className="fw-bold">ID</span>
                    </Col>
                    <Col sm={2}>
                      <span className="fw-bold">Fecha de Creación</span>
                    </Col>
                    <Col sm={3}>
                      <span className="fw-bold">Archivos</span>
                    </Col>
                    <Col>
                      <span className="fw-bold">Notas</span>
                    </Col>
                    <Col sm={1}></Col>
                  </Row>
                  {isFetching ? (
                    <Row>
                      <Col className="centered-horizontally my-1">
                        <Spinner />
                      </Col>
                    </Row>
                  ) : (
                    project.consolidatedFilesAssociations?.map(
                      (association: IConsolidatedFileAssociation, i: number) => {
                        return (
                          <Row key={i} className="border-bottom py-1 bg-light align-items-center">
                            <Col sm="auto">
                              <span>{i + 1}</span>
                            </Col>
                            <Col sm="auto">
                              <span>{association.id}</span>
                            </Col>
                            <Col sm={2}>
                              <span>{formatDate(association.consolidatedFile.createdAt)}</span>
                            </Col>
                            <Col sm={3}>
                              <p className="m-0">
                                Final:{' '}
                                <a download target="_blank" rel="noreferrer" href={association.finalFileURL}>
                                  {association.finalFileFilename}
                                </a>
                              </p>
                              <p className="fw-light m-0">
                                Original:{' '}
                                <a
                                  download
                                  target="_blank"
                                  rel="noreferrer"
                                  href={association.consolidatedFile.fileURL}
                                >
                                  {association.consolidatedFile.filename}
                                </a>
                              </p>
                            </Col>
                            <Col>
                              <span>{association.notes}</span>
                            </Col>
                            <Col sm={'auto'} className="d-flex gap-1">
                              <Button
                                variant="outline-dark"
                                onClick={() => window.open(association.studySentFileURL, '_blank')}
                                disabled={!association.studySentFileURL}
                              >
                                <FiletypeJson />
                              </Button>

                              <Modal show={showNotesModal} onHide={closeNotesModal} centered backdrop="static">
                                <Modal.Header closeButton>
                                  <Modal.Title>Notas</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {isUpdatingNotesInConsolidationFile ? (
                                    <Row>
                                      <Col className="centered-horizontally">
                                        <Spinner />
                                      </Col>
                                    </Row>
                                  ) : !isErrorUpdatingNotesInConsolidationFile ? (
                                    <Form.Control
                                      as="textarea"
                                      rows={2}
                                      value={associationNotesInModal}
                                      onChange={handleInputChange(setAssociationNotesInModal)}
                                      disabled={isUpdatingNotesInConsolidationFile}
                                    />
                                  ) : (
                                    <Row>
                                      <Col>
                                        <Alert variant="danger" className="my-1">
                                          Lo sentimos: hubo un error.
                                        </Alert>
                                      </Col>
                                    </Row>
                                  )}
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    disabled={isUpdatingNotesInConsolidationFile}
                                    onClick={closeNotesModal}
                                  >
                                    Cancelar
                                  </Button>
                                  <Button
                                    variant="primary"
                                    disabled={isUpdatingNotesInConsolidationFile}
                                    onClick={() => updateNotes()}
                                  >
                                    Actualizar
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                              <Button
                                variant="outline-dark"
                                onClick={() => openNotesModal(association.id, association.notes)}
                              >
                                <PencilSquare />
                              </Button>

                              <Modal
                                show={showRemoveConsolidatedFileConfirmation}
                                onHide={closeRemoveConsolidatedFileConfirmation}
                                centered
                                backdrop="static"
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Eliminar Consolidado</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {isRemovingConsolidationFile ? (
                                    <Row>
                                      <Col className="centered-horizontally">
                                        <Spinner />
                                      </Col>
                                    </Row>
                                  ) : !isErrorRemoveConsolidationFile ? (
                                    <span>
                                      ¿Estás seguro que deseas eliminar el archivo consolidado{' '}
                                      <b>{consolidatedFileFinalFileNameInModal}</b>?
                                    </span>
                                  ) : (
                                    <Row>
                                      <Col>
                                        <Alert variant="danger" className="my-1">
                                          Lo sentimos: hubo un error.
                                        </Alert>
                                      </Col>
                                    </Row>
                                  )}
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    disabled={isRemovingConsolidationFile}
                                    onClick={closeRemoveConsolidatedFileConfirmation}
                                  >
                                    Cancelar
                                  </Button>
                                  <Button
                                    variant="primary"
                                    disabled={isRemovingConsolidationFile}
                                    onClick={() => removeConsolidationFile()}
                                  >
                                    Aceptar
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                              <Button
                                variant="outline-danger"
                                onClick={() =>
                                  openRemoveConsolidatedFileAssociationConfirmation(
                                    association.consolidatedFile.id,
                                    association.finalFileFilename
                                  )
                                }
                              >
                                <Trash />
                              </Button>
                            </Col>
                          </Row>
                        );
                      }
                    )
                  )}
                </Tab>
              )}
              <Tab eventKey="graphical-analysis-variables" title="Análisis gráfico de variables">
                <DashboardProjectDetail projectId={projectId} />
              </Tab>
              <Tab eventKey="responses" title="Respuestas">
                <Row className="mt-4">
                  <Col>
                    <h2>Archivos proporcionados por Mineral Forecast</h2>
                  </Col>
                </Row>
                {isErrorDeletingResponse && (
                  <Row>
                    <Col>
                      <Alert variant="danger" className="my-1">
                        Lo sentimos: hubo un error.
                      </Alert>
                    </Col>
                  </Row>
                )}
                <Row className="border-bottom mt-3">
                  <Col>
                    <span className="fw-bold">#</span>
                  </Col>
                  <Col>
                    <span className="fw-bold">Mensaje</span>
                  </Col>
                  <Col>
                    <span className="fw-bold">Fecha</span>
                  </Col>
                  <Col></Col>
                </Row>
                {deletingResponse || isFetching ? (
                  <Row>
                    <Col className="centered-horizontally my-1">
                      <Spinner />
                    </Col>
                  </Row>
                ) : (
                  project.responses.map((responseFile: IResponseFile, i: number) => {
                    return (
                      <Row key={i} className="border-bottom py-1 bg-light">
                        <Col className="d-flex align-items-center">
                          <span>{i + 1}</span>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <span>{responseFile.message}</span>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <span>{formatDate(responseFile.createdAt)}</span>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-around">
                          <Button variant="secondary">
                            <a
                              download
                              target="_blank"
                              rel="noreferrer"
                              className="text-light"
                              href={responseFile.fileURL}
                            >
                              Descargar
                            </a>
                          </Button>
                          {auth.isAdmin && (
                            <Button
                              onClick={deleteResponse(responseFile.id)}
                              disabled={deletingResponse}
                              variant="danger"
                            >
                              Eliminar
                            </Button>
                          )}
                        </Col>
                      </Row>
                    );
                  })
                )}

                {auth.isAdmin && !sendingEmail && (
                  <Row>
                    <Col>
                      {isErrorSendingEmail && (
                        <Alert variant="danger" className="mt-2">
                          Lo sentimos: hubo un error.
                        </Alert>
                      )}
                      {isSuccessSendingEmail && (
                        <Alert variant="success" className="mt-2">
                          Email enviado satisfactoriamente.
                        </Alert>
                      )}
                    </Col>
                  </Row>
                )}
                {auth.isAdmin && (
                  <Row className="my-2 py-2 border-bottom">
                    <Col>
                      <Modal
                        size="xl"
                        show={showModalAddResponse}
                        onHide={closeModalAddResponse}
                        centered
                        backdrop="static"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Agregar respuesta</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {sendingResponse ? (
                            <></>
                          ) : (
                            isErrorSendingResponse && (
                              <Row>
                                <Col>
                                  <Alert variant="danger" className="my-1">
                                    Lo sentimos: hubo un error.
                                  </Alert>
                                </Col>
                              </Row>
                            )
                          )}
                          {sendingResponse ? (
                            <Row>
                              <Col className="centered-horizontally">
                                <Spinner />
                              </Col>
                            </Row>
                          ) : (
                            <>
                              <Row>
                                <Col>Archivo</Col>
                                <Col>
                                  <Form.Control type="file" onChange={handleFileChange} />
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <Col>Descripción</Col>
                                <Col>
                                  <Form.Control
                                    value={description}
                                    onChange={handleInputChange(setDescription)}
                                    type="text-area"
                                  />
                                </Col>
                              </Row>
                            </>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button disabled={sendingResponse} variant="secondary" onClick={closeModalAddResponse}>
                            Cancelar
                          </Button>
                          <Button disabled={responseFile === undefined || sendingResponse} onClick={sendResponseFile}>
                            Cargar
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <Button onClick={openModalAddResponse} className="d-flex align-items-center" variant="light">
                        <Plus size={32} />
                        Agregar respuesta
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="d-flex align-items-center float-end"
                        onClick={handleModalRecipients}
                        disabled={sendingEmail}
                        variant="light"
                      >
                        {sendingEmail ? (
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mx-2"
                          />
                        ) : (
                          <Envelope size={28} className="mx-2" />
                        )}
                        Enviar alerta de email con respuesta
                      </Button>
                    </Col>
                    <Modal show={showModalRecipients} onHide={handleModalRecipients}>
                      <Modal.Header>Seleccionar destinatarios</Modal.Header>
                      <Modal.Body>
                        <Container>
                          {companyMemberEmails?.map((email: string, index: number) => {
                            return (
                              <Row key={index}>
                                <Col>
                                  <Form.Check
                                    value={email}
                                    checked={emailRecipients.includes(email)}
                                    label={email}
                                    onChange={handleRecipientsChange}
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                          <Row className="mt-3">
                            <ReactMultiEmail
                              placeholder="Añadir destinatarios  "
                              emails={aditionalEmails}
                              onChange={(_emails: string[]) => {
                                setAditionalEmails(_emails);
                              }}
                              autoFocus={true}
                              getLabel={(email, index, removeEmail) => {
                                return (
                                  <Row key={index}>
                                    <Col>{email}</Col>
                                    <Col onClick={() => removeEmail(index)}>
                                      <X />
                                    </Col>
                                  </Row>
                                );
                              }}
                            />
                          </Row>
                        </Container>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button onClick={sendResponseEmail}>Enviar correo</Button>
                      </Modal.Footer>
                    </Modal>
                  </Row>
                )}
              </Tab>
              <Tab eventKey="targets-explainability" title="Explicabilidad de targets">
                <DataScience
                  projectId={projectId}
                  projectDepvarName={project.depvarName}
                  modelsData={project.predictionModels}
                  isFetching={isFetching}
                  getProjects={refetchProjects}
                />
              </Tab>
            </Tabs>
          </>
        )}
      </Container>
    </AuthenticationLayout>
  );
}
