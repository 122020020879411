import { IDataSetItem } from '../services/types';

export function sortByString(
  array: Array<IDataSetItem> | undefined,
  attribute: 'userName' | 'dataSetName',
  reverse: boolean
) {
  const sortedArray = array?.sort((a: IDataSetItem, b: IDataSetItem) => {
    return a[attribute].toLowerCase().localeCompare(b[attribute].toLowerCase());
  });
  if (reverse) {
    sortedArray?.reverse();
  }
}

export function sortByDate(array: Array<IDataSetItem> | undefined, reverse: boolean) {
  const sortedArray = array?.sort((a: IDataSetItem, b: IDataSetItem) => {
    const aDate = new Date(a.createdAt);
    const bDate = new Date(b.createdAt);
    return aDate.getTime() - bDate.getTime();
  });
  if (reverse) {
    sortedArray?.reverse();
  }
}
