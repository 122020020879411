import moment from 'moment';
import {
  STUDY_TYPE_DICT,
  BIN_TYPE_DICT,
  SEARCH_MODE_DICT,
  INPUT_FILE_TYPE_DICT,
  STUDY_LOG_STATE_DICT,
} from './constants';

export function formatDate(value: string) {
  if (value) return moment(String(value)).format('DD/MM/YYYY HH:mm');
}

export function validInformationLayerName(value: string): boolean {
  const specialCharacters = /[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/; // space and hyphen removed
  return value.trim() !== '' && !specialCharacters.test(value);
}

export function formatStudyType(value: string) {
  if (!value) return 'No definido';
  return STUDY_TYPE_DICT[value];
}

export function formatBinType(value: string) {
  if (!value) return 'No definido';
  return BIN_TYPE_DICT[value];
}

export function formatSearchMode(value: string | undefined) {
  if (!value) return 'No definido';
  return value in SEARCH_MODE_DICT ? SEARCH_MODE_DICT[value] : value;
}

export function formatInputFileType(value: string | undefined) {
  if (!value) return 'No definido';
  return value in INPUT_FILE_TYPE_DICT ? INPUT_FILE_TYPE_DICT[value] : value;
}

export function formatTaskStatusState(value: number) {
  return value in STUDY_LOG_STATE_DICT ? STUDY_LOG_STATE_DICT[value] : value;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatECSTaskARN(value: string) {
  return value.split('/').slice(-1);
}
