import React, { lazy, Suspense, useState, useEffect } from 'react';

import { Row, Col, Spinner } from 'react-bootstrap';

import { drawEllipsoid, drawCylinder } from '../../utils';

interface NeighborhoodSurfaceProps {
  xAxis: number;
  yAxis: number;
  zAxis: number;
  azimuthDegree: number;
  dipDegree: number;
  volumeKind: string;
  customLayout?: { [key: string]: any };
}

const data: any = [
  {
    x: [0],
    y: [0],
    z: [0],
    type: 'surface',
    mode: 'markers',
    showscale: false,
  },
];

const orientation: 'v' | 'h' = 'v';

const layout = {
  scene: {
    xaxis: { title: 'X - Oeste-Este' },
    yaxis: { title: 'Y - Norte-Sur' },
    zaxis: { title: 'Z' },
    camera: { eye: { x: 0, y: -0.01, z: 2.5 } },
  },
  modebar: {
    orientation: orientation,
  },
};

const Plot = lazy(() => import('react-plotly.js'));

export default function NeighborhoodSurface({
  xAxis,
  yAxis,
  zAxis,
  azimuthDegree,
  dipDegree,
  volumeKind,
  customLayout,
}: NeighborhoodSurfaceProps) {
  const [d, setD] = useState<any>(data);

  useEffect(() => {
    if (volumeKind === 'ellipsoid') {
      setD(drawEllipsoid(xAxis, yAxis, zAxis, azimuthDegree, dipDegree));
    } else {
      setD(drawCylinder(xAxis, yAxis, zAxis, azimuthDegree));
    }
  }, [xAxis, yAxis, zAxis, azimuthDegree, dipDegree, volumeKind]);

  return (
    <Suspense
      fallback={
        <Row className="h-100 align-content-center">
          <Col className="centered-horizontally">
            <Spinner animation="grow" />
          </Col>
        </Row>
      }
    >
      <Plot
        data={d}
        layout={{ ...layout, ...customLayout }}
        style={{ width: '100%', height: 'auto' }}
        config={{ displaylogo: false }}
      />
    </Suspense>
  );
}
