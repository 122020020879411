const awsSettings = {
  Storage: {
    region: process.env['REACT_APP_S3_REGION'],
    bucket: process.env['REACT_APP_S3_BUCKET_NAME'],
  },
  Auth: {
    region: process.env['REACT_APP_COGNITO_AWS_REGION'],
    userPoolId: process.env['REACT_APP_COGNITO_USER_POOL'],
    userPoolWebClientId: process.env['REACT_APP_WEB_CLIENT_ID'],
    identityPoolId: process.env['REACT_APP_COGNITO_IDENTITY_POOL'],
  },
};

export default awsSettings;
