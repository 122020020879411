import React from 'react';
import Plot from 'react-plotly.js';
import { Container } from 'react-bootstrap';

interface WindRoseProps {
  windRoseData: any[];
}

const colorScale = [
  '#FFFF00', // Yellow
  '#FFFA00',
  '#FFF500',
  '#FFF000',
  '#FFEB00',
  '#FFE600',
  '#FFE100',
  '#FFDC00',
  '#FFD700',
  '#FFD200',
  '#FFCD00',
  '#FFC800',
  '#FFC300',
  '#FFBE00',
  '#FFB900',
  '#FFB400',
  '#FFAF00',
  '#FFAA00',
  '#FFA500',
  '#FFA000',
  '#FF9B00',
  '#FF9600',
  '#FF9100',
  '#FF8C00',
  '#FF8700',
  '#FF8200',
  '#FF7D00',
  '#FF7800',
  '#FF7300',
  '#FF6E00',
  '#FF6900',
  '#FF6400',
  '#FF5F00',
  '#FF5A00',
  '#FF5500',
  '#FF5000',
  '#FF4B00',
  '#FF4600',
  '#FF4100',
  '#FF3C00',
  '#FF3700',
  '#FF3200',
  '#FF2D00',
  '#FF2800',
  '#FF2300',
  '#FF1E00',
  '#FF1900',
  '#FF1400',
  '#FF0F00',
  '#FF0A00',
  '#FF0500',
  '#FF0000', // Red
];

export default function WindRose({ windRoseData }: WindRoseProps) {
  const sortedWindRoseData = [...windRoseData].sort((a, b) => {
    const rangeA = a.DEPVAR.split(' - ').map(Number);
    const rangeB = b.DEPVAR.split(' - ').map(Number);
    return rangeA[0] - rangeB[0];
  });

  const depvars = sortedWindRoseData.map((el: any) => el.DEPVAR);
  const uniqueDepvarsArray = Array.from(new Set(depvars)); // Convert Set to Array
  const colorStep = Math.floor(colorScale.length / uniqueDepvarsArray.length);
  const depvarColors = uniqueDepvarsArray.reduce((acc: Record<string, string>, depvar: string, index: number) => {
    const colorIndex = Math.min(index * colorStep, colorScale.length - 1);
    acc[depvar] = colorScale[colorIndex];
    return acc;
  }, {});

  const traces = sortedWindRoseData.reduce((acc, row) => {
    const existingTrace = acc.find((trace: any) => trace.name === row.DEPVAR);
    if (existingTrace) {
      existingTrace.r.push(row.percentage);
      existingTrace.theta.push(row.direction);
    } else {
      acc.push({
        type: 'barpolar',
        name: row.DEPVAR,
        r: [row.percentage],
        theta: [row.direction],
        marker: {
          color: depvarColors[row.DEPVAR],
        },
      });
    }
    return acc;
  }, []);

  const layout = {
    title: {
      text: 'Gráfico de rosa de los vientos',
      font: {
        size: 24,
      },
      x: 0.5,
      y: 0.9,
    },
    polar: {
      radialaxis: {
        ticksuffix: '%',
        tickfont: {
          size: 10,
        },
      },
    },
    colorway: ['Plasma_r'],
  };

  return (
    <Container className="chart">
      <Plot data={traces as any} layout={layout} style={{ width: '100%', height: '100%' }} />
    </Container>
  );
}
