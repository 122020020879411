import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import './styles.css';

interface PopoverInfoProps {
  header: string;
  body: string;
}

export default function PopoverInfo({ header, body }: PopoverInfoProps) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover id="popover-positioned-bottom">
          <Popover.Header as="h3">{header}</Popover.Header>
          <Popover.Body>{body}</Popover.Body>
        </Popover>
      }
    >
      <span className="ms-1">
        <i className="fa fa-info-circle" aria-hidden="true"></i>
      </span>
    </OverlayTrigger>
  );
}
