import React, { useState } from 'react';

import { Row, Col, Button, Modal, Spinner, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Trash, Clipboard2Plus } from 'react-bootstrap-icons';

import { useNavigate } from 'react-router-dom';

import { IDataSetItem } from '../../services/types';
import { useDeactivateDataSetMutation, useDuplicateDataSetMutation } from '../../services';
import { formatDate } from '../../utils';

interface DataSetsRecordsProps {
  projectId?: string;
  dataSets: IDataSetItem[] | undefined;
}

export default function DataSetsRecords({ projectId, dataSets }: DataSetsRecordsProps) {
  const navigate = useNavigate();
  const goToDataSetDetail = (id: string) => () => {
    const dataSetDetailPath = !projectId
      ? `/projects/data-sets-without-project/${id}`
      : `/projects/${projectId}/data-sets/${id}`;
    navigate(dataSetDetailPath);
  };

  const [deactivateDataSetTrigger, { isLoading: deactivating, isError: isErrorDeactivate, reset: resetDeactivate }] =
    useDeactivateDataSetMutation();
  const [duplicateDataSetTrigger, { isLoading: duplicating, isError: isErrorDuplicate, reset: resetDuplicate }] =
    useDuplicateDataSetMutation();

  const deactivateDataSet = (dataSetId: string) => {
    deactivateDataSetTrigger(dataSetId)
      .unwrap()
      .then(() => {
        closeDeleteConfirmation();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const duplicateDataSet = (dataSetId: string) => {
    duplicateDataSetTrigger(dataSetId)
      .unwrap()
      .then(() => {
        closeDuplicateConfirmation();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [showDuplicateConfirmation, setShowDuplicateConfirmation] = useState<boolean>(false);
  const [dataSetIdInModal, setDataSetIdInModal] = useState<string>('');
  const [dataSetNameInModal, setDataSetNameInModal] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataSetIndexInModal, setDataSetIndexInModal] = useState<number>(-1);

  const openDeleteConfirmation = (dataSetId: string, dataSetName: string, dataSetIndex: number) => {
    resetDeactivate();
    setDataSetIdInModal(dataSetId);
    setDataSetNameInModal(dataSetName);
    setDataSetIndexInModal(dataSetIndex);
    setShowDeleteConfirmation(true);
  };
  const closeDeleteConfirmation = () => setShowDeleteConfirmation(false);

  const openDuplicateConfirmation = (dataSetId: string, dataSetName: string, dataSetIndex: number) => {
    resetDuplicate();
    setDataSetIdInModal(dataSetId);
    setDataSetNameInModal(dataSetName);
    setDataSetIndexInModal(dataSetIndex);
    setShowDuplicateConfirmation(true);
  };
  const closeDuplicateConfirmation = () => setShowDuplicateConfirmation(false);

  return (
    <>
      {dataSets?.map((dataSet, index) => {
        return (
          <Row key={dataSet.id} className="data-set-card align-items-center">
            <Col sm="4">
              <div className="mt-1 mb-2">Usuario: {dataSet.userName}</div>
              <div>Fecha: {formatDate(dataSet.createdAt)}</div>
            </Col>
            <Col sm="5">
              <div className="mt-1 mb-2">Nombre del data set: {dataSet.dataSetName}</div>
              <div>Número de estudios: {dataSet.numberOfStudies}</div>
            </Col>
            <Col sm="3" className="d-flex justify-content-end gap-1">
              <Button onClick={goToDataSetDetail(dataSet.id)} variant="light">
                Ver detalles
              </Button>
              <Modal show={showDuplicateConfirmation} onHide={closeDuplicateConfirmation} centered backdrop="static">
                <Modal.Header closeButton>
                  <Modal.Title>Duplicar data set</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {duplicating ? (
                    <Row>
                      <Col className="centered-horizontally">
                        <Spinner />
                      </Col>
                    </Row>
                  ) : !isErrorDuplicate ? (
                    `¿Estás seguro que deseas duplicar el data set ${dataSetNameInModal}?`
                  ) : (
                    <Row>
                      <Col>
                        <Alert variant="danger" className="my-1">
                          Lo sentimos: hubo un error.
                        </Alert>
                      </Col>
                    </Row>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" disabled={duplicating} onClick={closeDuplicateConfirmation}>
                    Cancelar
                  </Button>
                  <Button variant="primary" disabled={duplicating} onClick={() => duplicateDataSet(dataSetIdInModal)}>
                    Duplicar data set
                  </Button>
                </Modal.Footer>
              </Modal>
              <OverlayTrigger
                key="overlay-tooltip-duplicate"
                placement="top"
                overlay={<Tooltip id="tooltip-duplicate`">Duplicar</Tooltip>}
              >
                <Button
                  variant="outline-dark"
                  onClick={() => openDuplicateConfirmation(dataSet.id, dataSet.dataSetName, index)}
                >
                  <Clipboard2Plus />
                </Button>
              </OverlayTrigger>
              <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation} centered backdrop="static">
                <Modal.Header closeButton>
                  <Modal.Title>Eliminar data set</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {deactivating ? (
                    <Row>
                      <Col className="centered-horizontally">
                        <Spinner />
                      </Col>
                    </Row>
                  ) : !isErrorDeactivate ? (
                    `¿Estás seguro que deseas eliminar el data set ${dataSetNameInModal}?`
                  ) : (
                    <Row>
                      <Col>
                        <Alert variant="danger" className="my-1">
                          Lo sentimos: hubo un error.
                        </Alert>
                      </Col>
                    </Row>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" disabled={deactivating} onClick={closeDeleteConfirmation}>
                    Cancelar
                  </Button>
                  <Button variant="danger" disabled={deactivating} onClick={() => deactivateDataSet(dataSetIdInModal)}>
                    Eliminar data set
                  </Button>
                </Modal.Footer>
              </Modal>
              <OverlayTrigger
                key="overlay-tooltip-delete"
                placement="top"
                overlay={<Tooltip id="tooltip-delete`">Eliminar</Tooltip>}
              >
                <Button
                  variant="outline-danger"
                  onClick={() => openDeleteConfirmation(dataSet.id, dataSet.dataSetName, index)}
                >
                  <Trash />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        );
      })}
    </>
  );
}
