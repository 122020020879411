import { useState, useEffect } from 'react';
import { Button, Form, Alert, Row, Col, Container, Accordion } from 'react-bootstrap';

import Dashboard from './Dashboard';
import DrillingsMap from './PlotTypes/DrillingsMap';
import PopoverInfo from './PopoverInfo';

interface GoToDashboardProps {
  projectId: string;
  consolidatedId?: any;
  disabled?: boolean;
}

export default function GoToDashboard({ projectId, consolidatedId, disabled = false }: GoToDashboardProps) {
  const [preNumBins, setPreNumBins] = useState<number>(6);
  const [numBins, setNumBins] = useState<number>(6);
  const [key, setKey] = useState(0); // To force component to re-render
  const [blockIds, setBlockIds] = useState<number[]>([]);
  const [activeKey, setActiveKey] = useState<string | null>(null);

  useEffect(() => {
    if (consolidatedId) {
      goToDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidatedId]);

  const handleDataFromMap = (data: number[]) => {
    setBlockIds(data);
  };

  const goToDashboard = () => () => {
    if (!isNaN(preNumBins) && preNumBins >= 1 && preNumBins <= 1000) {
      setNumBins(preNumBins);
      setKey((k) => k + 1);
    }
  };

  return (
    <>
      {!consolidatedId ? (
        <Alert variant="info">Debes seleccionar un consolidado.</Alert>
      ) : (
        <Container className="bg-light p-3 rounded">
          <Form>
            <Form.Group controlId="newBins" className="mb-3">
              <Form.Label>
                Elegir número de bins
                <PopoverInfo
                  header={'Bins'}
                  body={`Corresponde al número de divisiones en que se agrupan los datos. Por ejemplo, si eliges 5 bins, entonces aparecerán 5 barras.`}
                />
              </Form.Label>
              <Form.Control
                type="number"
                value={preNumBins}
                disabled={!consolidatedId || disabled}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (!isNaN(value) && value >= 1 && value <= 1000) {
                    setPreNumBins(value);
                  }
                }}
                min="1"
                max="1000"
              />
            </Form.Group>
            <Container className="d-flex justify-content-center align-items-center">
              <Accordion className="accordion-custom" activeKey={activeKey}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header onClick={() => setActiveKey(activeKey === '0' ? null : '0')}>
                    Filtro por Area
                  </Accordion.Header>
                  <Accordion.Body>
                    <Container className="d-flex justify-content-center align-items-center">
                      <Row>
                        <Col>
                          <DrillingsMap consolidatedId={consolidatedId} onDataUpdate={handleDataFromMap} />
                        </Col>
                      </Row>
                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>

            <Row className="mt-4">
              <Container className="text-center">
                <Button
                  onClick={goToDashboard()}
                  variant={consolidatedId ? 'success' : 'secondary'}
                  disabled={!consolidatedId || disabled}
                >
                  Actualizar gráficos
                </Button>
              </Container>
            </Row>
          </Form>
        </Container>
      )}
      {consolidatedId && (
        <Dashboard
          key={key}
          projectId={projectId}
          consolidateId={consolidatedId}
          blockIds={blockIds}
          numBins={numBins}
        />
      )}
    </>
  );
}
