import React, { useState } from 'react';
import '../styles.css';

import { Container, Table, Accordion } from 'react-bootstrap';

interface TableProps {
  selectedData: string;
  quantileNeighborsData: any;
  intervalNeighborsData: any;
  naturalBreaksNeighborsData: any;
}
export default function CustomTable({
  selectedData,
  quantileNeighborsData,
  intervalNeighborsData,
  naturalBreaksNeighborsData,
}: TableProps) {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  function formatValues(value: number | null): string {
    if (value === null) {
      return '0';
    }
    const roundedValue = Math.round(value); // Round the value to remove decimals
    return roundedValue.toString();
  }

  return (
    <Container className="border p-4 my-4 text-center">
      <h3>Tabla de vecinos</h3>
      <Accordion className="custom-accordion" onSelect={() => setIsAccordionExpanded(!isAccordionExpanded)}>
        <Accordion.Header>Mostrar tabla de vecinos</Accordion.Header>
        <Accordion.Body>
          <Container>
            <Table className={isAccordionExpanded ? 'neighbors-table' : 'closed-table'} striped bordered hover>
              <thead>
                <tr>
                  <th>Bin</th>
                  <th>Mínimo número de vecinos</th>
                  <th>Número de vecinos promedio</th>
                  <th>Máximo número de vecinos</th>
                </tr>
              </thead>
              <tbody>
                {selectedData === 'quantile' ? (
                  quantileNeighborsData.mins_quantile_data &&
                  quantileNeighborsData.mean_quantile_data &&
                  quantileNeighborsData.maxs_quantile_data ? (
                    quantileNeighborsData.mins_quantile_data.map((value: number, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{formatValues(quantileNeighborsData.mins_quantile_data[index])}</td>
                        <td>{formatValues(quantileNeighborsData.mean_quantile_data[index])}</td>
                        <td>{formatValues(quantileNeighborsData.maxs_quantile_data[index])}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4}>0</td>
                    </tr>
                  )
                ) : selectedData === 'regular' ? (
                  intervalNeighborsData.mins_interval_data &&
                  intervalNeighborsData.mean_interval_data &&
                  intervalNeighborsData.maxs_interval_data ? (
                    intervalNeighborsData.mins_interval_data.map((value: number, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{formatValues(intervalNeighborsData.mins_interval_data[index])}</td>
                        <td>{formatValues(intervalNeighborsData.mean_interval_data[index])}</td>
                        <td>{formatValues(intervalNeighborsData.maxs_interval_data[index])}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4}>0</td>
                    </tr>
                  )
                ) : //Natural breaks
                naturalBreaksNeighborsData.mins_jnb_data &&
                  naturalBreaksNeighborsData.mean_jnb_data &&
                  naturalBreaksNeighborsData.maxs_jnb_data ? (
                  naturalBreaksNeighborsData.mins_jnb_data.map((value: number, index: number) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{formatValues(naturalBreaksNeighborsData.mins_jnb_data[index])}</td>
                      <td>{formatValues(naturalBreaksNeighborsData.mean_jnb_data[index])}</td>
                      <td>{formatValues(naturalBreaksNeighborsData.maxs_jnb_data[index])}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>0</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Container>
        </Accordion.Body>
      </Accordion>
    </Container>
  );
}
