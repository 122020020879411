import { useState, useMemo, useEffect } from 'react';

import { useAppSelector } from '../../../redux';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { Trash, BoxArrowUpRight } from 'react-bootstrap-icons';

import { handleInputChange, formatDate } from '../../../utils';
import {
  useLazyGetPredictionModelQuery,
  useUpdatePredictionModelMutation,
  useDeletePredictionModelMutation,
  useGetPredictionModelDashboardUrlMutation,
  useGetCoordRefSystemQuery,
  useGeneratePredictionModelShapValuesMutation,
  useGetPredictionModelShapValuesStatusQuery,
} from '../../../services';
import {
  IPredictionModelInterface,
  IPredictionModelShapValuesData,
  IEPSGCoordRefSystem,
} from '../../../services/types';

import { FileUploader } from '../../../components';

import { config } from '../../../config';
import { PREDICTION_MODEL_TYPE_DICT } from '../../../utils';

import { Typeahead } from 'react-bootstrap-typeahead';
import Reporting from './Reporting';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import '../style.css';

export default function ModelElement({
  modelData,
  projectId,
  projectDepvarName,
  deleteModelFromList,
}: {
  modelData: IPredictionModelInterface;
  getProjects: () => void;
  projectId: string;
  projectDepvarName: string;
  deleteModelFromList: (modelId: string) => void;
}) {
  const auth: { isAdmin: boolean; isAuth: boolean } = useAppSelector((state) => state.auth);

  const [model, setModel] = useState<IPredictionModelInterface>(modelData);
  const [activeMode, setActiveMode] = useState<'detail' | 'reporting'>('detail');
  const [updatingPipelineFile, setUpdatingPipelineFile] = useState<boolean>(false);
  const [updatingConsolidatedFile, setUpdatingConsolidatedFile] = useState<boolean>(false);
  const [updatingFeatureImportanceFile, setUpdatingFeatureImportanceFile] = useState<boolean>(false);
  const [updatingPredictionFile, setUpdatingPredictionFile] = useState<boolean>(false);
  const [updatingTestFile, setUpdatingTestFile] = useState<boolean>(false);

  const [shapValuesData, setShapValuesData] = useState<IPredictionModelShapValuesData>({ status: '', arn: '' });
  const [error, setError] = useState<string | undefined>(undefined);
  const [isDisplayingDashboard, setIsDisplayingDashboard] = useState<boolean>(false);
  const [name, setName] = useState<string>(model.name || '');
  const [stoppedShapValuesTask, setStoppedShapValuesTask] = useState<boolean>(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const [getPredictionModelTrigger, { isFetching: fetchingModel }] = useLazyGetPredictionModelQuery();
  const [updatePredictionModelTrigger, { isLoading: updatingModel }] = useUpdatePredictionModelMutation();
  const [deletePredictionModelTrigger, { isLoading: deletingModel }] = useDeletePredictionModelMutation();
  const [getUrlTrigger] = useGetPredictionModelDashboardUrlMutation();

  const [
    generateShapValuesTrigger,
    {
      isLoading: isGeneratingShapValues,
      isUninitialized: isUninitializedGenerateShapValues,
      reset: resetGenerateShapValues,
    },
  ] = useGeneratePredictionModelShapValuesMutation();

  const { data: shapValuesStatus, isFetching: isFetchingShapValuesStatus } = useGetPredictionModelShapValuesStatusQuery(
    { taskArn: shapValuesData.arn, projectId },
    {
      skip: !['PENDING', 'RUNNING'].includes(shapValuesData.status),
      pollingInterval: !stoppedShapValuesTask ? 15000 : 0,
    }
  );

  useEffect(() => {
    if (modelData) {
      if (modelData.shapValuesTaskArn) {
        setShapValuesData({
          status: 'PENDING',
          arn: modelData.shapValuesTaskArn,
        });
      }
      if (modelData.notes) {
        const contentStateFromHTML = convertFromHTML(modelData.notes);
        setEditorState(EditorState.createWithContent(contentStateFromHTML));
      }
    }
  }, [modelData]);

  useEffect(() => {
    if (
      shapValuesStatus &&
      ((['STOPPED', 'MISSING'].includes(shapValuesStatus.ecsLastStatus) && shapValuesStatus?.taskLogState !== 0) ||
        shapValuesStatus?.taskLogState >= 2)
    ) {
      setStoppedShapValuesTask(true);
      if (shapValuesStatus?.taskLogState >= 3) {
        setError(`Error en generación de SHAP Values: ${shapValuesStatus.taskLogMessage}`);
      }
      getPredictionModelTrigger({ projectId, modelId: model.id })
        .unwrap()
        .then((response: any) => {
          setModel({
            ...model,
            shapValuesFile: response.shap_values_file,
            shapValuesFilename: response.shap_values_filename,
            shapValuesUpdatedAt: response.shap_values_updated_at,
            shapDrillingsValuesFile: response.shap_drillings_values_file,
            shapDrillingsValuesFilename: response.shap_drillings_values_filename,
            shapDrillingsValuesUpdatedAt: response.shap_drillings_values_updated_at,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapValuesStatus]);

  const keywordsWGS84 = ['wgs', '84'];
  const keywordsPSAD56 = ['psad56'];
  const keywordsSIRGAS = ['sirgas', 'chile'];
  const pageSize = 1000;
  const { data: coordRefSystemsWGS84, isLoading: gettingCoordRefSystemsWGS84 } = useGetCoordRefSystemQuery({
    keywords: keywordsWGS84,
    pageSize,
  });
  const { data: coordRefSystemsPSAD56, isLoading: gettingCoordRefSystemsPSAD56 } = useGetCoordRefSystemQuery({
    keywords: keywordsPSAD56,
    pageSize,
  });
  const { data: coordRefSystemsSIRGAS, isLoading: gettingCoordRefSystemsSIRGAS } = useGetCoordRefSystemQuery({
    keywords: keywordsSIRGAS,
    pageSize,
  });

  const coordRefSystems: Array<IEPSGCoordRefSystem> = useMemo(() => {
    let result: Array<IEPSGCoordRefSystem> = [];
    if (coordRefSystemsWGS84) {
      result = [...result, ...coordRefSystemsWGS84];
    }
    if (coordRefSystemsPSAD56) {
      result = [...result, ...coordRefSystemsPSAD56];
    }
    if (coordRefSystemsSIRGAS) {
      result = [...result, ...coordRefSystemsSIRGAS];
    }
    return result;
  }, [coordRefSystemsWGS84, coordRefSystemsPSAD56, coordRefSystemsSIRGAS]);

  const gettingCoordRefSystems = useMemo(() => {
    return gettingCoordRefSystemsWGS84 || gettingCoordRefSystemsPSAD56 || gettingCoordRefSystemsSIRGAS;
  }, [gettingCoordRefSystemsWGS84, gettingCoordRefSystemsPSAD56, gettingCoordRefSystemsSIRGAS]);

  const onChangeModelType = (event: any) => {
    const form = new FormData();
    form.append('model_type', event.target.value);
    updatePredictionModelTrigger({
      data: form,
      projectId,
      modelId: model.id,
    })
      .unwrap()
      .then(() => {
        setModel({ ...model, modelType: event.target.value });
      });
  };

  const onChangeCrsCode = (selected: Array<any>) => {
    if (selected.length === 1) {
      const newValue = selected[0].code;
      const form = new FormData();
      form.append('crs_code', newValue);
      updatePredictionModelTrigger({
        data: form,
        projectId,
        modelId: model.id,
      })
        .unwrap()
        .then(() => {
          setModel({ ...model, crsCode: newValue });
        });
    }
  };

  const onChangePycaretVersion = (event: any) => {
    const form = new FormData();
    form.append('pycaret_version', event.target.value);
    updatePredictionModelTrigger({
      data: form,
      projectId,
      modelId: model.id,
    })
      .unwrap()
      .then(() => {
        setModel({ ...model, pycaretVersion: event.target.value });
      });
  };

  const onChangeDisplayed = () => {
    const newValue = !model.displayed;
    const form = new FormData();
    form.append('displayed', String(newValue));
    updatePredictionModelTrigger({
      data: form,
      projectId,
      modelId: model.id,
    })
      .unwrap()
      .then(() => {
        setModel({ ...model, displayed: newValue });
      });
  };

  const saveNotes = () => {
    const form = new FormData();
    const notes = convertToHTML(editorState.getCurrentContent());
    form.append('notes', notes);
    updatePredictionModelTrigger({
      data: form,
      projectId,
      modelId: model.id,
    })
      .unwrap()
      .then(() => {
        setModel({ ...model, notes: notes });
      });
  };

  const saveName = () => {
    const form = new FormData();
    form.append('name', name);
    updatePredictionModelTrigger({
      data: form,
      projectId,
      modelId: model.id,
    })
      .unwrap()
      .then(() => {
        setModel({ ...model, name: name });
      });
  };

  const onSuccessUploadFile = () => {
    getPredictionModelTrigger({ projectId, modelId: model.id })
      .unwrap()
      .then((response: any) => {
        setModel({
          id: response.id,
          name: response.name,
          modelType: response.model_type,
          modelFile: response.model_file,
          modelFilename: response.model_filename,
          crsCode: response.crs_code,
          pycaretVersion: response.pycaret_version,
          consolidatedFile: response.X_test_file,
          consolidatedFilename: response.X_test_filename,
          explainerFile: response.explainer_file,
          explainerFilename: response.explainer_filename,
          dashboardFile: response.dashboard_file,
          dashboardFilename: response.dashboard_filename,
          displayed: response.displayed,
          notes: response.notes,
          shapValuesFile: response.shap_values_file,
          shapValuesFilename: response.shap_values_filename,
          shapValuesTaskArn: response.shap_values_task_arn,
          shapValuesUpdatedAt: response.shap_values_updated_at,
          shapDrillingsValuesFile: response.shap_drillings_values_file,
          shapDrillingsValuesFilename: response.shap_drillings_values_filename,
          shapDrillingsValuesUpdatedAt: response.shap_drillings_values_updated_at,
          featureImportanceFile: response.feature_importance_file,
          featureImportanceFilename: response.feature_importance_filename,
          predictionFile: response.prediction_file,
          predictionFilename: response.prediction_filename,
          testFile: response.test_file,
          testFilename: response.test_filename,
          modelAlgorithm: response.model_algorithm,
          numRows: response.row_number,
          numCols: response.col_number,
        });
      });
  };

  const openDashboardInTab = async (serviceUrl: string) => {
    const url = `${config.BACKEND_URL}${serviceUrl}`;
    window.open(url, '_blank', 'noopener,noreferrer');
    setIsDisplayingDashboard(false);
  };

  const displayDashboard = () => {
    setIsDisplayingDashboard(true);
    setError(undefined);

    getUrlTrigger({ projectId, modelId: model.id })
      .unwrap()
      .then((data: any) => {
        openDashboardInTab(data['url']).catch((err) => {
          setError(err.error);
        });
      })
      .finally(() => setIsDisplayingDashboard(false));
  };

  const generateShapValues = () => {
    setError(undefined);
    setStoppedShapValuesTask(false);

    if (isRunningShapValuesTask) {
      return;
    }

    resetGenerateShapValues();

    generateShapValuesTrigger({ projectId, modelId: model.id })
      .unwrap()
      .then((data: IPredictionModelShapValuesData) => {
        setShapValuesData(data);
      })
      .catch((reason: any) => {
        setError('Error en la creación de la tarea para generar SHAP Values. Vuelva a intentar.');
        console.error(reason);
      })
      .finally(() => {});
  };

  const isRunningShapValuesTask: boolean = useMemo(() => {
    return (
      (!isUninitializedGenerateShapValues && isGeneratingShapValues) ||
      shapValuesStatus?.ecsLastStatus === 'PENDING' ||
      (shapValuesStatus?.ecsLastStatus === 'RUNNING' && shapValuesStatus.taskLogState <= 1) ||
      (shapValuesStatus?.ecsLastStatus === 'MISSING' && shapValuesStatus.taskLogState === 0)
    );
  }, [shapValuesStatus, isGeneratingShapValues, isUninitializedGenerateShapValues]);

  const hasPredictionFile: boolean = useMemo(() => {
    return !!modelData.predictionFile;
  }, [modelData.predictionFile]);

  const deletePredictionModel = () => {
    deletePredictionModelTrigger({ projectId, modelId: model.id })
      .unwrap()
      .then(() => {
        deleteModelFromList(model.id);
      });
  };

  return activeMode === 'detail' ? (
    <>
      <Row className="justify-content-between">
        <Col className="d-flex align-items-end gap-1">
          {auth.isAdmin ? (
            <>
              <div>
                <Form.Label>Nombre del modelo:</Form.Label>
                <Form.Control
                  value={name}
                  onChange={handleInputChange(setName)}
                  type="text"
                  className="parameter-input"
                  disabled={
                    isDisplayingDashboard ||
                    isFetchingShapValuesStatus ||
                    isRunningShapValuesTask ||
                    fetchingModel ||
                    updatingPipelineFile ||
                    updatingConsolidatedFile ||
                    updatingModel ||
                    deletingModel ||
                    updatingFeatureImportanceFile ||
                    updatingPredictionFile ||
                    updatingTestFile
                  }
                />
              </div>
              <Button
                className="my-2"
                onClick={saveName}
                disabled={
                  isDisplayingDashboard ||
                  isFetchingShapValuesStatus ||
                  isRunningShapValuesTask ||
                  fetchingModel ||
                  updatingPipelineFile ||
                  updatingConsolidatedFile ||
                  updatingModel ||
                  deletingModel ||
                  updatingFeatureImportanceFile ||
                  updatingPredictionFile ||
                  updatingTestFile
                }
              >
                Modificar nombre
              </Button>
            </>
          ) : (
            <h3>{model.name}</h3>
          )}
        </Col>
        <Col md={1} className="d-flex justify-content-center">
          {(updatingPipelineFile ||
            updatingConsolidatedFile ||
            updatingModel ||
            deletingModel ||
            updatingFeatureImportanceFile ||
            updatingPredictionFile ||
            updatingTestFile) && <Spinner />}
        </Col>
        {auth.isAdmin && (
          <Col className="d-flex flex-row justify-content-end align-items-center gap-4">
            <Form.Check
              label="Visible para el usuario"
              type="switch"
              checked={model.displayed}
              onChange={onChangeDisplayed}
              disabled={
                isDisplayingDashboard ||
                isFetchingShapValuesStatus ||
                isRunningShapValuesTask ||
                fetchingModel ||
                !model.shapValuesFile ||
                updatingPipelineFile ||
                updatingConsolidatedFile ||
                updatingModel ||
                deletingModel ||
                updatingFeatureImportanceFile ||
                updatingPredictionFile ||
                updatingTestFile
              }
            />
            <Button
              variant="outline-danger"
              onClick={deletePredictionModel}
              disabled={
                isDisplayingDashboard ||
                isFetchingShapValuesStatus ||
                isRunningShapValuesTask ||
                fetchingModel ||
                updatingPipelineFile ||
                updatingConsolidatedFile ||
                updatingModel ||
                deletingModel ||
                updatingFeatureImportanceFile ||
                updatingPredictionFile ||
                updatingTestFile
              }
            >
              <Trash />
            </Button>
          </Col>
        )}
      </Row>
      <Row className="my-2">
        <Col>
          <Form.Label>Tipo de modelo: </Form.Label>
          {auth.isAdmin ? (
            <Form.Group>
              {Object.keys(PREDICTION_MODEL_TYPE_DICT).map((key) => (
                <Form.Check
                  type="radio"
                  value={key}
                  checked={model.modelType === key}
                  label={PREDICTION_MODEL_TYPE_DICT[key]}
                  onChange={onChangeModelType}
                  disabled={
                    isDisplayingDashboard ||
                    isFetchingShapValuesStatus ||
                    isRunningShapValuesTask ||
                    fetchingModel ||
                    updatingModel ||
                    updatingPipelineFile ||
                    deletingModel ||
                    updatingFeatureImportanceFile ||
                    updatingPredictionFile ||
                    updatingTestFile
                  }
                  key={`input_prediction_model_type_${key}`}
                />
              ))}
            </Form.Group>
          ) : (
            <span> {PREDICTION_MODEL_TYPE_DICT[model.modelType]}</span>
          )}
        </Col>
        {auth.isAdmin && (
          <Col>
            <Form.Label>Versión Pycaret:</Form.Label>
            <Form.Group>
              <Form.Check
                type="radio"
                value={'3.2.0'}
                checked={model.pycaretVersion === '3.2.0'}
                label={'3.2.0'}
                onChange={onChangePycaretVersion}
                disabled={
                  isDisplayingDashboard ||
                  isFetchingShapValuesStatus ||
                  isRunningShapValuesTask ||
                  fetchingModel ||
                  updatingModel ||
                  updatingPipelineFile ||
                  deletingModel ||
                  updatingFeatureImportanceFile ||
                  updatingPredictionFile ||
                  updatingTestFile
                }
              />
              <Form.Check
                type="radio"
                value={'3.0.3'}
                checked={model.pycaretVersion === '3.0.3'}
                label={'3.0.3'}
                onChange={onChangePycaretVersion}
                disabled={
                  isDisplayingDashboard ||
                  isFetchingShapValuesStatus ||
                  isRunningShapValuesTask ||
                  fetchingModel ||
                  updatingModel ||
                  updatingPipelineFile ||
                  deletingModel ||
                  updatingFeatureImportanceFile ||
                  updatingPredictionFile ||
                  updatingTestFile
                }
              />
            </Form.Group>
          </Col>
        )}
        <Col>
          <Form.Label>Sistema de coordenadas:</Form.Label>
          {gettingCoordRefSystems ? (
            <span>
              <Spinner size="sm" />
            </span>
          ) : auth.isAdmin ? (
            <Typeahead
              id="coord-ref-systems"
              labelKey={(crs: any) => `${crs.name} (${crs.dataSource}:${crs.code})`}
              onChange={onChangeCrsCode}
              options={coordRefSystems}
              defaultSelected={coordRefSystems.filter((crs: any) => crs.code === model.crsCode)}
              disabled={
                isDisplayingDashboard ||
                isFetchingShapValuesStatus ||
                isRunningShapValuesTask ||
                fetchingModel ||
                gettingCoordRefSystems ||
                updatingModel ||
                updatingPipelineFile ||
                deletingModel ||
                updatingFeatureImportanceFile ||
                updatingPredictionFile ||
                updatingTestFile
              }
            />
          ) : (
            coordRefSystems
              .filter((crs: IEPSGCoordRefSystem) => crs.code === model.crsCode)
              .map((crs: IEPSGCoordRefSystem) => (
                <span key={crs.code}>
                  {crs.name} ({crs.dataSource}:{crs.code})
                </span>
              ))
          )}
        </Col>
      </Row>
      {auth.isAdmin && (
        <Row className="my-2">
          <Col>
            <Form.Label>Notas:</Form.Label>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              wrapperClassName="editor-wrapper-class"
              editorClassName="editor-editor-class"
              toolbarClassName="editor-toolbar-class"
              toolbar={{
                options: ['inline', 'blockType', 'list', 'emoji', 'remove', 'history'],
                blockType: {
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                },
              }}
              readOnly={
                isDisplayingDashboard ||
                isFetchingShapValuesStatus ||
                isRunningShapValuesTask ||
                fetchingModel ||
                updatingPipelineFile ||
                updatingConsolidatedFile ||
                updatingModel ||
                deletingModel ||
                updatingFeatureImportanceFile ||
                updatingPredictionFile ||
                updatingTestFile
              }
            />
            <Button
              className="my-2"
              onClick={saveNotes}
              disabled={
                isDisplayingDashboard ||
                isFetchingShapValuesStatus ||
                isRunningShapValuesTask ||
                fetchingModel ||
                updatingPipelineFile ||
                updatingConsolidatedFile ||
                updatingModel ||
                deletingModel ||
                updatingFeatureImportanceFile ||
                updatingPredictionFile ||
                updatingTestFile
              }
            >
              Guardar notas
            </Button>
          </Col>
        </Row>
      )}
      {auth.isAdmin && (
        <Row className="align-items-center justify-content-around">
          <Col>
            <Row className="mt-2">
              <Col>
                <FileUploader
                  fileDescription={
                    <Col xs={2} className="d-flex align-items-center gap-1">
                      Pipeline de modelo
                    </Col>
                  }
                  model="prediction_model"
                  filename={model.modelFilename}
                  fileURL={model.modelFile ? model.modelFile : ''}
                  fileExtension=".pkl"
                  id={model.id}
                  fileAttributeName="model_file"
                  setPerformUpdate={setUpdatingPipelineFile}
                  rowClassName=""
                  preprocessingOnSuccessCallback={onSuccessUploadFile}
                  disabled={
                    isDisplayingDashboard ||
                    isFetchingShapValuesStatus ||
                    isRunningShapValuesTask ||
                    fetchingModel ||
                    updatingPipelineFile ||
                    updatingConsolidatedFile ||
                    updatingModel ||
                    deletingModel ||
                    updatingFeatureImportanceFile ||
                    updatingPredictionFile ||
                    updatingTestFile
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FileUploader
                  fileDescription={
                    <Col xs={2} className="d-flex align-items-center gap-1">
                      Consolidado
                    </Col>
                  }
                  model="prediction_model"
                  filename={model.consolidatedFilename}
                  fileURL={model.consolidatedFile ? model.consolidatedFile : ''}
                  fileExtension=".csv"
                  id={model.id}
                  fileAttributeName="X_test_file"
                  setPerformUpdate={setUpdatingConsolidatedFile}
                  rowClassName=""
                  preprocessingOnSuccessCallback={onSuccessUploadFile}
                  disabled={
                    isDisplayingDashboard ||
                    isFetchingShapValuesStatus ||
                    isRunningShapValuesTask ||
                    fetchingModel ||
                    updatingPipelineFile ||
                    updatingConsolidatedFile ||
                    updatingModel ||
                    deletingModel ||
                    updatingFeatureImportanceFile ||
                    updatingPredictionFile ||
                    updatingTestFile
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FileUploader
                  fileDescription={
                    <Col xs={2} className="d-flex align-items-center gap-1">
                      Feature Importance File
                    </Col>
                  }
                  model="prediction_model"
                  filename={model.featureImportanceFilename}
                  fileURL={model.featureImportanceFile ? model.featureImportanceFile : ''}
                  fileExtension=".csv"
                  id={model.id}
                  fileAttributeName="feature_importance_file"
                  setPerformUpdate={setUpdatingFeatureImportanceFile}
                  rowClassName=""
                  preprocessingOnSuccessCallback={onSuccessUploadFile}
                  disabled={
                    isDisplayingDashboard ||
                    isFetchingShapValuesStatus ||
                    isRunningShapValuesTask ||
                    fetchingModel ||
                    updatingPipelineFile ||
                    updatingConsolidatedFile ||
                    updatingModel ||
                    deletingModel ||
                    updatingFeatureImportanceFile ||
                    updatingPredictionFile ||
                    updatingTestFile
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FileUploader
                  fileDescription={
                    <Col xs={2} className="d-flex align-items-center gap-1">
                      Test File
                    </Col>
                  }
                  model="prediction_model"
                  filename={model.testFilename}
                  fileURL={model.testFile ? model.testFile : ''}
                  fileExtension=".csv"
                  id={model.id}
                  fileAttributeName="test_file"
                  setPerformUpdate={setUpdatingTestFile}
                  rowClassName=""
                  preprocessingOnSuccessCallback={onSuccessUploadFile}
                  disabled={
                    isDisplayingDashboard ||
                    isFetchingShapValuesStatus ||
                    isRunningShapValuesTask ||
                    fetchingModel ||
                    updatingPipelineFile ||
                    updatingConsolidatedFile ||
                    updatingModel ||
                    deletingModel ||
                    updatingFeatureImportanceFile ||
                    updatingPredictionFile ||
                    updatingTestFile
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FileUploader
                  fileDescription={
                    <Col xs={2} className="d-flex align-items-center gap-1">
                      Prediction File
                    </Col>
                  }
                  model="prediction_model"
                  filename={model.predictionFilename}
                  fileURL={model.predictionFile ? model.predictionFile : ''}
                  fileExtension=".csv"
                  id={model.id}
                  fileAttributeName="prediction_file"
                  setPerformUpdate={setUpdatingPredictionFile}
                  rowClassName=""
                  preprocessingOnSuccessCallback={onSuccessUploadFile}
                  disabled={
                    isDisplayingDashboard ||
                    isFetchingShapValuesStatus ||
                    isRunningShapValuesTask ||
                    fetchingModel ||
                    updatingPipelineFile ||
                    updatingConsolidatedFile ||
                    updatingModel ||
                    deletingModel ||
                    updatingFeatureImportanceFile ||
                    updatingPredictionFile ||
                    updatingTestFile
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row className="my-3 justify-content-center">
        {auth.isAdmin && (
          <Col md={3}>
            {isFetchingShapValuesStatus || isRunningShapValuesTask ? (
              <Spinner />
            ) : (
              auth.isAdmin && (
                <>
                  <Button
                    onClick={generateShapValues}
                    disabled={
                      isDisplayingDashboard ||
                      !model.modelFile ||
                      !model.consolidatedFile ||
                      !model.modelType ||
                      updatingPipelineFile ||
                      updatingConsolidatedFile ||
                      updatingModel ||
                      deletingModel ||
                      updatingFeatureImportanceFile ||
                      updatingPredictionFile ||
                      updatingTestFile ||
                      fetchingModel
                    }
                  >
                    Generar SHAP Values
                  </Button>
                  {(model.shapValuesUpdatedAt || model.shapDrillingsValuesUpdatedAt) && (
                    <p className="form-text">
                      Última generación:
                      <br />
                      Grilla: {formatDate(model.shapValuesUpdatedAt || '')}
                      <br />
                      Sondajes: {formatDate(model.shapDrillingsValuesUpdatedAt || '')}
                    </p>
                  )}
                </>
              )
            )}
          </Col>
        )}
        <Col md={3}>
          {isDisplayingDashboard ? (
            <Spinner />
          ) : auth.isAdmin ? (
            <Button
              className="d-flex align-items-center gap-1"
              onClick={displayDashboard}
              disabled={
                isDisplayingDashboard ||
                isFetchingShapValuesStatus ||
                isRunningShapValuesTask ||
                fetchingModel ||
                !model.modelFile ||
                !model.consolidatedFile ||
                !model.modelType ||
                !model.shapValuesFile ||
                updatingPipelineFile ||
                updatingConsolidatedFile ||
                updatingModel ||
                deletingModel ||
                updatingFeatureImportanceFile ||
                updatingPredictionFile ||
                updatingTestFile
              }
            >
              Ver dashboard <BoxArrowUpRight />
            </Button>
          ) : (
            <Button
              className="d-flex align-items-center gap-1"
              onClick={displayDashboard}
              disabled={
                isDisplayingDashboard ||
                updatingPipelineFile ||
                updatingConsolidatedFile ||
                updatingModel ||
                deletingModel ||
                updatingFeatureImportanceFile ||
                updatingPredictionFile ||
                updatingTestFile
              }
            >
              Ver dashboard <BoxArrowUpRight />
            </Button>
          )}
        </Col>
        <Col md={3}>
          {fetchingModel ? (
            <Spinner />
          ) : auth.isAdmin ? (
            <Button
              className="d-flex align-items-center gap-1"
              onClick={() => setActiveMode('reporting')}
              disabled={
                fetchingModel ||
                !model.featureImportanceFile ||
                updatingPipelineFile ||
                updatingConsolidatedFile ||
                updatingModel ||
                deletingModel ||
                updatingFeatureImportanceFile ||
                updatingPredictionFile ||
                updatingTestFile
              }
            >
              Ver reporte
            </Button>
          ) : (
            <Button
              className="d-flex align-items-center gap-1"
              onClick={() => setActiveMode('reporting')}
              disabled={
                fetchingModel ||
                updatingPipelineFile ||
                updatingConsolidatedFile ||
                updatingModel ||
                deletingModel ||
                updatingFeatureImportanceFile ||
                updatingPredictionFile ||
                updatingTestFile
              }
            >
              Ver reporte
            </Button>
          )}
        </Col>
        <Col md={3}>
          {fetchingModel ? (
            <Spinner />
          ) : (
            <Button
              href={`/projects/${projectId}/prediction-visualization/${model.id}`}
              target="_blank"
              className="text-center"
              disabled={!hasPredictionFile}
            >
              Visualizar predicciones
            </Button>
          )}
        </Col>
      </Row>
      {error && <Alert variant="danger">{error}</Alert>}
    </>
  ) : (
    <Reporting
      projectId={projectId}
      projectDepvarName={projectDepvarName}
      model={model}
      setActiveModeFunction={setActiveMode}
    ></Reporting>
  );
}
