import React, { useState, useMemo } from 'react';

import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Modal,
  Form,
  Accordion,
  Card,
  Collapse,
  Spinner,
  Alert,
  Table,
} from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { CheckCircle, DashCircle, XCircle, ExclamationCircle, SlashCircle, PlayCircle } from 'react-bootstrap-icons';
import { useAppSelector } from '../../redux/hooks';
import {
  useDeleteStudyResponseMutation,
  useAddConsolidatedFileMutation,
  useForceSendStudyMutation,
  useForceSendStudiesMutation,
  useForceConsolidationStudyFilesMutation,
} from '../../services';
import {
  IConsolidated,
  IConsolidatedFile,
  IResponseFile,
  ITaskStatus,
  IConsolidationTaskStatus,
  IStudy,
} from '../../services/types';
import {
  formatDate,
  formatSearchMode,
  formatStudyType,
  formatInputFileType,
  formatTaskStatusState,
  formatECSTaskARN,
  getConsolidationAlgorithm,
  INVERSE_WEIGHTED_DISTANCE,
  NEAREST_NEIGHBOR,
  NEAREST_NEIGHBOR_2D,
  NEAREST_NEIGHBOR_3D,
  QUADRANT_AVERAGE_2D,
  QUADRANT_AVERAGE_3D,
  FAST_INVERSE_WEIGHTED_DISTANCE,
} from '../../utils';

import './style.css';

interface ResultProps {
  result: IConsolidated;
  projectId: string;
  dataSetId: string;
  index: number;
  tasksStatus?: ITaskStatus[];
  gettingTasksStatus: boolean;
  consolidationTasksStatus?: IConsolidationTaskStatus[];
  gettingConsolidationTasksStatus: boolean;
  dataSetStudies?: IStudy[];
}

export default function Result({
  result,
  projectId,
  dataSetId,
  index,
  tasksStatus,
  gettingTasksStatus,
  consolidationTasksStatus,
  gettingConsolidationTasksStatus,
  dataSetStudies,
}: ResultProps) {
  const auth: any = useAppSelector((state) => state.auth);
  const [showDataUsed, setShowDataUsed] = useState<boolean>(false);
  const [showTasksStatus, setShowTasksStatus] = useState<boolean>(false);

  const [deleteResponseTrigger, { isLoading: deletingResponse, isError: isErrorDeletingResponse }] =
    useDeleteStudyResponseMutation();

  const handleDataUsed = () => setShowDataUsed(!showDataUsed);
  const handleTasksStatus = () => setShowTasksStatus(!showTasksStatus);

  const deleteResponse = (id: string) => () =>
    deleteResponseTrigger({
      studyResponseId: id,
      studyRequestId: result.studyRequestId,
    });

  const [
    addConsolidatedFileTrigger,
    { isLoading: addingConsolidatedFile, isError: isErrorAddingConsolidatedFile, reset: resetAddConsolidatedFile },
  ] = useAddConsolidatedFileMutation();

  const addConsolidatedFile = (consolidatedFileId: string) => {
    addConsolidatedFileTrigger({ projectId, dataSetId, consolidatedFileId })
      .unwrap()
      .then(() => {
        closeAddConsolidatedFileConfirmation();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [showAddConsolidatedFileConfirmation, setShowAddConsolidatedFileConfirmation] = useState<boolean>(false);
  const [consolidatedFileIdInModal, setConsolidatedFileIdInModal] = useState<string>('');
  const [consolidatedFileNameInModal, setConsolidatedFileNameInModal] = useState<string>('');

  const openAddConsolidatedFileConfirmation = (consolidatedFileId: string, consolidatedFileName: string) => {
    resetAddConsolidatedFile();
    setConsolidatedFileIdInModal(consolidatedFileId);
    setConsolidatedFileNameInModal(consolidatedFileName);
    setShowAddConsolidatedFileConfirmation(true);
  };
  const closeAddConsolidatedFileConfirmation = () => setShowAddConsolidatedFileConfirmation(false);

  const [showForcedSendingConfirmation, setForcedSendingConfirmation] = useState<boolean>(false);
  const [studySentIdInModal, setStudySentIdInModal] = useState<string>('');
  const [studyIdInModal, setStudyIdInModal] = useState<string>('');
  const [studyNameInModal, setStudyNameInModal] = useState<string>('');

  const [
    forceSendStudyTrigger,
    { isLoading: isForceSendingStudy, isError: isErrorForceSendingStudy, reset: resetForceSendStudy },
  ] = useForceSendStudyMutation();

  const openForceSendStudyConfirmation = (studySentId: string, studyId: string, studyName: string) => {
    resetForceSendStudy();
    setStudySentIdInModal(studySentId);
    setStudyIdInModal(studyId);
    setStudyNameInModal(studyName);
    setForcedSendingConfirmation(true);
  };
  const closeForceSendStudyConfirmation = () => setForcedSendingConfirmation(false);

  const forceSending = () => {
    forceSendStudyTrigger({
      studyRequestId: dataSetId,
      studySentId: studySentIdInModal,
      studyId: studyIdInModal,
    })
      .unwrap()
      .then(() => {
        closeForceSendStudyConfirmation();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [showForceSendStudiesConfirmation, setShowForceSendStudiesConfirmation] = useState<boolean>(false);
  const [studySentIdInForceSendStudiesModal, setStudySentIdInForceSendStudiesModal] = useState<string>('');
  const [studiesIdsInForceSendStudiesModal, setStudiesIdsInForceSendStudiesModal] = useState<Array<string>>([]);

  const [
    forceSendStudiesTrigger,
    { isLoading: isForcingSendStudies, isError: isErrorForceSendStudies, reset: resetForceSendStudies },
  ] = useForceSendStudiesMutation();

  const openForceSendStudiesConfirmation = (studySentId: string) => {
    resetForceSendStudies();
    setStudySentIdInForceSendStudiesModal(studySentId);
    setStudiesIdsInForceSendStudiesModal([]);
    setShowForceSendStudiesConfirmation(true);
  };
  const closeForceSendStudiesConfirmation = () => setShowForceSendStudiesConfirmation(false);

  const forceSendStudies = () => {
    forceSendStudiesTrigger({
      studyRequestId: dataSetId,
      studySentId: studySentIdInForceSendStudiesModal,
      studiesIds: studiesIdsInForceSendStudiesModal,
    })
      .unwrap()
      .then(() => {
        closeForceSendStudiesConfirmation();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleStudyIdCheckbox = (studyId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = studiesIdsInForceSendStudiesModal.indexOf(studyId);
    let newStudiesIds = [...studiesIdsInForceSendStudiesModal];

    if (e.target.checked) {
      if (index === -1) {
        newStudiesIds = [...newStudiesIds, studyId];
      }
    } else {
      if (index !== -1) {
        newStudiesIds.splice(index, 1);
      }
    }
    setStudiesIdsInForceSendStudiesModal(newStudiesIds);
  };

  const [showForceConsolidationStudyFilesConfirmation, setShowForceConsolidationStudyFilesConfirmation] =
    useState<boolean>(false);
  const [studySentIdInForceConsolidationStudyFilesModal, setStudySentIdInForceConsolidationStudyFilesModal] =
    useState<string>('');
  const [studyFilesIdsInForceConsolidationStudyFilesModal, setStudyFilesIdsInForceConsolidationStudyFilesModal] =
    useState<Array<string>>([]);
  const [
    studyFilesStudyIdsInForceConsolidationStudyFilesModal,
    setStudyFilesStudyIdsInForceConsolidationStudyFilesModal,
  ] = useState<Array<string>>([]);

  const [
    forceConsolidationStudyFilesTrigger,
    {
      isLoading: isForcingConsolidationStudyFiles,
      isError: isErrorForceConsolidationStudyFiles,
      reset: resetForceConsolidationStudyFiles,
    },
  ] = useForceConsolidationStudyFilesMutation();

  const openForceConsolidationStudyFilesConfirmation = (studySentId: string) => {
    resetForceConsolidationStudyFiles();
    setStudySentIdInForceConsolidationStudyFilesModal(studySentId);
    setStudyFilesIdsInForceConsolidationStudyFilesModal([]);
    setStudyFilesStudyIdsInForceConsolidationStudyFilesModal([]);
    setShowForceConsolidationStudyFilesConfirmation(true);
  };
  const closeForceConsolidationStudyFilesConfirmation = () => setShowForceConsolidationStudyFilesConfirmation(false);

  const forceConsolidationStudyFiles = () => {
    forceConsolidationStudyFilesTrigger({
      studyRequestId: dataSetId,
      studySentId: studySentIdInForceConsolidationStudyFilesModal,
      studyFilesIds: studyFilesIdsInForceConsolidationStudyFilesModal,
    })
      .unwrap()
      .then(() => {
        closeForceConsolidationStudyFilesConfirmation();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleStudyFileIdCheckbox =
    (studyFileId: string, studyId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const indexStudyFileId = studyFilesIdsInForceConsolidationStudyFilesModal.indexOf(studyFileId);
      const indexStudyId = studyFilesStudyIdsInForceConsolidationStudyFilesModal.indexOf(studyId);

      let newStudyFilesIds = [...studyFilesIdsInForceConsolidationStudyFilesModal];
      let newStudyFilesStudyIds = [...studyFilesStudyIdsInForceConsolidationStudyFilesModal];

      if (e.target.checked) {
        if (indexStudyFileId === -1) {
          newStudyFilesIds = [...newStudyFilesIds, studyFileId];
        }
        if (indexStudyId === -1) {
          newStudyFilesStudyIds = [...newStudyFilesStudyIds, studyId];
        }
      } else {
        if (indexStudyFileId !== -1) {
          newStudyFilesIds.splice(indexStudyFileId, 1);
        }
        if (indexStudyId !== -1) {
          newStudyFilesStudyIds.splice(indexStudyId, 1);
        }
      }
      setStudyFilesIdsInForceConsolidationStudyFilesModal(newStudyFilesIds);
      setStudyFilesStudyIdsInForceConsolidationStudyFilesModal(newStudyFilesStudyIds);
    };

  const getTaskStatusIcon = (state: number) => {
    const iconSize = 18;
    switch (state) {
      case 0:
        return <SlashCircle size={iconSize}></SlashCircle>;
      case 1:
        return <PlayCircle size={iconSize}></PlayCircle>;
      case 2:
        return <CheckCircle color="green" size={iconSize}></CheckCircle>;
      case 3:
        return <XCircle color="red" size={iconSize}></XCircle>;
      case 4:
        return <ExclamationCircle color="red" size={iconSize}></ExclamationCircle>;
      case 5:
        return <DashCircle size={iconSize}></DashCircle>;
      default:
        return null;
    }
  };

  const thereAreRunningTasks = useMemo(() => {
    return (
      tasksStatus?.some((taskStatus) => taskStatus.state < 2) ||
      consolidationTasksStatus?.some((consolidationTaskStatus) => consolidationTaskStatus.state < 2)
    );
  }, [tasksStatus, consolidationTasksStatus]);

  return (
    <Card>
      <Card.Header as={Button} variant="light" onClick={useAccordionButton(index.toString())}>
        Resultado generado el {formatDate(result.createdAt)}
        <Badge pill bg="dark" className="ms-2">
          {formatInputFileType(result.inputFileType)}
        </Badge>
        {result.responseFiles.length > 0 && (
          <Badge pill bg="dark" className="ms-2">
            Archivos: {result.responseFiles.length}
          </Badge>
        )}
        {auth.isAdmin && <span> - [{result.id}]</span>}
      </Card.Header>
      <Accordion.Collapse eventKey={index.toString()}>
        <Card.Body>
          <Row md={4}>
            <Col>
              <Button
                className="ms-2 w-100"
                variant="secondary"
                onClick={handleDataUsed}
                aria-controls={'collapse-results-' + result.id}
                aria-expanded={showDataUsed}
              >
                {showDataUsed ? 'Esconder datos utilizados' : 'Mostrar datos utilizados'}
              </Button>
            </Col>
            {auth.isAdmin && (
              <Col>
                <Button
                  className="ms-2 w-100"
                  variant="secondary"
                  onClick={handleTasksStatus}
                  aria-controls={'collapse-tasks-status-' + result.id}
                  aria-expanded={showTasksStatus}
                >
                  {showTasksStatus ? 'Esconder estado de tareas' : 'Mostrar estado de tareas'}
                </Button>
              </Col>
            )}
          </Row>
          {auth.isAdmin && (
            <Collapse in={showTasksStatus}>
              <div id={'collapse-tasks-status-' + result.id} className="m-2">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col className="d-flex gap-1">
                        <h4>Tareas de Procesamiento por Estudio</h4>
                        {gettingTasksStatus && <Spinner />}
                      </Col>
                    </Row>
                    <Row className="border-bottom">
                      <Col sm="auto">
                        <span className="fw-bold">#</span>
                      </Col>
                      <Col>
                        <span className="fw-bold">Estudio</span>
                      </Col>
                      <Col sm={3}>
                        <span className="fw-bold">Task ARN</span>
                      </Col>
                      <Col>
                        <span className="fw-bold">Fecha de creación</span>
                      </Col>
                      <Col>
                        <span className="fw-bold">ECS Status</span>
                      </Col>
                      <Col>
                        <span className="fw-bold">Internal Status</span>
                      </Col>
                      <Col>
                        <span className="fw-bold">Acciones</span>
                      </Col>
                    </Row>
                    {tasksStatus?.map((taskStatus, i) => {
                      return (
                        <Row key={i} className="border-bottom py-1 bg-light">
                          <Col sm="auto">
                            <span>{i + 1}</span>
                          </Col>
                          <Col>
                            <span>{taskStatus.studyName}</span>
                          </Col>
                          <Col sm={3} className="text-truncate">
                            <span>{taskStatus.taskArn ? formatECSTaskARN(taskStatus.taskArn) : ''}</span>
                          </Col>
                          <Col>
                            <span>{formatDate(taskStatus.createdAt)}</span>
                          </Col>
                          <Col>
                            <span>{taskStatus.ecsLastStatus}</span>
                          </Col>
                          <Col>
                            <span>
                              {getTaskStatusIcon(taskStatus.state)} {formatTaskStatusState(taskStatus.state)}
                            </span>
                          </Col>
                          <Col>
                            <Modal
                              show={showForcedSendingConfirmation}
                              onHide={closeForceSendStudyConfirmation}
                              centered
                              backdrop="static"
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Forzar procesamiento de estudio</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {isForceSendingStudy ? (
                                  <Row>
                                    <Col className="centered-horizontally">
                                      <Spinner />
                                    </Col>
                                  </Row>
                                ) : !isErrorForceSendingStudy ? (
                                  <span>
                                    ¿Estás seguro que deseas forzar el procesamiento del estudio{' '}
                                    <b>{studyNameInModal}</b>?
                                  </span>
                                ) : (
                                  <Row>
                                    <Col>
                                      <Alert variant="danger" className="my-1">
                                        Lo sentimos: hubo un error.
                                      </Alert>
                                    </Col>
                                  </Row>
                                )}
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  disabled={isForceSendingStudy}
                                  onClick={closeForceSendStudyConfirmation}
                                >
                                  Cancelar
                                </Button>
                                <Button variant="primary" disabled={isForceSendingStudy} onClick={() => forceSending()}>
                                  Forzar procesamiento
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <Button
                              variant="light"
                              onClick={() =>
                                openForceSendStudyConfirmation(
                                  taskStatus.studySentId,
                                  taskStatus.studyId,
                                  taskStatus.studyName
                                )
                              }
                              disabled={isForceSendingStudy || taskStatus.state <= 2}
                            >
                              Forzar procesamiento
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                    <Row className="mt-3">
                      <Col className="d-flex gap-1">
                        <h4>Tareas de Consolidación</h4>
                        {gettingConsolidationTasksStatus && <Spinner />}
                      </Col>
                    </Row>
                    <Row className="border-bottom">
                      <Col sm="auto">
                        <span className="fw-bold">#</span>
                      </Col>
                      <Col sm={3}>
                        <span className="fw-bold">Task ARN</span>
                      </Col>
                      <Col>
                        <span className="fw-bold">Fecha de creación</span>
                      </Col>
                      <Col>
                        <span className="fw-bold">ECS Status</span>
                      </Col>
                      <Col>
                        <span className="fw-bold">Internal Status</span>
                      </Col>
                    </Row>
                    {consolidationTasksStatus?.map((taskStatus, i) => {
                      return (
                        <Row key={i} className="border-bottom py-1 bg-light">
                          <Col sm="auto">
                            <span>{i + 1}</span>
                          </Col>
                          <Col sm={3} className="text-truncate">
                            <span>{taskStatus.taskArn ? formatECSTaskARN(taskStatus.taskArn) : ''}</span>
                          </Col>
                          <Col>
                            <span>{formatDate(taskStatus.createdAt)}</span>
                          </Col>
                          <Col>
                            <span>{taskStatus.ecsLastStatus}</span>
                          </Col>
                          <Col>
                            <span>
                              {getTaskStatusIcon(taskStatus.state)} {formatTaskStatusState(taskStatus.state)}
                            </span>
                          </Col>
                        </Row>
                      );
                    })}
                  </Card.Body>
                </Card>
              </div>
            </Collapse>
          )}
          <Collapse in={showDataUsed}>
            <div id={'collapse-results-' + result.id} className="m-2">
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <h5>
                        Archivo utilizado:{' '}
                        <a download target="_blank" rel="noreferrer" href={result.fileUsedURL}>
                          {result.fileUsedFilename}
                        </a>
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5>
                        Tipo de Archivo: {formatInputFileType(result.inputFileType)}{' '}
                        {result.automaticGrid ? <Badge>Automática</Badge> : <></>}
                      </h5>
                    </Col>
                  </Row>
                  {auth.isAdmin && (
                    <Row>
                      <Col>
                        <h5>Modo de búsqueda: {formatSearchMode(result.searchMode)}</h5>
                      </Col>
                    </Row>
                  )}

                  {result.studiesUsed.map((study, i) => {
                    return (
                      <Container key={i} className="mt-3">
                        <Row>
                          <Col className="fs-4">
                            {study.name}{' '}
                            <Badge bg={study.parameters.studyType.toLowerCase()}>
                              {formatStudyType(study.parameters.studyType)}
                            </Badge>{' '}
                            {study.includedAt && <Badge>Enviado el: {formatDate(study.includedAt)}</Badge>}
                          </Col>
                          {auth.isAdmin && (
                            <Col>
                              Algoritmo de consolidación: {getConsolidationAlgorithm(study.consolidationAlgorithm)}
                            </Col>
                          )}
                        </Row>
                        {study.consolidationAlgorithm === INVERSE_WEIGHTED_DISTANCE ||
                        study.consolidationAlgorithm === FAST_INVERSE_WEIGHTED_DISTANCE ? (
                          <>
                            <Row>
                              <Col className="fs-5">
                                Figura: {study.parameters.volumeKind === 'cylinder' ? 'Cilindro' : 'Elipsoide'}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Row>
                                  <Col className="text-secondary">Factor inverso de la distancia</Col>
                                  <Col className="fw-bold">{study.parameters.m}</Col>
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  <Col className="text-secondary">Eje dirección Este (X) en metros</Col>
                                  <Col className="fw-bold">{study.parameters.semiMajorAxis}</Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Row>
                                  <Col className="text-secondary">Eje dirección Norte (Y) en metros</Col>
                                  <Col className="fw-bold">
                                    {study.parameters.volumeKind === 'cylinder'
                                      ? study.parameters.semiMinorAxis
                                      : study.parameters.semiMediumAxis}
                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  <Col className="text-secondary">
                                    {study.parameters.volumeKind === 'cylinder'
                                      ? 'Altura en metros'
                                      : 'Eje dirección Cota (Z) en metros'}
                                  </Col>
                                  <Col className="fw-bold">
                                    {study.parameters.volumeKind === 'cylinder'
                                      ? study.parameters.height
                                      : study.parameters.semiMinorAxis}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Row>
                                  <Col className="text-secondary">Rotación Horizontal Azimut en grados</Col>
                                  <Col className="fw-bold">{study.parameters.theta}</Col>
                                </Row>
                              </Col>
                              <Col>
                                {study.parameters.volumeKind === 'ellipsoid' && (
                                  <Row>
                                    <Col className="text-secondary">Rotación Vertical Buzamiento en grados</Col>
                                    <Col className="fw-bold">{study.parameters.phi}</Col>
                                  </Row>
                                )}
                              </Col>
                            </Row>
                          </>
                        ) : [NEAREST_NEIGHBOR, NEAREST_NEIGHBOR_2D, NEAREST_NEIGHBOR_3D].includes(
                            study.consolidationAlgorithm
                          ) ? (
                          <Row>
                            <Col>
                              <Row>
                                <Col className="text-secondary">Distancia máxima en metros</Col>
                                <Col className="fw-bold">{study.parameters.maxDistance}</Col>
                              </Row>
                            </Col>
                            <Col></Col>
                          </Row>
                        ) : [QUADRANT_AVERAGE_2D, QUADRANT_AVERAGE_3D].includes(study.consolidationAlgorithm) ? (
                          <Row>
                            <Col>
                              <Row>
                                <Col className="text-secondary">Espaciamiento en metros</Col>
                                <Col className="fw-bold">{study.parameters.spacing}</Col>
                              </Row>
                            </Col>
                            <Col></Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col>
                              <Row>
                                <Col className="text-secondary">Arista en metros en eje X</Col>
                                <Col className="fw-bold">{study.parameters.spacingX}</Col>
                              </Row>
                              <Row>
                                <Col className="text-secondary">Arista en metros en eje Y</Col>
                                <Col className="fw-bold">{study.parameters.spacingY}</Col>
                              </Row>
                              <Row>
                                <Col className="text-secondary">Arista en metros en eje Z</Col>
                                <Col className="fw-bold">{study.parameters.spacingZ}</Col>
                              </Row>
                            </Col>
                            <Col></Col>
                          </Row>
                        )}
                        <Row>
                          <Col>
                            <span className="text-secondary">
                              Archivo utilizado:{' '}
                              <a download target="_blank" rel="noreferrer" href={study.fileURL}>
                                {study.filename}
                              </a>
                            </span>
                          </Col>
                        </Row>
                        {study.processedFileURL && (
                          <Row>
                            <Col>
                              <span className="text-secondary">
                                Archivo procesado:{' '}
                                <a download target="_blank" rel="noreferrer" href={study.processedFileURL}>
                                  Descargar archivo procesado
                                </a>
                              </span>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col sm={3} className="fs-5">
                            Variables:
                          </Col>
                          <Col>
                            {Object.keys(study.variablesData).map((varName: string, j: number) => {
                              return (
                                <Row key={j}>
                                  <Col>
                                    <Row>
                                      <Col xs lg="2" className="text-secondary">
                                        {varName}:
                                      </Col>
                                      <Col md="auto" className="text-secondary">
                                        {study.variablesData[varName] === 'C' ? 'Categórica' : 'Numérica'}
                                      </Col>
                                      <Col>
                                        <Badge pill bg="secondary">
                                          {study.notImportedVariables.includes(varName) ? 'No importada' : ''}
                                        </Badge>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              );
                            })}
                          </Col>
                        </Row>
                      </Container>
                    );
                  })}
                </Card.Body>
              </Card>
            </div>
          </Collapse>
          {auth.isAdmin && thereAreRunningTasks && (
            <Alert variant="info" className="mt-3">
              <Spinner size="sm" /> Hay tareas en ejecución. Espere a que finalicen y revise los archivos generados.
            </Alert>
          )}
          {auth.isAdmin && (
            <>
              <Row className="mt-3 border-bottom">
                <h4 className="text-secondary">Consolidados generados a partir de los datos proporcionados</h4>
              </Row>
              <Row className="border-bottom">
                <Col sm={1}>
                  <span className="fw-bold">#</span>
                </Col>
                <Col sm={2}>
                  <span className="fw-bold">Tipo</span>
                </Col>
                <Col>
                  <span className="fw-bold">Estudio</span>
                </Col>
                <Col>
                  <span className="fw-bold">Nombre de archivo</span>
                </Col>
                <Col sm={2}>
                  <span className="fw-bold">Fecha</span>
                </Col>
                <Col sm={1}></Col>
              </Row>
              {result.consolidatedFiles.map((consolidatedFile: IConsolidatedFile, i: number) => {
                return (
                  <Row key={i} className="border-bottom py-1 bg-light">
                    <Col sm={1} className="d-flex align-items-center">
                      <span>{i + 1}</span>
                    </Col>
                    <Col sm={2} className="d-flex align-items-center">
                      <span>{consolidatedFile.name}</span>
                    </Col>
                    <Col className="d-flex align-items-center">
                      {consolidatedFile.studyName}
                      <span></span>
                    </Col>
                    <Col className="d-flex align-items-center">
                      <a download target="_blank" rel="noreferrer" href={consolidatedFile.fileURL}>
                        {consolidatedFile.filename}
                      </a>
                    </Col>
                    <Col sm={2} className="d-flex align-items-center">
                      <span>{formatDate(consolidatedFile.createdAt)}</span>
                    </Col>
                    <Col sm={1} className="d-flex align-items-center">
                      <Modal
                        show={showAddConsolidatedFileConfirmation}
                        onHide={closeAddConsolidatedFileConfirmation}
                        centered
                        backdrop="static"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Cargar consolidado</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {addingConsolidatedFile ? (
                            <Row>
                              <Col className="centered-horizontally">
                                <Spinner />
                              </Col>
                            </Row>
                          ) : !isErrorAddingConsolidatedFile ? (
                            `¿Estás seguro que deseas cargar el Consolidado ${consolidatedFileNameInModal} para análisis?`
                          ) : (
                            <Row>
                              <Col>
                                <Alert variant="danger" className="my-1">
                                  Lo sentimos: hubo un error.
                                </Alert>
                              </Col>
                            </Row>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            disabled={addingConsolidatedFile}
                            onClick={closeAddConsolidatedFileConfirmation}
                          >
                            Cancelar
                          </Button>
                          <Button
                            variant="primary"
                            disabled={addingConsolidatedFile}
                            onClick={() => addConsolidatedFile(consolidatedFileIdInModal)}
                          >
                            Aceptar
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      {consolidatedFile.name === 'ProcessableDatabaseFile' && (
                        <Button
                          variant="outline-dark"
                          disabled={consolidatedFile.isAssociated}
                          onClick={() =>
                            openAddConsolidatedFileConfirmation(consolidatedFile.id, consolidatedFile.filename)
                          }
                        >
                          Cargar
                        </Button>
                      )}
                    </Col>
                  </Row>
                );
              })}
              <Row className="text-center mt-3">
                <Col>
                  <Modal
                    show={showForceConsolidationStudyFilesConfirmation}
                    onHide={closeForceConsolidationStudyFilesConfirmation}
                    centered
                    backdrop="static"
                    dialogClassName="modal-90w"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Forzar consolidación de archivos AverageNeighborsFile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {isForcingConsolidationStudyFiles ? (
                        <Row>
                          <Col className="centered-horizontally">
                            <Spinner />
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <p>
                            ¿Estás seguro que deseas forzar la consolidación de archivos AverageNeighborsFiles de los
                            resultados enviados a generar el {formatDate(result.createdAt)} con id{' '}
                            <b>{studySentIdInForceConsolidationStudyFilesModal}</b>?
                          </p>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Estudio</th>
                                <th>Nombre de archivo</th>
                                <th>Fecha</th>
                              </tr>
                            </thead>
                            <tbody>
                              {result.consolidatedFiles.map((consolidatedFile: IConsolidatedFile) => {
                                if (consolidatedFile.name === 'AverageNeighborsFile' && consolidatedFile.studyId) {
                                  return (
                                    <tr key={`row-${consolidatedFile.id}`}>
                                      <td>
                                        <Form.Check
                                          key={`option-${consolidatedFile.id}`}
                                          type="checkbox"
                                          id={`option--${consolidatedFile.id}`}
                                          onChange={handleStudyFileIdCheckbox(
                                            consolidatedFile.id,
                                            consolidatedFile.studyId
                                          )}
                                          disabled={
                                            studyFilesStudyIdsInForceConsolidationStudyFilesModal.includes(
                                              consolidatedFile.studyId
                                            ) &&
                                            !studyFilesIdsInForceConsolidationStudyFilesModal.includes(
                                              consolidatedFile.id
                                            )
                                          }
                                        />
                                      </td>
                                      <td>{consolidatedFile.studyName}</td>
                                      <td>{consolidatedFile.filename}</td>
                                      <td>{formatDate(consolidatedFile.createdAt)}</td>
                                    </tr>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </tbody>
                          </Table>
                        </>
                      )}
                      {isErrorForceConsolidationStudyFiles && (
                        <Alert variant="danger" className="my-1">
                          Lo sentimos: hubo un error.
                        </Alert>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        disabled={isForcingConsolidationStudyFiles}
                        onClick={closeForceConsolidationStudyFilesConfirmation}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="primary"
                        disabled={
                          isForcingConsolidationStudyFiles ||
                          studyFilesIdsInForceConsolidationStudyFilesModal.length === 0
                        }
                        onClick={() => forceConsolidationStudyFiles()}
                      >
                        Forzar consolidación
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Button
                    variant="light"
                    onClick={() => openForceConsolidationStudyFilesConfirmation(result.id)}
                    disabled={isForcingConsolidationStudyFiles}
                  >
                    Forzar consolidación de AverageNeighborsFiles
                  </Button>
                </Col>
                <Col>
                  <Modal
                    show={showForceSendStudiesConfirmation}
                    onHide={closeForceSendStudiesConfirmation}
                    centered
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Agregar estudios a consolidado existente</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {isForcingSendStudies ? (
                        <Row>
                          <Col className="centered-horizontally">
                            <Spinner />
                          </Col>
                        </Row>
                      ) : !isErrorForceSendStudies ? (
                        <span>
                          ¿Estás seguro que deseas agregar estudios al consolidado de los resultados enviados a generar
                          el {formatDate(result.createdAt)} con id <b>{studySentIdInForceSendStudiesModal}</b>?
                        </span>
                      ) : (
                        <Row>
                          <Col>
                            <Alert variant="danger" className="my-1">
                              Lo sentimos: hubo un error.
                            </Alert>
                          </Col>
                        </Row>
                      )}
                      {dataSetStudies?.map((study) => {
                        return (
                          <Form.Check
                            key={`option-${study.id}`}
                            type="checkbox"
                            id={`option--${study.id}`}
                            label={study.name}
                            onChange={handleStudyIdCheckbox(study.id)}
                          />
                        );
                      })}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        disabled={isForcingSendStudies}
                        onClick={closeForceSendStudiesConfirmation}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="primary"
                        disabled={isForcingSendStudies || studiesIdsInForceSendStudiesModal.length === 0}
                        onClick={() => forceSendStudies()}
                      >
                        Forzar envío de estudios
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Button
                    variant="light"
                    onClick={() => openForceSendStudiesConfirmation(result.id)}
                    disabled={isForcingSendStudies}
                  >
                    Agregar estudios a consolidado existente
                  </Button>
                </Col>
              </Row>
            </>
          )}

          <Row className="mt-5 border-bottom">
            <h4 className="text-secondary">Archivos proporcionados por Mineral Forecast</h4>
          </Row>
          {isErrorDeletingResponse && (
            <Row>
              <Col>
                <Alert variant="danger" className="my-1">
                  Lo sentimos: hubo un error.
                </Alert>
              </Col>
            </Row>
          )}
          <Row className="border-bottom">
            <Col>
              <span className="fw-bold">#</span>
            </Col>
            <Col>
              <span className="fw-bold">Mensaje</span>
            </Col>
            <Col>
              <span className="fw-bold">Fecha</span>
            </Col>
            <Col></Col>
          </Row>
          {deletingResponse ? (
            <Row>
              <Col className="centered-horizontally my-1">
                <Spinner />
              </Col>
            </Row>
          ) : (
            result.responseFiles.map((responseFile: IResponseFile, i: number) => {
              return (
                <Row key={i} className="border-bottom py-1 bg-light">
                  <Col className="d-flex align-items-center">
                    <span>{i + 1}</span>
                  </Col>
                  <Col className="d-flex align-items-center">
                    <span>{responseFile.message}</span>
                  </Col>
                  <Col className="d-flex align-items-center">
                    <span>{formatDate(responseFile.createdAt)}</span>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-around">
                    <Button variant="secondary">
                      <a download target="_blank" rel="noreferrer" className="text-light" href={responseFile.fileURL}>
                        Descargar
                      </a>
                    </Button>
                    {auth.isAdmin && (
                      <Button onClick={deleteResponse(responseFile.id)} disabled={deletingResponse} variant="danger">
                        Eliminar
                      </Button>
                    )}
                  </Col>
                </Row>
              );
            })
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
