import React, { useState, useEffect, useMemo } from 'react';

import {
  Alert,
  Button,
  Col,
  Form,
  FormCheck,
  Container,
  Modal,
  Row,
  Spinner,
  Popover,
  OverlayTrigger,
  Card,
} from 'react-bootstrap';
import { Table, FileArrowDown } from 'react-bootstrap-icons';

import { useAppSelector } from '../../redux/hooks';
import { IStudy, IStudyVariables, IParameter, IValidationError } from '../../services/types';
import { useLazyGetStudyQuery, useUpdateStudyMutation, useValidateStudyMutation } from '../../services';
import {
  handleInputChange,
  STUDY_TYPE_DICT,
  CONSOLIDATION_ALGORITHMS,
  VALID_CONSOLIDATION_ALGORITHMS,
  INVERSE_WEIGHTED_DISTANCE,
  NEAREST_NEIGHBOR,
  NEAREST_NEIGHBOR_2D,
  NEAREST_NEIGHBOR_3D,
  QUADRANT_AVERAGE_2D,
  QUADRANT_AVERAGE_3D,
  RECTANGULAR_PRISM,
  FAST_INVERSE_WEIGHTED_DISTANCE,
  ALLOWED_EXTENSIONS_BY_STUDY_TYPE,
} from '../../utils';

import { NeighborhoodSurface, FileUploader } from '../../components';
import './study-form.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const templateStudy = require('../../assets/templates/TemplateStudy.csv');

interface StudyFormProps {
  show: boolean;
  onHide: () => void;
  studyData?: IStudy;
  dataSetName: string;
  elevationGridFilename?: string;
}

interface IParameterRequest {
  semiMajorAxis: number;
  semiMediumAxis: number;
  semiMinorAxis: number;
  height: number;
  theta: number;
  phi: number;
  m: number;
  studyType: string;
  volume_kind: string;
  holeIdComparison: string;
  columnNoValuesCases: boolean;
  maxDistance: number;
  neighborsDetails: boolean;
  spacing: number;
  spacingX: number;
  spacingY: number;
  spacingZ: number;
}

const parametersToParametersRequest = (parameters: IParameter): IParameterRequest => {
  const { volumeKind, ...rest } = parameters;
  return {
    ...rest,
    volume_kind: parameters.volumeKind,
    semiMajorAxis: +parameters.semiMajorAxis ? +parameters.semiMajorAxis : 0,
    semiMediumAxis: volumeKind === 'cylinder' ? 0 : +parameters.semiMediumAxis ? +parameters.semiMediumAxis : 0,
    semiMinorAxis: +parameters.semiMinorAxis ? +parameters.semiMinorAxis : 0,
    height: volumeKind === 'cylinder' ? +parameters.height : 0,
    theta: +parameters.theta ? +parameters.theta : 0,
    phi: +parameters.phi ? +parameters.phi : 0,
    m: +parameters.m ? +parameters.m : 0,
    maxDistance: +parameters.maxDistance ? +parameters.maxDistance : 0,
    spacing: +parameters.spacing ? +parameters.spacing : 0,
    spacingX: +parameters.spacingX ? +parameters.spacingX : 0,
    spacingY: +parameters.spacingY ? +parameters.spacingY : 0,
    spacingZ: +parameters.spacingZ ? +parameters.spacingZ : 0,
  };
};

export default function StudyForm({ show, onHide, studyData, dataSetName, elevationGridFilename }: StudyFormProps) {
  const auth = useAppSelector((state) => state.auth);

  // Study parameters
  const [parameters, setParameters] = useState<IParameter>({
    semiMajorAxis: '100',
    semiMediumAxis: '100',
    semiMinorAxis: '100',
    height: '100',
    theta: '0',
    phi: '0',
    m: '2',
    studyType: 'OTROS',
    volumeKind: 'cylinder',
    holeIdComparison: 'none',
    columnNoValuesCases: false,
    maxDistance: '10',
    neighborsDetails: false,
    spacing: '10',
    spacingX: '10',
    spacingY: '10',
    spacingZ: '10',
  });
  const [study, setStudy] = useState<IStudy | undefined>(undefined);
  const [studyName, setStudyName] = useState<string>('Nuevo estudio');
  const [consolidationAlgorithm, setConsolidationAlgorithm] = useState<string>(FAST_INVERSE_WEIGHTED_DISTANCE);
  const [variablesData, setVariablesData] = useState<IStudyVariables>({});
  const [validFile, setValidFile] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<IValidationError[]>([]);
  const [notImportedVariables, setNotImportedVariables] = useState<string[]>([]);

  const [updatingFile, setUpdatingFile] = useState<boolean>(false);
  const [showSpacingInput, setShowSpacingInput] = useState<boolean>(false);
  const [startUpload, setStartUpload] = useState<boolean>(false);
  const [spacingInput, setSpacingInput] = useState<string>('');
  const [varName, setVarName] = useState<string>('');
  // Mutations to backend
  const [
    updateStudyTrigger,
    { data: updatedStudy, isLoading: updating, isError: isErrorUpdating, reset: resetUpdateStudy },
  ] = useUpdateStudyMutation();

  const [getStudyTrigger] = useLazyGetStudyQuery();
  const [validateStudyTrigger, { isLoading: validating }] = useValidateStudyMutation();

  useEffect(() => {
    // Updating an existing study (modal open with edit button)
    if (studyData) {
      setStudy(studyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Updating a new study (modal open with new study)
  useEffect(() => {
    if (updatedStudy && !studyData) {
      setStudy(updatedStudy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedStudy]);

  useEffect(() => {
    if (study) {
      setStudyName(study.name);
      setParameters(study.parameters);
      setConsolidationAlgorithm(study.consolidationAlgorithm);
      setVariablesData(study.variablesData);
      setValidFile(study.validFile);
      setNotImportedVariables(study.notImportedVariables);
    }
  }, [study]);

  const handleStringParameterChange =
    (
      parameter:
        | 'studyType'
        | 'holeIdComparison'
        | 'm'
        | 'semiMajorAxis'
        | 'semiMediumAxis'
        | 'semiMinorAxis'
        | 'height'
        | 'theta'
        | 'phi'
        | 'maxDistance'
        | 'spacing'
        | 'spacingX'
        | 'spacingY'
        | 'spacingZ'
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setParameters({ ...parameters, [parameter]: e.target.value });
    };

  const handleConsolidationAlgorithmChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setConsolidationAlgorithm(e.target.value);
  };

  const handleSelectParameterChange =
    (parameter: 'volumeKind' | 'studyType') => (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (parameter === 'volumeKind') {
        if (e.target.value === 'cylinder') {
          setParameters({
            ...parameters,
            [parameter]: e.target.value,
            semiMinorAxis: parameters.semiMediumAxis,
            height: parameters.semiMinorAxis,
          });
        } else {
          setParameters({
            ...parameters,
            [parameter]: e.target.value,
            semiMediumAxis: parameters.semiMinorAxis,
            semiMinorAxis: parameters.height,
          });
        }
      } else {
        setParameters({ ...parameters, [parameter]: e.target.value });
      }
    };

  const handleBooleanParameterChange = (parameter: 'columnNoValuesCases' | 'neighborsDetails') => () => {
    setParameters({
      ...parameters,
      [parameter]: !parameters[parameter],
    });
  };

  const handleVariableTypeChange = (variable: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValidFile(false);
    setVariablesData({ ...variablesData, [variable]: e.target.value });
  };

  const handleNotImportedCheckbox = (varName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = notImportedVariables.indexOf(varName);
    let newNotImportedVariables = [...notImportedVariables];

    if (e.target.checked) {
      if (index === -1) {
        newNotImportedVariables = [...newNotImportedVariables, varName];
      }
    } else {
      if (index !== -1) {
        newNotImportedVariables.splice(index, 1);
      }
    }

    setNotImportedVariables(newNotImportedVariables);
  };

  const allCategoric = () => {
    setValidFile(false);
    const variablesCategoric: IStudyVariables = {};
    Object.keys(variablesData).forEach((variable) => {
      variablesCategoric[variable] = 'C';
    });
    setVariablesData(variablesCategoric);
  };

  const allNumeric = () => {
    setValidFile(false);
    const variablesNumeric: IStudyVariables = {};
    Object.keys(variablesData).forEach((variable) => {
      variablesNumeric[variable] = 'N';
    });
    setVariablesData(variablesNumeric);
  };

  const validateStudy = () => {
    if (study) {
      setValidationErrors([]);
      validateStudyTrigger({
        studyId: study.id,
        variables: variablesData,
        notImportedVariables: notImportedVariables,
      })
        .unwrap()
        .then((response: any) => {
          getStudyTrigger({
            studyId: study.id,
            studyRequestId: study.requestId,
          });
          setValidFile(response.valid_file);
          setValidationErrors(response.errors);
        });
    }
  };

  const save = () => {
    const form = new FormData();
    form.append('id', study ? study.id : '');
    form.append('name', studyName);
    form.append('dataset_name', dataSetName);
    form.append('consolidation_algorithm', consolidationAlgorithm);
    form.append('variables', JSON.stringify(variablesData));
    form.append('parameters', JSON.stringify(parametersToParametersRequest(parameters)));
    form.append('not_imported_variables', JSON.stringify(notImportedVariables));
    resetUpdateStudy();
    updateStudyTrigger({ id: study ? study.id : '', data: form })
      .unwrap()
      .then(() => {
        onHide();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const close = () => {
    onHide();
    setStudy(undefined);
  };

  const updateFileCallback = () => {
    setStartUpload(false);
    if (study) {
      getStudyTrigger({
        studyId: study.id,
        studyRequestId: study.requestId,
      })
        .unwrap()
        .then((response: IStudy) => {
          setStudy({
            ...study,
            name: studyName,
            filename: response.filename,
            fileURL: response.fileURL,
            validFile: response.validFile,
            variablesData: response.variablesData,
            parameters: parameters,
            consolidationAlgorithm,
          });
        });
    }
  };

  const [showPopoverStudy, setShowPopoverStudy] = useState(false);
  const handleOnMouseEnterStudy = () => setShowPopoverStudy(true);
  const handleOnMouseLeaveStudy = () => setShowPopoverStudy(false);

  const popoverStudy = (
    <Popover id="popover-template-study" onMouseEnter={handleOnMouseEnterStudy} onMouseLeave={handleOnMouseLeaveStudy}>
      <Popover.Header as="h3">Plantilla para Estudios</Popover.Header>
      <Popover.Body>
        <p>Al completarla, asegúrate que los campos X, Y y Z sean numéricos.</p>
        <p>
          <a href={templateStudy} download="TemplateStudy.csv" target="_blank" rel="noreferrer">
            Descargar archivo
          </a>
        </p>
      </Popover.Body>
    </Popover>
  );

  const specialCharacters = useMemo(
    // eslint-disable-next-line no-useless-escape
    () => /[`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/,
    []
  ); // space and hyphen removed
  const validTitle = useMemo(
    () => studyName.trim() !== '' && !specialCharacters.test(studyName),
    [studyName, specialCharacters]
  );
  const validM = useMemo(() => Number.isInteger(+parameters.m) && +parameters.m > 0, [parameters.m]);
  const validSemiMajor = useMemo(() => +parameters.semiMajorAxis > 0, [parameters.semiMajorAxis]);
  const validSemiMedium = useMemo(
    () => +parameters.semiMediumAxis > 0 || parameters.volumeKind === 'cylinder',
    [parameters.semiMediumAxis, parameters.volumeKind]
  );
  const validSemiMinor = useMemo(() => +parameters.semiMinorAxis > 0, [parameters.semiMinorAxis]);
  const validHeight = useMemo(
    () => +parameters.height > 0 || parameters.volumeKind !== 'cylinder',
    [parameters.height, parameters.volumeKind]
  );
  const validTheta = useMemo(
    () => parameters.theta.trim() !== '' && +parameters.theta >= 0 && +parameters.theta <= 360,
    [parameters.theta]
  );
  const validPhi = useMemo(
    () => parameters.phi.trim() !== '' && +parameters.phi >= -180 && +parameters.phi <= 180,
    [parameters.phi]
  );
  const validMaxDistance = useMemo(() => +parameters.maxDistance > 0, [parameters.maxDistance]);
  const validSpacing = useMemo(() => +parameters.spacing > 0, [parameters.spacing]);
  const validSpacingX = useMemo(() => +parameters.spacingX > 0, [parameters.spacingX]);
  const validSpacingY = useMemo(() => +parameters.spacingY > 0, [parameters.spacingY]);
  const validSpacingZ = useMemo(() => +parameters.spacingZ >= 0, [parameters.spacingZ]);
  const validVars = useMemo(() => {
    return (
      Object.keys(variablesData).length > 0 &&
      Object.keys(variablesData)
        .map((key: string) => variablesData[key] !== '')
        .reduce((prevBool: boolean, isNotEmpty: boolean) => prevBool && isNotEmpty)
    );
  }, [variablesData]);
  const validParams = useMemo(() => {
    return (
      (((consolidationAlgorithm === INVERSE_WEIGHTED_DISTANCE || FAST_INVERSE_WEIGHTED_DISTANCE) &&
        validM &&
        validSemiMajor &&
        validSemiMedium &&
        validSemiMinor &&
        validHeight &&
        validTheta &&
        validPhi) ||
        ([NEAREST_NEIGHBOR, NEAREST_NEIGHBOR_2D, NEAREST_NEIGHBOR_3D].includes(consolidationAlgorithm) &&
          validMaxDistance) ||
        ([QUADRANT_AVERAGE_2D, QUADRANT_AVERAGE_3D].includes(consolidationAlgorithm) && validSpacing) ||
        (consolidationAlgorithm === RECTANGULAR_PRISM && validSpacingX && validSpacingY && validSpacingZ)) &&
      validVars &&
      validTitle &&
      validFile
    );
  }, [
    validTitle,
    validM,
    validSemiMajor,
    validSemiMedium,
    validSemiMinor,
    validHeight,
    validTheta,
    validPhi,
    validMaxDistance,
    validSpacing,
    validSpacingX,
    validSpacingY,
    validSpacingZ,
    consolidationAlgorithm,
    validVars,
    validFile,
  ]);

  const studyErrors = useMemo(() => {
    return validationErrors.filter((err: IValidationError) => err.severity === 'Error');
  }, [validationErrors]);

  const studyWarnings = useMemo(() => {
    return validationErrors.filter((err: IValidationError) => err.severity === 'Warning');
  }, [validationErrors]);

  const allowedExtensions = useMemo(() => {
    return ALLOWED_EXTENSIONS_BY_STUDY_TYPE[parameters.studyType];
  }, [parameters.studyType]);

  const validSpacingInput = useMemo(() => Number.isInteger(+spacingInput) && +spacingInput > 0, [spacingInput]);
  const validVarName = useMemo(() => {
    if (parameters.studyType === 'GEOF') {
      return varName.length > 0;
    }
    return true;
  }, [varName, parameters.studyType]);

  const checkExtension = (file: File) => {
    setStartUpload(false);
    setShowSpacingInput(false);
    const parts = file.name.split('.');
    if (parts.length > 1) {
      const extension = parts[parts.length - 1].toLocaleLowerCase();
      if (extension === 'rar' || extension === 'zip') {
        setShowSpacingInput(true);
        return;
      } else if (extension === 'csv') {
        setStartUpload(true);
      }
    }
  };

  const submitFile = () => {
    setStartUpload(true);
  };

  return (
    <Modal size="lg" show={show} onHide={close} backdrop="static">
      <Modal.Header closeButton={!(updating || updatingFile || validating)}>
        <Modal.Title>Editar estudio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col>
              <Form.Label className="parameter-label">Identificador de estudio</Form.Label>
              <Form.Control
                isInvalid={!validTitle}
                value={studyName}
                onChange={handleInputChange(setStudyName)}
                disabled={updating}
                className="parameter-input"
                type="text"
              />
              <Form.Control.Feedback type="invalid">
                Debe asignar un identificador sin caracteres especiales
              </Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label className="parameter-label">Tipo de estudio</Form.Label>
              <Form.Select
                value={parameters.studyType}
                onChange={handleSelectParameterChange('studyType')}
                disabled={updating}
                className="parameter-select"
              >
                {Object.keys(STUDY_TYPE_DICT).map((key: string, index: number) => (
                  <option key={index} value={key}>
                    {STUDY_TYPE_DICT[key]}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              {auth.isAdmin && (
                <>
                  <Form.Label className="parameter-label">Algoritmo de consolidación</Form.Label>
                  <Form.Select
                    value={consolidationAlgorithm}
                    isInvalid={!VALID_CONSOLIDATION_ALGORITHMS.includes(consolidationAlgorithm)}
                    onChange={handleConsolidationAlgorithmChange}
                    disabled={updating}
                    className="parameter-select"
                  >
                    {Object.keys(CONSOLIDATION_ALGORITHMS).map((algorithm: string, i: number) => {
                      if (VALID_CONSOLIDATION_ALGORITHMS.includes(algorithm)) {
                        return (
                          <option key={i} value={algorithm}>
                            {CONSOLIDATION_ALGORITHMS[algorithm]}
                          </option>
                        );
                      } else if (algorithm === consolidationAlgorithm) {
                        return (
                          <option key={i} value={algorithm} disabled>
                            {CONSOLIDATION_ALGORITHMS[algorithm]}
                          </option>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Algoritmo no válido: {CONSOLIDATION_ALGORITHMS[consolidationAlgorithm]}
                  </Form.Control.Feedback>
                </>
              )}
            </Col>
          </Row>

          <Card body className="mt-3">
            <Row className="align-items-start">
              <Col>
                <FileUploader
                  rowClassName="pb-3"
                  model="study"
                  id={study ? study.id : ''}
                  filename={study ? study.filename : ''}
                  fileURL={study ? study.fileURL : ''}
                  fileExtension={allowedExtensions}
                  fileAttributeName="input_file"
                  preprocessingOnSuccessCallback={updateFileCallback}
                  setPerformUpdate={setUpdatingFile}
                  extraCol={
                    <OverlayTrigger show={showPopoverStudy} placement="bottom" overlay={popoverStudy}>
                      <Button
                        variant="outline-secondary"
                        onMouseEnter={handleOnMouseEnterStudy}
                        onMouseLeave={handleOnMouseLeaveStudy}
                      >
                        <span>
                          <Table /> Plantilla
                        </span>
                      </Button>
                    </OverlayTrigger>
                  }
                  submitOnChange={false}
                  onChangeCallback={checkExtension}
                  spacing={spacingInput}
                  varName={varName}
                  uploadTrigger={startUpload}
                  studyType={parameters.studyType}
                />
              </Col>
            </Row>
            {study?.processedFileURL && (
              <Row>
                <Col>
                  <p>
                    <a
                      href={study?.processedFileURL ? study?.processedFileURL : ''}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      Archivo procesado <FileArrowDown size={18} />
                    </a>
                  </p>
                </Col>
              </Row>
            )}
            {showSpacingInput && !elevationGridFilename && (
              <Alert variant="warning">
                La Grilla de Elevación no se encuentra cargada en el data set.
                <br />
                Por favor cierre esta ventana y suba el archivo antes de continuar.
              </Alert>
            )}
            {showSpacingInput && (parameters.studyType === 'LITO' || parameters.studyType === 'EST') && (
              <Row className="align-items-center py-2">
                <Col>
                  <Form.Label>Espaciamiento</Form.Label>
                  <Form.Control
                    isInvalid={!validSpacingInput}
                    value={spacingInput}
                    type="text"
                    onChange={handleInputChange(setSpacingInput)}
                    disabled={updatingFile}
                    className="parameter-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    El espaciamiento debe ser un número natural mayor o igual 1
                  </Form.Control.Feedback>
                </Col>
                <Col>
                  <Button onClick={submitFile} disabled={!validSpacingInput || updatingFile || !elevationGridFilename}>
                    Subir archivo
                  </Button>
                </Col>
              </Row>
            )}
            {showSpacingInput && parameters.studyType === 'GEOF' && (
              <Row className="align-items-center py-2">
                <Col>
                  <Form.Label>Nombre de variable</Form.Label>
                  <Form.Control
                    isInvalid={!validVarName}
                    value={varName}
                    type="text"
                    onChange={handleInputChange(setVarName)}
                    disabled={updatingFile}
                    className="parameter-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    El nombre de la variable a procesar de geofísica no puede ser vacío
                  </Form.Control.Feedback>
                </Col>
                <Col>
                  <Button onClick={submitFile} disabled={!validVarName || updatingFile || !elevationGridFilename}>
                    Subir archivo
                  </Button>
                </Col>
              </Row>
            )}
            {study?.fileURL && (
              <>
                <Row className="align-items-center py-3 border-top">
                  <Col>
                    <Form.Label>Seleccione el tipo de variables:</Form.Label>
                  </Col>
                  <Col className="d-flex justify-content-around">
                    <Button
                      onClick={allCategoric}
                      variant="secondary"
                      disabled={updating || updatingFile || validating}
                    >
                      Todas categóricas
                    </Button>
                    <Button onClick={allNumeric} variant="secondary" disabled={updating || updatingFile || validating}>
                      Todas numéricas
                    </Button>
                  </Col>
                </Row>
                <Row className="text-end" style={{ fontSize: 'small' }}>
                  <Col>
                    <span>No importar</span>
                  </Col>
                  <Col>
                    <span>No importar</span>
                  </Col>
                </Row>
                <Row>
                  {study &&
                    Object.keys(variablesData).map((vName: string, i: number) => {
                      return (
                        <Col key={i} className="mb-2" sm={6}>
                          <Row className="align-items-center">
                            <Col>
                              <Form.Label>{vName}</Form.Label>
                            </Col>
                            <Col>
                              <Form.Select
                                value={variablesData[vName]}
                                isInvalid={variablesData[vName] === ''}
                                onChange={handleVariableTypeChange(vName)}
                                disabled={updating || updatingFile || validating}
                                className="parameter-select"
                              >
                                <option value="" key={0}>
                                  Selecciona un tipo de variable
                                </option>
                                <option value="C" key={1}>
                                  Categórica
                                </option>
                                <option value="N" key={2}>
                                  Numérica
                                </option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Se debe definir el tipo de variable
                              </Form.Control.Feedback>
                            </Col>
                            <Col sm={2}>
                              <Form.Check
                                key={`option-${i}`}
                                type="switch"
                                id={`option-${i}`}
                                onChange={handleNotImportedCheckbox(vName)}
                                checked={notImportedVariables.includes(vName)}
                                disabled={updating || updatingFile || validating}
                              />
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                </Row>
                <Row className="my-2">
                  <Col className="centered-horizontally">
                    {validating ? (
                      <Spinner />
                    ) : (
                      <Button onClick={validateStudy} disabled={updating || updatingFile || !validVars}>
                        Validar archivo
                      </Button>
                    )}
                  </Col>
                </Row>
                {!updatingFile && !validating && Object.keys(variablesData).length === 0 && (
                  <Alert variant="warning">
                    No se reconocieron variables al cargar el estudio. Por favor vuelva a subir el archivo.
                  </Alert>
                )}
                {!updatingFile && !validating && !validVars && (
                  <Alert variant="danger">El estudio contiene variables sin asginación de tipo.</Alert>
                )}
                {!updatingFile &&
                  !validating &&
                  (validFile ? (
                    <Alert variant="success">El estudio fue validado correctamente.</Alert>
                  ) : (
                    <Alert variant="danger">Es necesario validar el estudio para poder guardarlo.</Alert>
                  ))}
                {studyErrors.length > 0 && (
                  <Alert variant="danger">
                    {studyErrors.map((err: IValidationError, i: number) => (
                      <Row key={i}>
                        <Col className="text-center">{err.description}</Col>
                      </Row>
                    ))}
                  </Alert>
                )}
                {studyWarnings.length > 0 && (
                  <Alert variant="warning">
                    {studyWarnings.map((err: IValidationError, i: number) => (
                      <Row key={i}>
                        <Col className="text-center">{err.description}</Col>
                      </Row>
                    ))}
                  </Alert>
                )}
              </>
            )}
          </Card>

          {[
            INVERSE_WEIGHTED_DISTANCE,
            QUADRANT_AVERAGE_2D,
            QUADRANT_AVERAGE_3D,
            RECTANGULAR_PRISM,
            FAST_INVERSE_WEIGHTED_DISTANCE,
          ].includes(consolidationAlgorithm) && (
            <Card body className="mt-3">
              <Row>
                <Col>
                  <Form.Label>¿Comparar muestras vecinas si es que son muestras de sondajes?</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormCheck>
                    <Form.Check
                      type="radio"
                      checked={parameters.holeIdComparison === 'none'}
                      value="none"
                      onChange={handleStringParameterChange('holeIdComparison')}
                      disabled={updating}
                      className="d-flex justify-content-center"
                    />
                    <Form.Label className="comparison-radio-text">No son muestras de sondaje</Form.Label>
                  </FormCheck>
                </Col>
                <Col>
                  <Form.Check
                    type="radio"
                    checked={parameters.holeIdComparison === 'equal'}
                    value="equal"
                    onChange={handleStringParameterChange('holeIdComparison')}
                    disabled={updating}
                    className="d-flex justify-content-center"
                  />
                  <Form.Label className="comparison-radio-text">
                    Sí, comparar sólo con muestras del <span>mismo sondaje</span>
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Check
                    type="radio"
                    checked={parameters.holeIdComparison === 'different'}
                    value="different"
                    onChange={handleStringParameterChange('holeIdComparison')}
                    disabled={updating}
                    className="d-flex justify-content-center"
                  />
                  <Form.Label className="comparison-radio-text">
                    Sí, comparar sólo con muestras de <span>otros sondajes</span>
                  </Form.Label>
                </Col>
              </Row>
              {auth.isAdmin && (
                <Row className="mt-4">
                  <Col>
                    <Form.Check
                      checked={parameters.neighborsDetails}
                      onChange={handleBooleanParameterChange('neighborsDetails')}
                      disabled={updating}
                      type="checkbox"
                      label="Generar detalle de vecinos"
                    />
                  </Col>
                </Row>
              )}
              <Row className="mt-4">
                <Col>
                  <Form.Check
                    checked={parameters.columnNoValuesCases}
                    onChange={handleBooleanParameterChange('columnNoValuesCases')}
                    disabled={updating}
                    type="checkbox"
                    label="Agregar columna para casos sin valores"
                  />
                </Col>
              </Row>
            </Card>
          )}

          <Card body className="mt-3">
            {[INVERSE_WEIGHTED_DISTANCE, FAST_INVERSE_WEIGHTED_DISTANCE].includes(consolidationAlgorithm) ? (
              <>
                <Row>
                  <Col>
                    <Form.Label className="parameter-label">Factor inverso</Form.Label>
                    <Form.Control
                      isInvalid={!validM}
                      value={parameters.m}
                      type="text"
                      onChange={handleStringParameterChange('m')}
                      disabled={updating}
                      className="parameter-input"
                    />
                    <Form.Control.Feedback type="invalid">
                      El factor inverso debe ser un número natural mayor o igual 1
                    </Form.Control.Feedback>
                  </Col>
                  <Col></Col>
                  <Col>
                    <Form.Label className="parameter-label">Figura</Form.Label>
                    <Form.Select
                      value={parameters.volumeKind}
                      onChange={handleSelectParameterChange('volumeKind')}
                      disabled={updating}
                      className="parameter-select"
                    >
                      <option key={0} value="cylinder">
                        Cilindro
                      </option>
                      <option key={1} value="ellipsoid">
                        Elipsoide
                      </option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} className="d-flex flex-column justify-content-center">
                    <Row className="mb-1">
                      <Form.Label className="parameter-label">
                        Eje dirección Este (X): {parameters.semiMajorAxis} metros
                      </Form.Label>
                      <Form.Control
                        isInvalid={!validSemiMajor}
                        value={parameters.semiMajorAxis}
                        onChange={handleStringParameterChange('semiMajorAxis')}
                        disabled={updating}
                      />
                      <Form.Control.Feedback type="invalid">
                        La longitud de los ejes debe ser un número mayor a 0
                      </Form.Control.Feedback>
                    </Row>
                    <Row className="my-2">
                      <Form.Label className="parameter-label">
                        Eje dirección Norte (Y):{' '}
                        {parameters.volumeKind === 'cylinder' ? parameters.semiMinorAxis : parameters.semiMediumAxis}{' '}
                        metros
                      </Form.Label>
                      <Form.Control
                        isInvalid={parameters.volumeKind === 'cylinder' ? !validSemiMinor : !validSemiMedium}
                        value={
                          parameters.volumeKind === 'cylinder' ? parameters.semiMinorAxis : parameters.semiMediumAxis
                        }
                        onChange={handleStringParameterChange(
                          parameters.volumeKind === 'cylinder' ? 'semiMinorAxis' : 'semiMediumAxis'
                        )}
                        disabled={updating}
                      />
                      <Form.Control.Feedback type="invalid">
                        La longitud de los ejes debe ser un número mayor a 0
                      </Form.Control.Feedback>
                    </Row>
                    <Row className="my-2">
                      <Form.Label className="parameter-label">
                        {parameters.volumeKind === 'cylinder' ? 'Altura: ' : 'Eje dirección Cota (Z): '}
                        {parameters.volumeKind === 'cylinder' ? parameters.height : parameters.semiMinorAxis} metros
                      </Form.Label>
                      <Form.Control
                        isInvalid={parameters.volumeKind === 'cylinder' ? !validHeight : !validSemiMinor}
                        value={parameters.volumeKind === 'cylinder' ? parameters.height : parameters.semiMinorAxis}
                        onChange={handleStringParameterChange(
                          parameters.volumeKind === 'cylinder' ? 'height' : 'semiMinorAxis'
                        )}
                        disabled={updating}
                      />
                      <Form.Control.Feedback type="invalid">
                        La longitud de los ejes debe ser un número mayor a 0
                      </Form.Control.Feedback>
                    </Row>
                    <Row className="my-2">
                      <Form.Label className="parameter-label">
                        Rotación Horizontal Azimut: * {parameters.theta}°
                      </Form.Label>
                      <Form.Control
                        isInvalid={!validTheta}
                        value={parameters.theta}
                        onChange={handleStringParameterChange('theta')}
                        disabled={updating}
                      />
                      <Form.Control.Feedback type="invalid">
                        El ańgulo azimutal debe ser un número entre 0 y 360
                      </Form.Control.Feedback>
                    </Row>
                    <Row className="mt-2">
                      {parameters.volumeKind === 'ellipsoid' && (
                        <>
                          <Form.Label className="parameter-label">
                            Rotación Vertical Buzamiento **: {parameters.phi}°
                          </Form.Label>
                          <Form.Control
                            isInvalid={!validPhi}
                            value={parameters.phi}
                            onChange={handleStringParameterChange('phi')}
                            disabled={updating}
                          />
                          <Form.Control.Feedback type="invalid">
                            El ańgulo de buzamiento debe ser un número entre -180 y 180
                          </Form.Control.Feedback>
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col xs={8}>
                    <NeighborhoodSurface
                      xAxis={+parameters.semiMajorAxis}
                      yAxis={
                        parameters.volumeKind === 'cylinder' ? +parameters.semiMinorAxis : +parameters.semiMediumAxis
                      }
                      zAxis={parameters.volumeKind === 'cylinder' ? +parameters.height : +parameters.semiMinorAxis}
                      azimuthDegree={+parameters.theta}
                      dipDegree={+parameters.phi}
                      volumeKind={parameters.volumeKind}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="parameter-label">
                      * Azimut: ángulo en plano Norte-Este, 0° en dirección Norte (Y), creciente hacia el Este (X).
                    </p>
                    {parameters.volumeKind === 'ellipsoid' && (
                      <p className="parameter-label">
                        ** Buzamiento: ángulo entre el plano y la horizontal, en dirección perpendicular al azimut y
                        creciente en sentido horario.
                      </p>
                    )}
                  </Col>
                </Row>
              </>
            ) : [NEAREST_NEIGHBOR, NEAREST_NEIGHBOR_2D, NEAREST_NEIGHBOR_3D].includes(consolidationAlgorithm) ? (
              <Row>
                <Col>
                  <Form.Label className="parameter-label">Distancia máxima en metros</Form.Label>
                  <Form.Control
                    isInvalid={!validMaxDistance}
                    value={parameters.maxDistance}
                    onChange={handleStringParameterChange('maxDistance')}
                    disabled={updating}
                    className="parameter-input"
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">Debe asignar una distancia máxima válida</Form.Control.Feedback>
                </Col>
              </Row>
            ) : [QUADRANT_AVERAGE_2D, QUADRANT_AVERAGE_3D].includes(consolidationAlgorithm) ? (
              <Row>
                <Col>
                  <Form.Label className="parameter-label">Espaciamiento en metros</Form.Label>
                  <Form.Control
                    isInvalid={!validSpacing}
                    value={parameters.spacing}
                    onChange={handleStringParameterChange('spacing')}
                    disabled={updating}
                    className="parameter-input"
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">Debe asignar un espaciamiento válido</Form.Control.Feedback>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <Form.Label className="parameter-label">Arista en metros eje X</Form.Label>
                  <Form.Control
                    isInvalid={!validSpacingX}
                    value={parameters.spacingX}
                    onChange={handleStringParameterChange('spacingX')}
                    disabled={updating}
                    className="parameter-input"
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">
                    Debe asignar un valor de arista válido en X
                  </Form.Control.Feedback>
                </Col>
                <Col>
                  <Form.Label className="parameter-label">Arista en metros eje Y</Form.Label>
                  <Form.Control
                    isInvalid={!validSpacingY}
                    value={parameters.spacingY}
                    onChange={handleStringParameterChange('spacingY')}
                    disabled={updating}
                    className="parameter-input"
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">
                    Debe asignar un valor de arista válido en Y
                  </Form.Control.Feedback>
                </Col>
                <Col>
                  <Form.Label className="parameter-label">Arista en metros eje Z</Form.Label>
                  <Form.Control
                    isInvalid={!validSpacingZ}
                    value={parameters.spacingZ}
                    onChange={handleStringParameterChange('spacingZ')}
                    disabled={updating}
                    className="parameter-input"
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">
                    Debe asignar un valor de arista válido en Z
                  </Form.Control.Feedback>
                </Col>
              </Row>
            )}
          </Card>
          {isErrorUpdating && (
            <Alert variant="danger" className="mt-2 mb-0">
              Hubo un error. Intente otra vez.
            </Alert>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={updating || updatingFile || validating}>
          Cancelar
        </Button>
        <Button variant="success" disabled={updating || updatingFile || validating || !validParams} onClick={save}>
          Guardar {updating && <Spinner size="sm" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
