import { Suspense, lazy } from 'react';

import { createBrowserRouter } from 'react-router-dom';

import ProjectDetail from './pages/ProjectDetail/ProjectDetail';
import DataSetDetail from './pages/DataSetDetail/DataSetDetail';

// Layouts
const AppLayout = lazy(() => import('./layouts/AppLayout'));
const AppLayoutV2 = lazy(() => import('./layouts/AppLayoutV2'));
const AuthenticationLayout = lazy(() => import('./layouts/AuthenticationLayout'));

// Projects
const ProjectsList = lazy(() => import('./pages/Projects/ProjectsV2'));
const UpdateProjectFiles = lazy(() => import('./pages/ProjectDetail/UpdateProjectsFiles'));

// drills samples
const DrillingSampleList = lazy(() => import('./pages/DrillingSamples/DrillingSampleList'));
const CreateDrillingSample = lazy(() => import('./pages/DrillingSamples/CreateDrillingSample'));
const DrillingSampleDetail = lazy(() => import('./pages/DrillingSamples/DrillingSampleDetail'));

// information-layers
const InformationLayerList = lazy(() => import('./pages/InformationLayers/InformationLayerList'));
const CreateInformationLayer = lazy(() => import('./pages/InformationLayers/CreateInformationLayer'));
const PreprocessingInformationLayer = lazy(() => import('./pages/InformationLayers/PreprocessingInformationLayer'));

// DataSets
const DatasetList = lazy(() => import('./pages/DataSets/V2/DatasetList'));
const DatasetDetailV2 = lazy(() => import('./pages/DataSets/V2/DatasetDetail'));
const CreateDataset = lazy(() => import('./pages/DataSets/V2/CreatedataSet'));

// machine learning => experiments
const ExperimentsList = lazy(() => import('./pages/MachineLearning/Experiments/ExperimentsList'));
const CreateExperiment = lazy(() => import('./pages/MachineLearning/Experiments/CreateExperiment'));
const ExperimentDetail = lazy(() => import('./pages/MachineLearning/Experiments/ExperimentDetail'));
// machine-learning => explainability
const ExplainerDashboard = lazy(() => import('./pages/MachineLearning/ExplainerDashboard/ExplainerDashboard'));
// machine-learning => reports
const Report = lazy(() => import('./pages/MachineLearning/Report/Report'));

// Auth
const Login = lazy(() => import('./pages/Login/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));

// Projects
const Projects = lazy(() => import('./pages/Projects/Projects'));

// Data Management
const PredictionVisualization = lazy(() => import('./pages/PredictionVisualization/PredictionVisualization'));
const CategoricalVariablesHomologation = lazy(
  () => import('./pages/CategoricalVariablesHomologation/CategoricalVariablesHomologation')
);
const Geochemical = lazy(() => import('./pages/Geochemical/Geochemical'));

const DocumentExample = lazy(() => import('./pages/DocumentExample/DocumentExample'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <Login />
        </AppLayout>
      </Suspense>
    ),
  },
  {
    path: '/forgot-password',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <ForgotPassword />
        </AppLayout>
      </Suspense>
    ),
  },
  {
    path: '/projects',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <Projects />
        </AppLayout>
      </Suspense>
    ),
  },
  {
    path: '/projects/data-sets-without-project/:dataSetId',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <DataSetDetail dataSetId="undefined" noProject={true} />
        </AppLayout>
      </Suspense>
    ),
  },
  {
    path: '/projects/:id',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <ProjectDetail />
        </AppLayout>
      </Suspense>
    ),
  },
  {
    path: '/projects/:id/data-sets/:dataSetId',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <ProjectDetail />
        </AppLayout>
      </Suspense>
    ),
  },
  {
    path: '/projects/:id/new-data-set',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <ProjectDetail newDataSet={true} />
        </AppLayout>
      </Suspense>
    ),
  },
  {
    path: '/data-management/categorical-variables-homologation',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <CategoricalVariablesHomologation />
        </AppLayout>
      </Suspense>
    ),
  },
  {
    path: '/data-management/geochemical',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <Geochemical />
        </AppLayout>
      </Suspense>
    ),
  },
  {
    path: 'document-example',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <DocumentExample />
        </AppLayout>
      </Suspense>
    ),
  },
  {
    path: '/projects/:id/prediction-visualization/:modelId',
    element: (
      <Suspense>
        <PredictionVisualization />
      </Suspense>
    ),
  },
  // V2
  {
    path: '/v2/projects',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <AuthenticationLayout mustAdmin={true}>
            <ProjectsList />
          </AuthenticationLayout>
        </AppLayout>
      </Suspense>
    ),
  },
  {
    path: '/projects/update-files/:projectId',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayout>
          <AuthenticationLayout mustAdmin={true}>
            <UpdateProjectFiles />
          </AuthenticationLayout>
        </AppLayout>
      </Suspense>
    ),
  },

  // Drilling Samples
  {
    path: '/v2/projects/:projectId/drilling-samples/',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <DrillingSampleList />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  {
    path: '/v2/projects/:projectId/drilling-samples/new',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <CreateDrillingSample />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  {
    path: '/v2/projects/:projectId/drilling-samples/:drillingSampleId',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <DrillingSampleDetail />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  // Information Layers
  {
    path: '/v2/projects/:projectId/information-layers',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <InformationLayerList />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  {
    path: '/v2/projects/:projectId/information-layers/new',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <CreateInformationLayer />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  {
    path: '/v2/projects/:projectId/information-layers/preprocessing/:informationLayerId',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <PreprocessingInformationLayer />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  // Integrations
  {
    path: '/v2/projects/:projectId/integrations',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <DatasetList />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  {
    path: '/v2/projects/:projectId/integrations/new',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <CreateDataset />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  {
    path: '/v2/projects/:projectId/integrations/:datasetId',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <DatasetDetailV2 />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  // Machine Learning
  // Experiments
  {
    path: '/v2/projects/:projectId/machine-learning/experiments',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <ExperimentsList />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  {
    path: '/v2/projects/:projectId/machine-learning/experiments/new',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <CreateExperiment />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  {
    path: '/v2/projects/:projectId/machine-learning/experiments/:experimentId',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <ExperimentDetail />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  // Explainability
  {
    path: '/v2/projects/:projectId/machine-learning/explainability/:experimentId',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <ExplainerDashboard />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
  // Reports
  {
    path: '/v2/projects/:projectId/machine-learning/reports/:experimentId',
    element: (
      <Suspense fallback={<>...</>}>
        <AppLayoutV2>
          <AuthenticationLayout mustAdmin={true}>
            <Report />
          </AuthenticationLayout>
        </AppLayoutV2>
      </Suspense>
    ),
  },
]);

export default router;
