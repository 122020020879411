import { useState, useEffect } from 'react';

import { useAppSelector } from '../../redux';

import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { Plus } from 'react-bootstrap-icons';

import { handleInputChange } from '../../utils';
import { useCreatePredictionModelMutation } from '../../services';
import { IPredictionModelInterface } from '../../services/types';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ModelElement from './Components/ModelElement';
import './style.css';

export default function DataScience({
  projectId,
  projectDepvarName,
  modelsData,
  isFetching,
  getProjects,
}: {
  projectId: string;
  projectDepvarName: string;
  modelsData: IPredictionModelInterface[];
  isFetching: boolean;
  getProjects: () => void;
}) {
  const auth = useAppSelector((state) => state.auth);
  const [models, setModels] = useState<IPredictionModelInterface[]>([]);
  const [showNameInput, setShowNameInput] = useState<boolean>(false);
  const [newModelName, setNewModelName] = useState<string>('');
  const [createNewPredictionModelTrigger, { isLoading: creatingNewPredictionModel }] =
    useCreatePredictionModelMutation();

  useEffect(() => {
    if (modelsData) {
      setModels(modelsData);
    }
  }, [modelsData]);

  const handleShowNewModelNameInput = () => setShowNameInput(!showNameInput);

  const createNewModel = () => {
    const form = new FormData();
    form.append('name', newModelName);
    form.append('project', projectId);
    form.append('model_type', 'Classifier');
    createNewPredictionModelTrigger({ data: form, projectId })
      .unwrap()
      .then(() => {
        handleShowNewModelNameInput();
        setNewModelName('');
        getProjects();
      });
  };

  const deletePredictionModel = (modelId: string) => {
    setModels(models.filter((model) => model.id !== modelId));
  };
  const formatNumber = (num: number) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M'; // Millions
    } else if (num >= 1000) {
      return (num / 1000).toFixed(0) + 'k'; // Thousands
    } else {
      return num.toString(); // Less than 1000
    }
  };

  if (isFetching) {
    return (
      <Container>
        <Row className="mt-5">
          <Col className="centered-horizontally">
            <Spinner />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h2>Listado de modelos</h2>
        </Col>
      </Row>
      {models && models.length > 0 ? (
        <Accordion>
          {models.map((model, index) => (
            <Accordion.Item key={index} eventKey={`model-${index}`}>
              <Accordion.Header>
                {model.name}
                {model.numRows && model.numCols ? (
                  <span className="ml-2 badge bg-secondary tag">
                    {formatNumber(model.numRows)} x {model.numCols}
                  </span>
                ) : null}

                <span className="ml-2 badge bg-secondary tag">{model.modelAlgorithm}</span>
              </Accordion.Header>
              <Accordion.Body>
                <ModelElement
                  key={index}
                  modelData={model}
                  projectId={projectId}
                  projectDepvarName={projectDepvarName}
                  getProjects={getProjects}
                  deleteModelFromList={deletePredictionModel}
                />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      ) : (
        <Alert variant="info">Aún no hay modelos asociados al proyecto.</Alert>
      )}

      {auth.isAdmin && (
        <Row className="my-2">
          <Col>
            <Button
              className="d-flex flex-row align-items-center"
              variant="light"
              onClick={handleShowNewModelNameInput}
            >
              <Plus size={32} /> Agregar nuevo modelo
            </Button>
          </Col>
        </Row>
      )}

      {auth.isAdmin &&
        showNameInput &&
        (creatingNewPredictionModel ? (
          <Row>
            <Col className="centered-horizontally">
              <Spinner />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <Form.Control
                placeholder="Nombre del modelo"
                value={newModelName}
                onChange={handleInputChange(setNewModelName)}
                type="text"
              />
            </Col>
            <Col>
              <Button onClick={createNewModel}>Crear</Button>
            </Col>
          </Row>
        ))}
    </Container>
  );
}
