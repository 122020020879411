import { useState } from 'react';
import { useSetDashboardVisibilityMutation } from '../../services';
import { Row, Col, Spinner, ListGroup, Form } from 'react-bootstrap';
import { IConsolidatedItem, IEPSGCoordRefSystem } from '../../services/types';
import { formatDate } from '../../utils';
import './styles.css';
import { useAppSelector } from '../../redux/hooks';
import { Typeahead } from 'react-bootstrap-typeahead';

interface DataSetListElementProps {
  consolidated: IConsolidatedItem;
  isActive: boolean;
  coordRefSystems?: IEPSGCoordRefSystem[];
  onChangeCrsCode: (selected: Array<any>, consolidatedId: number) => void;
  handleListClick: (index: number) => void;
  updatingCrsCodeIds: Set<number>;
}

export default function DataSetListElement({
  consolidated,
  isActive,
  coordRefSystems,
  onChangeCrsCode,
  handleListClick,
  updatingCrsCodeIds,
}: DataSetListElementProps) {
  const [consolidatedState, setConsolidatedState] = useState<IConsolidatedItem>(consolidated);
  const auth = useAppSelector((state) => state.auth);
  const isAdmin = auth.isAdmin;
  const [setDashboardVisibility] = useSetDashboardVisibilityMutation();
  const [isUpdatingVisibility, setIsUpdatingVisibility] = useState<boolean>(false);

  const handleSwitchToggle = async (consolidateId: number, currentValue: boolean) => {
    const newValue = !currentValue;
    setIsUpdatingVisibility(true);
    try {
      await setDashboardVisibility({
        consolidateId,
        dashboardEnabled: newValue,
      }).then(() => {
        setConsolidatedState((prev) => ({
          ...prev,
          dashboard_enabled: newValue,
        }));
      });
    } catch (error) {
      console.error('Error setting dashboard visibility:', error);
    } finally {
      setIsUpdatingVisibility(false);
    }
  };

  return (
    <ListGroup.Item
      action
      as="li"
      className={`d-flex justify-content-between align-items-start ${isActive ? 'active-item' : ''}`}
      role="button"
      onClick={() => handleListClick(consolidated.id)}
      key={consolidated.id}
    >
      <Row className="w-100 align-items-center">
        <Col>
          <div className="ms-2 me-auto">
            <div className="fw-bold">{consolidated.study_file?.dataset_name || 'Dataset sin nombre'}</div>
            <div>{'Creado en '.concat(formatDate(consolidated.study_file?.created_at || '') || '')}</div>
            <div className="text-muted">{consolidated.final_file_filename}</div>
          </div>
        </Col>
        {isAdmin ? (
          <>
            <Col>
              {!coordRefSystems ? (
                <Spinner size="sm" />
              ) : (
                <Typeahead
                  id="coord-ref-systems"
                  labelKey={(crs: any) => `${crs.name} (${crs.dataSource}:${crs.code})`}
                  onChange={(selected) => onChangeCrsCode(selected, consolidated.id)}
                  options={coordRefSystems}
                  defaultSelected={coordRefSystems.filter((crs: any) => crs.code === consolidated.crs_code)}
                  disabled={!coordRefSystems || updatingCrsCodeIds.has(consolidated.id)}
                />
              )}
            </Col>
            <Col>
              <Form.Check
                type="switch"
                id={`switch-${consolidated.id}`}
                checked={consolidatedState.dashboard_enabled}
                onChange={() => handleSwitchToggle(consolidatedState.id, consolidatedState.dashboard_enabled)}
                disabled={isUpdatingVisibility}
              />
            </Col>
          </>
        ) : null}
      </Row>
    </ListGroup.Item>
  );
}
