import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Container } from 'react-bootstrap';

interface Props {
  xData: any;
  yData: any;
  binSizes: any;
  yName: string;
  depVarName: string;
  width: number;
  height: number;
  interestGrade?: number;
  xAxisDistribution: string;
}

const STUDY_TYPES = ['ALT', 'EST', 'LITO', 'GEOF', 'GEOQ', 'MIN', 'OTROS'];

export default function MiniLollipopChart({
  xData,
  yData,
  binSizes,
  yName,
  depVarName,
  width,
  height,
  interestGrade,
  xAxisDistribution,
}: Props) {
  const filteredXData: number[] = xData.map((value: number | null) => (value !== null ? value : 0));
  const filteredYData: number[] = yData.map((value: number | null) => (value !== null ? value : 0));
  const chartRef = useRef<SVGSVGElement>(null);
  const xDataArray = Object.values(xData) as number[];
  const maxX = Math.max(...xDataArray);
  const minX = Math.min(...xDataArray);
  const yDataArray = Object.values(yData) as number[];
  const maxY = Math.max(...yDataArray);
  const adjustedMinY = Math.min(...yDataArray) > 0 ? 0 : Math.min(...yDataArray) * 1.1;
  const adjustedMinX = 0 - (maxX - minX) * 0.1; //Just for linear data
  const yAxisTextSpacing = 70;
  const usePercentageFormat = yName.includes('%') || yName.toLowerCase().includes('pct');
  const usePercentageFormatDepvar = depVarName.includes('%') || depVarName.toLowerCase().includes('pct');
  const [currentZoomExtent, setCurrentZoomExtent] = useState<[[number, number], [number, number]] | null>(null);
  const id = 'whatever'; //for some reason this works

  const formatTickValue = (numericValue: number, decimalLimit: number) => {
    const absoluteValue = Math.abs(numericValue);
    const decimalPart = Math.abs(numericValue - Math.floor(numericValue))
      .toString()
      .split('.')[1];

    const formattedValue =
      absoluteValue >= 10000 || (decimalPart && decimalPart.length > decimalLimit)
        ? d3.format('.2e')(numericValue)
        : numericValue.toFixed(10);

    const roundedValue = parseFloat(formattedValue); // Convert back to number
    const isApproxEqual = Math.abs(numericValue - roundedValue) < Number.EPSILON;

    return isApproxEqual ? roundedValue.toString() : formattedValue;
  };

  const yAxisTickFormat = (value: number | { valueOf(): number }) => {
    const numericValue = typeof value === 'number' ? value : value.valueOf();
    const formattedValue = formatTickValue(numericValue, 3);
    return usePercentageFormat ? formattedValue + '%' : formattedValue;
  };

  const xAxisTickFormat = (value: number | { valueOf(): number }) => {
    const numericValue = typeof value === 'number' ? value : value.valueOf();
    const formattedValue = formatTickValue(numericValue, 3);
    return usePercentageFormatDepvar ? formattedValue + '%' : formattedValue;
  };

  const extractStudyColumn = (yName: string) => {
    const studyTypeRegex = STUDY_TYPES.join('|');
    const regex = new RegExp(`^(.*)(_(${studyTypeRegex})_)`);
    const match = yName.match(regex);
    if (match && match[1]) {
      return match[1].replace(/_/g, ' ');
    }
    return yName.replace(/_/g, ' ');
  };
  const yFilteredName = extractStudyColumn(yName);
  const filteredBinSizes: number[] = binSizes.map((size: number | null) => (size !== null ? size : 0));

  useEffect(() => {
    d3.select(chartRef.current).selectAll('*').remove();
    const x_label = depVarName;
    const y_label = yFilteredName;

    const title = x_label.concat(' vs ', y_label);

    const margin = {
      top: 40,
      right: 0,
      bottom: 60,
      left: 70,
    };

    const svg = d3
      .select(chartRef.current)
      .attr('viewBox', [0, 0, width, height])
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const adjustedHeight = height - margin.top - margin.bottom;

    const yMin = adjustedMinY * 1.1;
    const yMax = maxY * 1.5;
    const xMin = xAxisDistribution === 'log' ? minX : adjustedMinX;
    const xMax = maxX * 1.5;

    //Scale for bin sizes x axis
    const xScale = d3
      .scaleLinear()
      .domain([0, filteredXData.length])
      .range([0, width - margin.left - margin.right]);

    const cleanedBinSizes: number[] = filteredBinSizes.map((size) => size || 0);
    const maxBinSize = d3.max(cleanedBinSizes) || 0; //fallback
    //Scale for bin sizes y axis
    const binSizeScale = d3.scaleLinear().domain([0, maxBinSize]).range([adjustedHeight, 0]);

    svg
      .selectAll('.bin-bar')
      .data(filteredBinSizes)
      .enter()
      .append('rect')
      .attr('class', 'bin-bar')
      .attr('x', (d, i) => xScale(i))
      .attr('y', (d) => binSizeScale(d))
      .attr('width', (width - margin.left - margin.right) / filteredXData.length - 1)
      .attr('height', (d) => adjustedHeight - binSizeScale(d)) // Height based on bin size
      .attr('fill', '#D59367')
      .attr('opacity', 0.4);

    //X axis
    let x = d3
      .scaleLinear()
      .range([0, width - margin.left - margin.right])
      .domain([xMin, xMax]);

    if (xAxisDistribution === 'log') {
      x = d3
        .scaleLog()
        .domain([xMin, xMax])
        .range([0, width - margin.left - margin.right]);
    }

    const x_axis = svg.append('g').attr('transform', `translate(0, ${adjustedHeight})`).call(d3.axisBottom(x));

    if (xAxisDistribution !== 'log') {
      x_axis.call(d3.axisBottom(x).tickFormat(xAxisTickFormat));
    }

    x_axis.selectAll('text').attr('transform', 'translate(-10,0)rotate(-45)').style('text-anchor', 'end');

    // Draw bars for bin sizes
    svg
      .append('text')
      .attr('y', adjustedHeight + 50)
      .attr('x', width / 2)
      .style('text-anchor', 'middle')
      .style('font-size', '13px')
      .text(x_label);

    // Add Y axis
    const y = d3.scaleLinear().domain([yMin, yMax]).range([adjustedHeight, 0]);

    const y_axis = svg.append('g').call(d3.axisLeft(y).tickFormat(yAxisTickFormat));

    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('x', -adjustedHeight / 2)
      .attr('y', -yAxisTextSpacing)
      .style('text-anchor', 'middle')
      .style('font-size', '13px')
      .text(y_label)
      .attr('dy', '0.75em')
      .attr('dx', '-2em');

    //Chart title
    const titleText = svg.append('text').style('text-anchor', 'middle').style('font-size', '15px').text(title);

    // Calculate the width of the title text
    const titleWidth = titleText.node()?.getBBox().width || 0;

    // Calculate the x-coordinate to center the title
    const titleX = (width - titleWidth) / 2;

    titleText.attr('x', titleX + 50).attr('y', -margin.top / 2);

    //#endregion

    const brush = d3
      .brush()
      .extent([
        [0, 0],
        [width, adjustedHeight],
      ])
      .on('end', updateChart);

    if (currentZoomExtent) {
      x.domain([x.invert(currentZoomExtent[0][0]), x.invert(currentZoomExtent[1][0])]);
      y.domain([y.invert(currentZoomExtent[1][1]), y.invert(currentZoomExtent[0][1])]);
      svg.select('.brush').call(brush.move as any, null);
    }

    svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', width)
      .attr('height', adjustedHeight)
      .attr('x', 0)
      .attr('y', 0);

    const lollipop = svg.append('g').attr('clip-path', 'url(#clip)');

    svg.append('g').attr('class', 'brush').call(brush);

    lollipop
      .selectAll('line')
      .data(filteredYData)
      .enter()
      .append('line')
      .attr('x1', function (d, i) {
        return x(filteredXData[i] as number);
      })
      .attr('x2', function (d, i) {
        return x(filteredXData[i] as number);
      })
      .attr('y1', function (d) {
        return y(d as number);
      })
      .attr('y2', y(0))
      .attr('stroke', 'orange')
      .style('stroke-dasharray', '3, 3');

    lollipop
      .selectAll('circle')
      .data(filteredYData)
      .enter()
      .append('circle')
      .attr('cx', function (d, i) {
        return x(filteredXData[i] as number);
      })
      .attr('cy', function (d) {
        return y(d as number);
      })
      .attr('r', '4')
      .style('fill', 'orange')
      .attr('stroke', 'black');

    const eventLayer = svg.append('g').attr('class', 'event-layer');

    const tooltip = d3.select(`body`).selectAll(`#chart-tooltip-${id}`).data([null]);
    tooltip
      .enter()
      .append('div')
      .attr('id', `chart-tooltip-${id}`)
      .attr('class', 'plot-tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('padding', '10px')
      .style('background', 'rgba(255, 255, 255, 0.8)')
      .style('border', '1px solid #ddd')
      .style('border-radius', '5px')
      .style('pointer-events', 'none')
      .style('z-index', '10');

    // Create invisible circles for tooltips in the event layer
    eventLayer
      .selectAll('circle')
      .data(filteredYData)
      .enter()
      .append('circle')
      .attr('data-index', (d, i) => i)
      .attr('cx', function (d, i) {
        return x(filteredXData[i] as number);
      })
      .attr('cy', function (d) {
        return y(d as number);
      })
      .attr('r', 6)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseover', function (event) {
        const i = parseInt(d3.select(this).attr('data-index'));
        const xValue = filteredXData[i];
        const yValue = filteredYData[i];
        const binSize = filteredBinSizes[i];

        // Removes trial zeroes in tooltips
        const formattedXValue = parseFloat(xValue.toFixed(7));
        const formattedYValue = parseFloat(yValue.toFixed(7));

        const tooltipHtml = `
                <div><strong>${depVarName}:</strong> ${formattedXValue}</div>
                <div><strong>${y_label}:</strong> ${formattedYValue}</div>
                <div><strong>Tamaño del Bin:</strong> ${binSize}</div>
                `;
        d3.select(`#chart-tooltip-${id}`)
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY + 10}px`)
          .style('visibility', 'visible')
          .html(tooltipHtml);
      })
      .on('mouseout', function () {
        d3.select(`#chart-tooltip-${id}`).style('visibility', 'hidden');
      });

    //Area of interest initial drawing
    svg
      .insert('rect', ':first-child')
      .attr('class', 'highlight-rect')
      .attr('x', x(interestGrade ? interestGrade : 10000000000))
      .attr('y', 0)
      .attr('width', width - x.range()[0])
      .attr('height', adjustedHeight)
      .attr('fill', 'rgba(0, 0, 0, 0.1)');
    let idleTimeout: null | ReturnType<typeof setTimeout>;

    function idled() {
      idleTimeout = null;
    }

    function updateChart(event: any) {
      const extent = event.selection;
      if (!extent) {
        if (!idleTimeout) return (idleTimeout = setTimeout(idled, 350));
        setCurrentZoomExtent(null);
        x.domain([xMin, xMax]); // Update the x domain back to its original value
        y.domain([yMin, yMax]); // Update the y domain back to its original value
      } else {
        setCurrentZoomExtent(extent); // Save the current zoom extent
        y.domain([y.invert(extent[1][1]), y.invert(extent[0][1])]);
        svg.select('.brush').call(brush.move as any, null);
      }

      x_axis.transition().attr('transform', `translate(0, ${adjustedHeight})`).duration(1000).call(d3.axisBottom(x));

      if (xAxisDistribution !== 'log') {
        x_axis.call(d3.axisBottom(x).tickFormat(xAxisTickFormat));
      }

      x_axis.selectAll('text').attr('transform', 'translate(-10,0)rotate(-45)').style('text-anchor', 'end');

      y_axis.transition().duration(1000).call(d3.axisLeft(y).tickFormat(yAxisTickFormat));

      // Update lines and circles using x and y scales
      lollipop
        .selectAll('line')
        .transition()
        .duration(1000)
        .attr('x1', function (d, i) {
          return x(filteredXData[i] as number);
        })
        .attr('x2', function (d, i) {
          return x(filteredXData[i] as number);
        })
        .attr('y1', function (d) {
          return y(d as number);
        })
        .attr('y2', y(0));

      lollipop
        .selectAll('circle')
        .transition()
        .duration(1000)
        .attr('cx', function (d, i) {
          return x(filteredXData[i] as number);
        })
        .attr('cy', function (d) {
          return y(d as number);
        });

      eventLayer
        .selectAll('circle')
        .transition()
        .duration(1000)
        .attr('cx', function (d, i) {
          return x(filteredXData[i] as number);
        })
        .attr('cy', function (d) {
          return y(d as number);
        });
      if (extent) {
        let startingX = interestGrade ? interestGrade : 10000000;
        if (interestGrade && x(interestGrade) < 0) {
          startingX = x.domain()[0];
        }
        svg
          .selectAll('.highlight-rect')
          .transition()
          .duration(500) // Set the duration for the transition (in milliseconds)
          .ease(d3.easeLinear) // Set the easing function for the transition (optional, linear by default)
          .attr('x', x(startingX))
          .attr('y', 0) // Set y-coordinate to 0 to start from the top of the chart
          .attr('width', width - x.range()[0])
          .attr('height', adjustedHeight) // Adjust height to cover the entire chart area
          .attr('fill', 'rgba(0, 0, 0, 0.1)');
      } else {
        svg
          .selectAll('.highlight-rect')
          .transition()
          .duration(500) // Set the duration for the transition (in milliseconds)
          .ease(d3.easeLinear) // Set the easing function for the transition (optional, linear by default)
          .attr('x', x(interestGrade ? interestGrade : 10000000))
          .attr('y', 0) // Set y-coordinate to 0 to start from the top of the chart
          .attr('width', width - x.range()[0]) // Width from x=10 to the end of the x-axis
          .attr('height', adjustedHeight) // Adjust height to cover the entire chart area
          .attr('fill', 'rgba(0, 0, 0, 0.1)');
      }
    }
    // eslint-disable-next-line
  }, [maxX, maxY, xData, yData, yFilteredName, yName, chartRef, interestGrade]);

  return (
    <Container style={{ position: 'relative' }} className="chart">
      <svg ref={chartRef} viewBox={`0 0 ${width} ${height}`}></svg>
    </Container>
  );
}
