import React from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function ActionButton({
  id,
  children,
  title,
}: {
  id: string;
  children: React.ReactElement;
  title: string;
}) {
  return <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>{children}</OverlayTrigger>;
}
