import { Container, Row, Col, Card, Form, Button, Offcanvas } from 'react-bootstrap';
import { useEffect, useState, useMemo } from 'react';
import PlotlyHistogram from '../PlotTypes/PlotlyHistogram';
import { IStudyFile } from '../../../services/types';

interface GeneralInfoProps {
  quantileBinnedData: any;
  intervalBinnedData: any;
  naturalBreaksData: any;
  depVarName: string;
  widthHeightRatio: number;
  interestGrade: number;
  intervalBinsInfo: any;
  dataInfo: any;
  studyFile: IStudyFile | undefined;
}

interface DataEntry {
  DEPVAR: number | null;
  [key: string]: number | null;
}

export default function GeneralInfoPlots({
  quantileBinnedData,
  intervalBinnedData,
  naturalBreaksData,
  depVarName,
  widthHeightRatio,
  interestGrade,
  intervalBinsInfo,
  dataInfo,
}: GeneralInfoProps) {
  const [plotWidth, setPlotWidth] = useState(0);
  const [decimalPlaces, setDecimalPlaces] = useState<number>(5);
  const [showOffsetCanvas, setShowOffsetCanvas] = useState(false);

  const handleCloseOffsetCanvas = () => setShowOffsetCanvas(false);
  const toggleShowOffsetCanvas = () => setShowOffsetCanvas((s) => !s);

  const handleDecimalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      setDecimalPlaces(value);
    }
  };

  const selectData = (colName: string, data: any) => {
    return data.map((el: any) => el[colName]);
  };

  const getBinSize = (data: any) => {
    return data.map((el: any) => el['num_rows']);
  };

  const getSampleSize = (data: any) => {
    return data.reduce((sum: number, el: any) => sum + el['num_rows'], 0);
  };

  const replaceNullsInData = (data: any[], intervalBinsInfo: any[]) => {
    const newData = data.map((entry, i) => {
      if (entry.DEPVAR === null) {
        const newDEPVAR = (intervalBinsInfo[i].right + intervalBinsInfo[i].left) / 2;
        const newEntry: DataEntry = { DEPVAR: newDEPVAR };
        for (const key in entry) {
          if (key !== 'DEPVAR') {
            newEntry[key] = 0;
          }
        }
        return newEntry;
      } else {
        return entry;
      }
    });
    return newData;
  };
  intervalBinnedData = replaceNullsInData(intervalBinnedData, intervalBinsInfo);
  const sampleSize = getSampleSize(intervalBinnedData);
  const maxValue = dataInfo['DEPVAR']['max'].toFixed(3);
  const avgValue = dataInfo['DEPVAR']['avg'].toFixed(3);

  const yName = 'Cantidad de muestras de sondaje';

  const selectedIntervalData = useMemo(() => selectData('DEPVAR', intervalBinnedData), [intervalBinnedData]);
  const selectedQuantileData = useMemo(() => selectData('DEPVAR', quantileBinnedData), [quantileBinnedData]);
  const selectedNaturalBreaksData = useMemo(() => selectData('DEPVAR', naturalBreaksData), [naturalBreaksData]);

  const binSizeIntervalData = useMemo(() => getBinSize(intervalBinnedData), [intervalBinnedData]);
  const binSizeQuantileData = useMemo(() => getBinSize(quantileBinnedData), [quantileBinnedData]);
  const binSizeNaturalBreaksData = useMemo(() => getBinSize(naturalBreaksData), [naturalBreaksData]);

  const intervalPlotImageUrl = process.env.PUBLIC_URL + `/offcanvas-images/HistogramaRegular.png`;
  const quantilePlotImageUrl = process.env.PUBLIC_URL + `/offcanvas-images/HistogramaCuantiles.png`;
  const naturalBreaksImageUrl = process.env.PUBLIC_URL + `/offcanvas-images/naturalBreaks.png`;

  useEffect(() => {
    const handleResize = () => {
      const newWidth = (document.getElementsByClassName('chart-container')[0] as HTMLElement).offsetWidth;
      setPlotWidth(newWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="mb-3 d-flex flex-column align-items-center justify-content-center"
      id="general-information-plots-div"
    >
      <Offcanvas show={showOffsetCanvas} onHide={handleCloseOffsetCanvas}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Gráficos de Información General</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>
            En esta sección se pueden ver distintos tipos de gráfico actualmente, en primer lugar, los{' '}
            <strong>histogramas</strong>, que muestran la cantidad de muestras que componen un bin según el tipo de
            distribución. En este caso, tenemos dos tipos de agrupación de datos. En primer lugar, tenemos el gráfico{' '}
            <strong>Histograma con intervalos regulares en muestras de sondajes</strong>.
          </p>
          <img src={intervalPlotImageUrl} width={'95%'} alt={'Histograma de intervalos regulares'} />
          <p>
            Este histograma muestra cuántas muestras tiene cada bin al repartirse los datos en intervalos regulares. Por
            lo general, la gran mayoría de los datos estarán acumulados en el primer bin, y un número reducido de
            muestras estarán en los bins que representan una alta ley de {depVarName}.
          </p>
          <p>
            Luego, tenemos el <strong>Histograma con intervalos de igual frecuencia en muestras de sondajes</strong>:
          </p>
          <img src={quantilePlotImageUrl} width={'95%'} alt={'Histograma de cuantiles'} />
          <p>
            {' '}
            Este gráfico divide las muestras en grupos de igual tamaño, a menos que no sea posible, por tener una
            cantidad alta de datos con un mismo valor (como en la imagen superior). Con este gráfico se puede observar
            los valores que toma la {depVarName} en promedio en cada cuantil determinado por el número de bins
            especificado.
          </p>
          <p>
            Por último, presentamos el{' '}
            <strong>Histograma por Breaks Naturales (Jenks Natural Breaks) en muestras de sondajes</strong>:
          </p>
          <img src={naturalBreaksImageUrl} width={'95%'} alt={'Histograma de Breaks Naturales'} />
          <p>
            Este histograma utiliza el algoritmo de Breaks Naturales (Jenks) para identificar los &apos;breaks&apos; o
            cortes más naturales dentro de los datos. A diferencia de los métodos anteriores, este enfoque busca
            minimizar la varianza dentro de cada bin y maximizar la varianza entre bins. Esto resulta en una agrupación
            de datos que refleja mejor las agrupaciones naturales y patrones dentro del conjunto de datos. Los bins
            creados por este método pueden tener tamaños muy variados, dependiendo de cómo los valores se agrupan
            naturalmente. Esta técnica es especialmente útil para identificar umbrales significativos y patrones dentro
            de los datos que podrían no ser evidentes con métodos de agrupación más uniformes. Por lo tanto, este
            gráfico es particularmente valioso para destacar las características intrínsecas de {depVarName},
            permitiendo una interpretación más intuitiva de cómo se distribuyen los valores a lo largo del conjunto de
            datos.
          </p>
        </Offcanvas.Body>
      </Offcanvas>
      <Card className="mb-2">
        <Card.Header>Información General</Card.Header>
        <Card.Body>
          <Card.Title className="slide-title">Información de Sondajes Disponibles</Card.Title>
          <Card.Text className="slide-text">
            Los sondajes del proyecto incluyen un total de {sampleSize} muestras, con una ley media de {avgValue}{' '}
            {depVarName} y ley máxima de {maxValue} {depVarName}. En gris se resalta la zona de interés de{' '}
            {interestGrade} {depVarName}. El histograma de las leyes de las muestras de sondajes es el siguiente:
          </Card.Text>
        </Card.Body>
      </Card>
      <Button variant="info" onClick={toggleShowOffsetCanvas} className="mb-3">
        Guía de gráficos <i className="fa fa-question-circle" aria-hidden="true"></i>
      </Button>
      <Container className="chart-grid">
        <div id="regular-intervals-histogram">
          <Row className="grouped-chart-row">
            <Col className="chart-container d-flex align-items-center justify-content-center">
              {plotWidth !== 0 && (
                <PlotlyHistogram
                  xData={selectedIntervalData}
                  yData={binSizeIntervalData}
                  yName={yName}
                  depVarName={depVarName}
                  width={plotWidth * 0.95}
                  widthHeightRatio={widthHeightRatio}
                  interestGrade={interestGrade}
                  title={'Histograma con intervalos regulares en muestras de sondajes'}
                  isQuantile={false}
                />
              )}
            </Col>
          </Row>
        </div>
        <div id="quantiles-histogram">
          <Row className="grouped-chart-row">
            <Col className="chart-container d-flex align-items-center justify-content-center">
              {plotWidth !== 0 && (
                <PlotlyHistogram
                  xData={selectedQuantileData}
                  yData={binSizeQuantileData}
                  yName={yName}
                  depVarName={depVarName}
                  width={plotWidth * 0.95}
                  widthHeightRatio={widthHeightRatio}
                  interestGrade={interestGrade}
                  title={'Histograma con intervalos de igual frecuencia en muestras de sondajes'}
                  isQuantile={true}
                  maxDecimals={decimalPlaces}
                />
              )}
            </Col>
          </Row>
        </div>
        <div id="quantiles-histogram">
          <Row className="grouped-chart-row">
            <Col className="chart-container d-flex align-items-center justify-content-center">
              {plotWidth !== 0 && (
                <PlotlyHistogram
                  xData={selectedNaturalBreaksData}
                  yData={binSizeNaturalBreaksData}
                  yName={yName}
                  depVarName={depVarName}
                  width={plotWidth * 0.95}
                  widthHeightRatio={widthHeightRatio}
                  interestGrade={interestGrade}
                  title={'Histograma con intervalos definidos por breaks naturales'}
                  isQuantile={true}
                  maxDecimals={decimalPlaces}
                />
              )}
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="decimal-input mt-3">
        <Form.Group as={Row} controlId="decimalPlaces">
          <Form.Label column sm={3}>
            Número de decimales
          </Form.Label>
          <Col sm={9}>
            <Form.Control type="number" value={decimalPlaces} onChange={handleDecimalChange} min={0} max={10} />
          </Col>
        </Form.Group>
      </Container>
    </div>
  );
}
