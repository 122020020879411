const PREDICTION_MODELS_LOCAL_ENDPOINTS: {
  [key: string]: (id: string) => string;
} = {
  get: (id: string) => `api/prediction-models/${id}/`,
  create: () => 'api/prediction-models/',
  update: (id: string) => `api/prediction-models/${id}/`,
  delete: (id: string) => `api/prediction-models/${id}/`,
  'get-dashboard-url': (id: string) => `api/prediction-models/${id}/get-dashboard-url/`,
  'get-dashboard-status': () => `api/prediction-models/get-dashboard-status/`,
  'get-redirect-url': (id: string) => `api/prediction-models/${id}/get-redirect-url/`,
  'generate-shap-values': (id: string) => `api/prediction-models/${id}/generate-shap-values/`,
  'get-shap-values-status': () => `api/prediction-models/get-shap-values-status/`,
  'get-feature-importance-data': (id: string) => `api/prediction-models/${id}/get-feature-importance-data/`,
  'get-prediction-data': (id: string) => `api/prediction-models/${id}/get-prediction-data/`,
  'get-test-data': (id: string) => `api/prediction-models/${id}/get-test-data/`,
  'get-visualization-layers': (id: string) => `api/prediction-models/${id}/get-visualization-layers/`,
};

// TODO: Eliminar este método
export const endpointHandler = (prefix: string, id: string, suffix: string) => {
  if (prefix === 'prediction-model') {
    prefix = 'prediction-models';
  }

  if (process.env['REACT_APP_ENVIRONMENT'] === 'local') {
    return PREDICTION_MODELS_LOCAL_ENDPOINTS[suffix](id);
  } else if (['get-dashboard-status', 'get-shap-values-status'].includes(suffix)) {
    return `${prefix}/${suffix}/`;
  } else if (['get', 'update', 'delete'].includes(suffix)) {
    return `${prefix}/${id}/`;
  } else if (['create'].includes(suffix)) {
    return `${prefix}/`;
  } else {
    return `${prefix}/${id}/${suffix}/`;
  }
};
