import React, { useState, lazy, Suspense } from 'react';

import { Container, Row, Col, Button, Badge, Modal, Alert } from 'react-bootstrap';

import { IStudy } from '../../services/types';
import { useAppSelector } from '../../redux/hooks';
import { useDeleteStudyMutation } from '../../services';
import {
  formatStudyType,
  INVERSE_WEIGHTED_DISTANCE,
  NEAREST_NEIGHBOR,
  NEAREST_NEIGHBOR_2D,
  NEAREST_NEIGHBOR_3D,
  getConsolidationAlgorithm,
  QUADRANT_AVERAGE_2D,
  QUADRANT_AVERAGE_3D,
  FAST_INVERSE_WEIGHTED_DISTANCE,
} from '../../utils';
import StudyForm from './StudyForm';

import './style.css';

const NeighborhoodSurface = lazy(() => import('../../components/NeighborhoodSurface/NeighborhoodSurface'));

interface IStudyProps {
  study: IStudy;
  noProject: boolean;
  dataSetName: string;
  canSend: boolean;
  elevationGridFilename?: string;
}

export default function Study({
  study,
  noProject = false,
  dataSetName,
  canSend = true,
  elevationGridFilename,
}: IStudyProps) {
  const auth = useAppSelector((state) => state.auth);

  const [showEditingForm, setShowEditingForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  const openEditingForm = () => setShowEditingForm(true);
  const closeEditingForm = () => setShowEditingForm(false);
  const openDeleteConfirmation = () => setShowDeleteConfirmation(true);
  const closeDeleteConfirmation = () => setShowDeleteConfirmation(false);

  const [deleteStudyTrigger, { isLoading: deleting }] = useDeleteStudyMutation();

  const deleteStudy = () => {
    deleteStudyTrigger({
      studyId: study.id,
      studyRequestId: study.requestId,
    });
    closeDeleteConfirmation();
  };

  return (
    <Container className="border-bottom mt-4">
      <Row>
        <Col className="d-flex align-items-center">
          <h5 className="fw-bold">
            <span>{study.name} </span>
            <Badge bg={study.parameters.studyType.toLowerCase()}>{formatStudyType(study.parameters.studyType)}</Badge>
            <span> </span>
            <Badge bg={study.validFile ? 'success' : 'danger'}>{study.validFile ? 'Validado' : 'No validado'}</Badge>
          </h5>
        </Col>
        <Col className="d-flex justify-content-end align-items-center gap-1">
          {showEditingForm && (
            <StudyForm
              show={showEditingForm}
              onHide={closeEditingForm}
              studyData={study}
              dataSetName={dataSetName}
              elevationGridFilename={elevationGridFilename}
            />
          )}
          <Button variant="primary" onClick={openEditingForm} disabled={noProject || !canSend}>
            Editar
          </Button>
          <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation} centered>
            <Modal.Header>
              <Modal.Title>Eliminar estudio</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Estás seguro que deseas eliminar este estudio?</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" disabled={deleting || noProject} onClick={deleteStudy}>
                Eliminar estudio
              </Button>
              <Button variant="secondary" disabled={deleting || noProject} onClick={closeDeleteConfirmation}>
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>
          <Button
            variant="danger"
            onClick={openDeleteConfirmation}
            disabled={noProject || !canSend || study.isBlockStudy}
          >
            Eliminar
          </Button>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          {auth.isAdmin && <p>Algoritmo de consolidación: {getConsolidationAlgorithm(study.consolidationAlgorithm)}</p>}
        </Col>
      </Row>
      {study.consolidationAlgorithm === INVERSE_WEIGHTED_DISTANCE ||
      study.consolidationAlgorithm === FAST_INVERSE_WEIGHTED_DISTANCE ? (
        <Row>
          <Col className="d-flex flex-column justify-content-center">
            <Row>
              <Col md={10} className="d-flex flex-row justify-content-between">
                <p className="text-secondary">Eje Dirección Este (X) en metros</p>
                <p className="fw-bold">{study.parameters.semiMajorAxis}</p>
              </Col>
            </Row>
            <Row>
              <Col md={10} className="d-flex flex-row justify-content-between">
                <p className="text-secondary">Eje Dirección Norte (Y) en metros</p>
                <p className="fw-bold">
                  {study.parameters.volumeKind === 'cylinder'
                    ? study.parameters.semiMinorAxis
                    : study.parameters.semiMediumAxis}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={10} className="d-flex flex-row justify-content-between">
                <p className="text-secondary">Altura en metros</p>
                <p className="fw-bold">
                  {study.parameters.volumeKind === 'cylinder'
                    ? study.parameters.height
                    : study.parameters.semiMinorAxis}
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            <Row>
              <Col md={10} className="d-flex flex-row justify-content-between">
                <p className="fs-xs text-secondary">Factor inverso a la distancia</p>
                <p className="fw-bold">{study.parameters.m}</p>
              </Col>
            </Row>
            <Row>
              <Col md={10} className="d-flex flex-row justify-content-between">
                <p className="text-secondary">Ángulo de azimut en grados</p>
                <p className="fw-bold">{study.parameters.theta}</p>
              </Col>
            </Row>
            <Row>
              {study.parameters.volumeKind === 'ellipsoid' && (
                <Col md={10} className="d-flex flex-row justify-content-between">
                  <p className="text-secondary">Ángulo de buzamiento en grados</p>
                  <p className="fw-bold">{study.parameters.phi}</p>
                </Col>
              )}
            </Row>
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            <Row>
              <Col>
                <Suspense fallback={<></>}>
                  <NeighborhoodSurface
                    xAxis={+study.parameters.semiMajorAxis}
                    yAxis={
                      study.parameters.volumeKind === 'cylinder'
                        ? +study.parameters.semiMinorAxis
                        : +study.parameters.semiMediumAxis
                    }
                    zAxis={
                      study.parameters.volumeKind === 'cylinder'
                        ? +study.parameters.height
                        : +study.parameters.semiMinorAxis
                    }
                    azimuthDegree={+study.parameters.theta}
                    dipDegree={+study.parameters.phi}
                    volumeKind={study.parameters.volumeKind}
                    customLayout={{
                      autosize: false,
                      width: 300,
                      height: 300,
                      margin: {
                        l: 0,
                        r: 0,
                        b: 0,
                        t: 0,
                        pad: 0,
                      },
                    }}
                  />
                </Suspense>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : [NEAREST_NEIGHBOR, NEAREST_NEIGHBOR_2D, NEAREST_NEIGHBOR_3D].includes(study.consolidationAlgorithm) ? (
        <Row>
          <Col className="d-flex flex-column justify-content-center">
            <Row>
              <Col md={10} className="d-flex flex-row justify-content-between">
                <p className="text-secondary">Distancia máxima en metros</p>
                <p className="fw-bold">{study.parameters.maxDistance}</p>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      ) : [QUADRANT_AVERAGE_2D, QUADRANT_AVERAGE_3D].includes(study.consolidationAlgorithm) ? (
        <Row>
          <Col className="d-flex flex-column justify-content-center">
            <Row>
              <Col md={10} className="d-flex flex-row justify-content-between">
                <p className="text-secondary">Espaciamiento en metros</p>
                <p className="fw-bold">{study.parameters.spacing}</p>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      ) : (
        <Row>
          <Col className="d-flex flex-column justify-content-center">
            <Row>
              <Col md={10} className="d-flex flex-row justify-content-between">
                <p className="text-secondary">Arista en metros en eje X</p>
                <p className="fw-bold">{study.parameters.spacingX}</p>
              </Col>
            </Row>
            <Row>
              <Col md={10} className="d-flex flex-row justify-content-between">
                <p className="text-secondary">Arista en metros en eje Y</p>
                <p className="fw-bold">{study.parameters.spacingY}</p>
              </Col>
            </Row>
            <Row>
              <Col md={10} className="d-flex flex-row justify-content-between">
                <p className="text-secondary">Arista en metros en eje Z</p>
                <p className="fw-bold">{study.parameters.spacingZ}</p>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      )}

      {!study.fileURL && <Alert variant="danger">Estudio sin archivo.</Alert>}
    </Container>
  );
}
