import React, { useState } from 'react';

import { Alert, Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import { ArrowCounterclockwise } from 'react-bootstrap-icons';

import { useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';

import { useCreateDataSetMutation } from '../../services';
import { AuthenticationLayout } from '../../layouts';
import { handleInputChange } from '../../utils';
import { IValidationError } from '../../services/types';

interface CreateDataSeetProps {
  projectId: string;
}

interface CreationError {
  blocksErrors: IValidationError[];
  gridErrors: IValidationError[];
}

export default function CreateDataSet({ projectId }: CreateDataSeetProps) {
  const auth: any = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const [dataSetName, setDataSetName] = useState<string>('');
  const [blocksFile, setBlocksFile] = useState<File | undefined>(undefined);
  const [gridFile, setGridFile] = useState<File | undefined>(undefined);

  const [blocksErrors, setBlocksErrors] = useState<IValidationError[]>([]);
  const [blocksWarnings, setBlocksWarnings] = useState<IValidationError[]>([]);
  const [gridErrors, setGridErrors] = useState<IValidationError[]>([]);
  const [gridWarnings, setGridWarnings] = useState<IValidationError[]>([]);
  const [anotherError, setAnotherError] = useState<boolean>(false);

  const goBack = () =>
    navigate(`/projects/${projectId}/`, {
      state: { comesFromDataSetPages: true },
    });

  const [createDataSetTrigger, { isLoading: isCreating }] = useCreateDataSetMutation();

  const handleBlocksFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;
    if (files) {
      setBlocksFile(files[0]);
    }
  };

  const handleGridFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;
    if (files) {
      setGridFile(files[0]);
    }
  };

  const createDataSet = async () => {
    if (blocksFile) {
      setAnotherError(false);
      setBlocksErrors([]);
      setBlocksWarnings([]);
      setGridErrors([]);
      setGridWarnings([]);
      const form = new FormData();
      form.append('name', dataSetName);
      form.append('blocks_file', blocksFile);
      gridFile && form.append('grid_file', gridFile);
      form.append('can_send', JSON.stringify(true));
      form.append('project', projectId);
      createDataSetTrigger(form)
        .unwrap()
        .then((dataSetId: string) => {
          if (dataSetId) {
            navigate(`/projects/${projectId}/data-sets/${dataSetId}`);
          }
        })
        .catch((error: CreationError) => {
          if (error.blocksErrors.length > 0 || error.gridErrors.length > 0) {
            setBlocksErrors(error.blocksErrors.filter((err: IValidationError) => err.severity === 'Error'));
            setBlocksWarnings(error.blocksErrors.filter((err: IValidationError) => err.severity === 'Warning'));
            setGridErrors(error.gridErrors.filter((err: IValidationError) => err.severity === 'Error'));
            setGridWarnings(error.gridErrors.filter((err: IValidationError) => err.severity === 'Warning'));
          } else {
            setAnotherError(true);
          }
        });
    }
  };

  return (
    <AuthenticationLayout mustAdmin={false} mustAuth={true} noAuth={false}>
      <Container className="px-3 my-3">
        <Row>
          <Col>
            <Button
              className="d-flex flex-row align-items-center"
              onClick={goBack}
              variant="outline-secondary"
              size="sm"
              disabled={isCreating}
            >
              <ArrowCounterclockwise size={32} />
              Volver a Data Sets
            </Button>
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            <Form.Label>
              <h5>Nombre del data set</h5>
            </Form.Label>
            <Form.Control
              value={dataSetName}
              onChange={handleInputChange(setDataSetName)}
              disabled={isCreating}
              type="text"
              className="parameter-input"
              placeholder="Ingresa el nombre del data set"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Búsqueda de vecinos</h5>
            <p>Cargar archivos csv y parámetros de vecindad</p>
          </Col>
        </Row>
        <Row className="bottom-border py-4 my-6">
          <Col className="d-flex align-items-center">
            <span>Bloques</span>
          </Col>
          <Col className="d-flex align-items-center">
            <span>{blocksFile ? blocksFile.name : 'No hay archivo seleccionado'}</span>
          </Col>
          <Col className="centered-horizontally">
            <Form.Control type="file" onChange={handleBlocksFileChange} disabled={isCreating} />
          </Col>
        </Row>
        {auth.isAdmin && (
          <Row className="bottom-border py-4 my-6">
            <Col className="d-flex align-items-center">
              <span>Grilla (opcional)</span>
            </Col>
            <Col className="d-flex align-items-center">
              <span>{gridFile ? gridFile.name : 'No hay archivo seleccionado'}</span>
            </Col>
            <Col className="centered-horizontally">
              <Form.Control type="file" onChange={handleGridFileChange} disabled={isCreating} />
            </Col>
          </Row>
        )}

        {anotherError && (
          <Alert variant="danger" className="centered-horizontally">
            Lo sentimos: hubo un error.
          </Alert>
        )}

        {(blocksErrors.length > 0 || blocksWarnings.length > 0) && (
          <Row className="mt-4">
            <Col className="centered-horizontally">
              <span className="text-danger">Se detectaron errores en el archivo de bloques</span>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            {blocksErrors.length > 0 && (
              <Alert variant="danger">
                {blocksErrors.map((err: IValidationError, i: number) => (
                  <Row key={i}>
                    <Col className="d-flex justify-content-center">
                      <span>{err.description}</span>
                    </Col>
                  </Row>
                ))}
              </Alert>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            {blocksWarnings.length > 0 && (
              <Alert variant="warning">
                {blocksWarnings.map((err: IValidationError, i: number) => (
                  <Row key={i}>
                    <Col className="d-flex justify-content-center">
                      <span>{err.description}</span>
                    </Col>
                  </Row>
                ))}
              </Alert>
            )}
          </Col>
        </Row>
        {(gridErrors.length > 0 || gridWarnings.length > 0) && (
          <Row className="mt-4">
            <Col className="centered-horizontally">
              <span className="text-danger">Se detectaron errores en el archivo de grilla</span>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            {gridErrors.length > 0 && (
              <Alert variant="danger">
                {gridErrors.map((err: IValidationError, i: number) => (
                  <Row key={i}>
                    <Col className="d-flex justify-content-center">
                      <span>{err.description}</span>
                    </Col>
                  </Row>
                ))}
              </Alert>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            {gridWarnings.length > 0 && (
              <Alert variant="warning">
                {gridWarnings.map((err: IValidationError, i: number) => (
                  <Row key={i}>
                    <Col className="d-flex justify-content-center">
                      <span>{err.description}</span>
                    </Col>
                  </Row>
                ))}
              </Alert>
            )}
          </Col>
        </Row>

        <Row className="my-3 justify-content-center">
          <Col md={4} className="d-flex justify-content-center">
            {isCreating ? (
              <Spinner />
            ) : (
              <Button variant="primary" onClick={createDataSet} disabled={!dataSetName || !blocksFile}>
                Crear data set
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </AuthenticationLayout>
  );
}
