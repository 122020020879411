import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { default as p } from '../../../package.json';

import './style.css';

export default function Footer() {
  return (
    <Container fluid className="footer">
      <Row className="text-center">
        <Col>&copy; {new Date().getFullYear()} Mineral Forecast</Col>
        <Col></Col>
        <Col>{p.version}</Col>
      </Row>
    </Container>
  );
}
