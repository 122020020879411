export const STUDY_TYPE_DICT: { [key: string]: string } = {
  ALT: 'Alteración',
  EST: 'Estructuras',
  LITO: 'Litología',
  GEOF: 'Geofísica',
  GEOQ: 'Geoquímica',
  MIN: 'Mineralización',
  OTROS: 'Otras variables',
};

export const ALLOWED_EXTENSIONS_BY_STUDY_TYPE: { [key: string]: string } = {
  ALT: '.csv',
  EST: '.csv,.rar,.zip',
  LITO: '.csv,.rar,.zip',
  GEOF: '.csv,.rar,.zip',
  GEOQ: '.csv',
  MIN: '.csv',
  OTROS: '.csv',
};

export const BIN_TYPE_DICT: { [key: string]: string } = {
  REG_BIN: 'Regular',
  Q_BIN: 'Quantile',
};

export const SEARCH_MODE_DICT: { [key: string]: string } = {
  legacy: 'Legacy',
  legacypandas: 'Legacy Pandas',
};

export const INPUT_FILE_TYPE_DICT: { [key: string]: string } = {
  blocks: 'Bloques',
  grid: 'Grilla',
};

export const STUDY_LOG_STATE_DICT: { [key: number]: string } = {
  0: 'NOT STARTED',
  1: 'STARTED',
  2: 'FINISHED',
  3: 'FINISHED WITH ERROR',
  4: 'MEMORY ERROR',
  5: 'CANCELED',
};

export const PREPROCESSING_LOG_STATE_DICT: { [key: number]: string } = {
  0: 'NOT STARTED',
  1: 'STARTED',
  2: 'FINISHED',
  3: 'FINISHED WITH ERROR',
  4: 'MEMORY ERROR',
  5: 'CANCELED',
};

// Consolidations Algorithms
export const INVERSE_WEIGHTED_DISTANCE = 'inverse_weighted_distance';
export const NEAREST_NEIGHBOR = 'nearest_neighbor';
export const NEAREST_NEIGHBOR_2D = 'nearest_neighbor_2d';
export const NEAREST_NEIGHBOR_3D = 'nearest_neighbor_3d';
export const QUADRANT_AVERAGE_2D = 'quadrant_average_2d';
export const QUADRANT_AVERAGE_3D = 'quadrant_average_3d';
export const RECTANGULAR_PRISM = 'rectangular_prism';
export const FAST_INVERSE_WEIGHTED_DISTANCE = 'fast_inverse_weighted_distance';

export const CONSOLIDATION_ALGORITHMS: { [key: string]: string } = {
  [INVERSE_WEIGHTED_DISTANCE]: 'Inverso de la distancia ponderada',
  [NEAREST_NEIGHBOR_2D]: 'Vecino más cercano (2D)',
  [NEAREST_NEIGHBOR_3D]: 'Vecino más cercano (3D)',
  [QUADRANT_AVERAGE_2D]: '[Deprecado] Promedio de cuadrante (2D)',
  [QUADRANT_AVERAGE_3D]: '[Deprecado] Promedio de cuadrante (3D)',
  [RECTANGULAR_PRISM]: 'Promedio de cuadrante',
  [FAST_INVERSE_WEIGHTED_DISTANCE]: 'Inverso de la distancia ponderada (Rápido)',
};

export const getConsolidationAlgorithm = (algorithm: string) => {
  if (algorithm) {
    if (algorithm === NEAREST_NEIGHBOR) {
      return CONSOLIDATION_ALGORITHMS[NEAREST_NEIGHBOR_3D];
    }
    return CONSOLIDATION_ALGORITHMS[algorithm];
  }
  return CONSOLIDATION_ALGORITHMS[INVERSE_WEIGHTED_DISTANCE];
};

export const VALID_CONSOLIDATION_ALGORITHMS = [
  NEAREST_NEIGHBOR_2D,
  NEAREST_NEIGHBOR_3D,
  RECTANGULAR_PRISM,
  FAST_INVERSE_WEIGHTED_DISTANCE,
];

export const PREDICTION_MODEL_TYPE_REGRESSOR = 'Regressor';
export const PREDICTION_MODEL_TYPE_CLASSIFIER = 'Classifier';
export const PREDICTION_MODEL_TYPE_MULTICLASSIFIER = 'Multiclassifier';

export const PREDICTION_MODEL_TYPE_DICT: { [key: string]: string } = {
  [PREDICTION_MODEL_TYPE_REGRESSOR]: 'Regresor',
  [PREDICTION_MODEL_TYPE_CLASSIFIER]: 'Clasificador',
  [PREDICTION_MODEL_TYPE_MULTICLASSIFIER]: 'Multiclasificador',
};
