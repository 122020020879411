import React from 'react';

import { Storage } from 'aws-amplify';

export function SetS3Config() {
  Storage.configure({
    bucket: process.env['REACT_APP_S3_BUCKET_NAME'],
    level: 'public',
    region: process.env['REACT_APP_S3_REGION'],
    identityPoolId: process.env['REACT_APP_COGNITO_IDENTITY_POOL'],
    customPrefix: {
      public: 'media/',
    },
  });
}

export async function uploadFileToS3(
  filePath: string,
  file: File,
  setUploadProgress?: React.Dispatch<React.SetStateAction<number>>,
  contentType: string = 'text/plain'
) {
  await Storage.put(filePath, file, {
    cacheControl: 'no-cache',
    contentType,
    progressCallback: (progress: any) => {
      if (setUploadProgress) {
        setUploadProgress((100 * progress.loaded) / progress.total);
      }
    },
  });
}
