import React, { useEffect } from 'react';

import { Container, Col, Row } from 'react-bootstrap';

import './style.css';
import logo from '../../assets/logo.png';

export default function SplashScreen() {
  useEffect(() => {
    document.body.classList.add('bg-dark');
  }, []);
  useEffect(
    () => () => {
      document.body.classList.remove('bg-dark');
    },
    []
  );

  return (
    <Container className="splash-screen my-auto">
      <Row>
        <Col>
          <div className="centered-horizontally">
            <img src={logo} alt="Mineral Forecast" />
          </div>
          <div className="centered-horizontally loading-dot">.</div>
        </Col>
      </Row>
    </Container>
  );
}
