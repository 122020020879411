import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IRenameVariablesColumn } from './types';
import { config } from '../config';
import { getAccessToken } from './auth';

export const dataManagementApi = createApi({
  reducerPath: 'dataManagementApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.BACKEND_URL}/api/`,
    prepareHeaders: async (headers: Headers) => {
      const idToken = await getAccessToken();
      headers.set('authorization', idToken);
      return headers;
    },
    timeout: 3000000, // equals to 5 minutes
  }),
  endpoints: (builder) => ({
    startGeochemicalProcess: builder.mutation<any, string>({
      query: (identityId: string) => ({
        url: 'geochemical/start/',
        method: 'POST',
        body: { identityId },
      }),
    }),
    processRenameVariablesFile: builder.mutation<IRenameVariablesColumn[], FormData>({
      query: (data: FormData) => ({
        url: 'rename-variables/process-file/',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: IRenameVariablesColumn[]) => response,
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    applyModifications: builder.mutation<any, { data: FormData; filename: string }>({
      query: ({ data, filename }: { data: FormData; filename: string }) => ({
        url: 'rename-variables/apply-modifications/',
        method: 'POST',
        body: data,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const responseFile = new File([await response.blob()], filename, { type: 'text/csv' });
            const url = window.URL.createObjectURL(responseFile);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            URL.revokeObjectURL(url);
          }
        },
        cache: 'no-cache',
      }),
      transformResponse: (response: any) => response,
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
  }),
});

export const {
  useStartGeochemicalProcessMutation,
  useProcessRenameVariablesFileMutation,
  useApplyModificationsMutation,
} = dataManagementApi;
