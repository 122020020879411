import React, { useState, useEffect, useMemo } from 'react';

import { Alert, Col, Row, Form, ProgressBar, Spinner } from 'react-bootstrap';
import {} from 'react-bootstrap-icons';

import {
  useUploadFileToS3Mutation,
  usePreprocessingFileInS3Mutation,
  usePreprocessingStatusQuery,
} from '../../services';

interface IFileUploaderProps {
  fileDescription?: JSX.Element;
  filename: string | undefined;
  fileURL: string;
  fileExtension: string;
  model: string;
  id: string;
  extraCol?: JSX.Element;
  fileAttributeName: string;
  setPerformUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  rowClassName?: string;
  submitOnChange?: boolean;
  onChangeCallback?: (file: File) => void;
  preprocessingOnSuccessCallback?: () => void;
  preprocessingOnErrorCallback?: () => void;
  uploadTrigger?: boolean;
  spacing?: string;
  studyType?: string;
  varName?: string;
  disabled?: boolean;
}

const FINISHED = 2;
const FINISHED_WITH_ERROR = 3;
const MEMORY_ERROR = 4;
const CANCELED = 5;

export default function FileUploader({
  fileDescription,
  filename,
  fileURL,
  fileExtension,
  model,
  id,
  fileAttributeName,
  extraCol,
  preprocessingOnErrorCallback,
  preprocessingOnSuccessCallback,
  setPerformUpdate,
  onChangeCallback,
  submitOnChange = true,
  uploadTrigger = false,
  rowClassName = 'bottom-border py-4 my-6',
  spacing = '0',
  studyType = 'OTROS',
  varName = '',
  disabled = false,
}: IFileUploaderProps) {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [requestStatusInput, setRequestStatusInput] = useState<{
    logId: string;
    model: string;
  } | null>(null);
  const [isPreprocessing, setIsPreprocessing] = useState<boolean>(false);
  const [preprocessingError, setPreprocessingError] = useState<string | null>(null);

  const [updateFileTrigger, { isLoading: updatingFile, reset: resetUpdateFile }] = useUploadFileToS3Mutation();

  const [preprocessingFileTrigger] = usePreprocessingFileInS3Mutation();
  const { data: preprocessingStatus } = usePreprocessingStatusQuery(requestStatusInput, {
    skip: !requestStatusInput,
    pollingInterval: requestStatusInput ? 5000 : undefined,
  });

  useEffect(() => {
    if (preprocessingStatus) {
      if (preprocessingStatus.state === FINISHED) {
        if (preprocessingOnSuccessCallback) {
          preprocessingOnSuccessCallback();
        }
        setPerformUpdate(false);
        setRequestStatusInput(null);
        setIsPreprocessing(false);
      } else if (
        preprocessingStatus.state === FINISHED_WITH_ERROR ||
        preprocessingStatus.state === MEMORY_ERROR ||
        preprocessingStatus.state === CANCELED
      ) {
        if (preprocessingOnErrorCallback) {
          preprocessingOnErrorCallback();
        }
        setPerformUpdate(false);
        setRequestStatusInput(null);
        setIsPreprocessing(false);
        setPreprocessingError(preprocessingStatus.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preprocessingStatus]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetUpdateFile();
    setPreprocessingError(null);
    const files = (e.target as HTMLInputElement).files;
    if (files) {
      setFile(files[0]);
      if (submitOnChange) {
        submitFile(files[0]);
      } else {
        if (onChangeCallback) {
          onChangeCallback(files[0]);
        }
      }
    }
  };

  useEffect(() => {
    if (file && uploadTrigger) {
      submitFile(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadTrigger]);

  const submitFile = (fileToUpload: File) => {
    resetUpdateFile();
    setPerformUpdate(true);
    setPreprocessingError(null);

    const form = new FormData();
    form.append('input_file', fileToUpload);
    setUploadProgress(0);
    form.append('id', id);
    updateFileTrigger({
      id,
      model,
      fileAttributeName,
      file: fileToUpload,
      setUploadProgress,
    })
      .then((response: any) => {
        if (response.error) {
          setIsPreprocessing(false);
          setPreprocessingError(`ERROR: ${response.error.data}`);
          return;
        }
        setIsPreprocessing(true);
        let logId: string = '';
        if ('data' in response) {
          logId = response.data.logId;
        }
        preprocessingFileTrigger({
          model,
          id,
          fileAttributeName,
          logId,
          spacing,
          studyType,
          varName,
        })
          .unwrap()
          .then(() => {
            setRequestStatusInput({ logId, model });
          })

          .catch(() => {
            setIsPreprocessing(false);
            setPreprocessingError('ERROR: Se ha producido un error');
          });
      })
      .catch(() => {
        setIsPreprocessing(false);
        setPreprocessingError('ERROR: Se ha producido un error');
      });
  };

  const errors = useMemo(() => {
    if (preprocessingError) {
      const listErrorsWarnings = preprocessingError.split('\n');
      const errors: string[] = [];
      listErrorsWarnings.forEach((el: string) => {
        const splittedElement = el.split(':');
        if (splittedElement[0] === 'ERROR') {
          errors.push(splittedElement[1]);
        }
      });
      return errors;
    }
    return [];
  }, [preprocessingError]);

  const warnings = useMemo(() => {
    if (preprocessingError) {
      const listErrorsWarnings = preprocessingError.split('\n');
      const w: string[] = [];
      listErrorsWarnings.forEach((el: string) => {
        const splittedElement = el.split(':');
        if (splittedElement[0] === 'WARNING') {
          w.push(splittedElement[1]);
        }
      });
      return w;
    }
    return [];
  }, [preprocessingError]);

  return (
    <>
      <Row className={rowClassName}>
        {fileDescription ? fileDescription : <></>}
        <Col className="d-flex align-items-center">
          {updatingFile ? (
            <ProgressBar now={uploadProgress} className="w-100" />
          ) : filename ? (
            <a href={fileURL} download target="_blank" rel="noreferrer">
              {filename}
            </a>
          ) : file ? (
            <></>
          ) : (
            <span>No hay archivo seleccionado</span>
          )}
        </Col>
        <Col>
          <Form.Control
            type="file"
            disabled={isPreprocessing || updatingFile || disabled}
            onChange={handleFileChange}
            accept={fileExtension}
          />
        </Col>
        {extraCol && (
          <Col xs={3} className="text-end">
            {extraCol}
          </Col>
        )}
      </Row>
      {isPreprocessing && (
        <>
          <Row className="my-2">
            <Col className="centered-horizontally">
              <Spinner />
            </Col>
          </Row>
          <Row>
            <Col className="centered-horizontally">Preprocesando archivo ...</Col>
          </Row>
        </>
      )}
      {errors.length > 0 && (
        <Alert variant="danger">
          {errors.map((err: string, i: number) => (
            <Row key={i}>
              <Col>{err}</Col>
            </Row>
          ))}
          <Row></Row>
        </Alert>
      )}
      {warnings.length > 0 && (
        <Alert variant="warning">
          {warnings.map((err: string, i: number) => (
            <Row key={i}>
              <Col>{err}</Col>
            </Row>
          ))}
          <Row></Row>
        </Alert>
      )}
    </>
  );
}
