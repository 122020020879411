import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { UseMutationResult } from '@tanstack/react-query';

interface IModalMutationConfirmationProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  textTitle: string;
  textConfirmation: string;
  mutation: UseMutationResult<any, Error, any, any>;
  mutationArgs: any;
  onSuccessCallback?: () => void;
}

export default function ModalMutationConfirmation(props: IModalMutationConfirmationProps) {
  const { showModal, setShowModal, textTitle, textConfirmation, mutation, mutationArgs, onSuccessCallback } = props;

  const onHide = () => setShowModal(false);
  const confirm = () =>
    mutation.mutateAsync(mutationArgs).then(() => {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
      onHide();
    });

  return (
    <Modal show={showModal} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{textTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {mutation.isPending ? (
          <Spinner />
        ) : mutation.isIdle ? (
          <p>{textConfirmation}</p>
        ) : (
          mutation.isError && (
            <Alert variant="danger" className="my-1">
              Lo sentimos: hubo un error.
            </Alert>
          )
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" disabled={mutation.isPending} onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="dark" disabled={mutation.isPending} onClick={confirm}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
