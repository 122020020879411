import { useState, useRef, useEffect, useMemo } from 'react';
import { useGetConsolidatedFilesQuery, useGetCoordRefSystemQuery, useSetCrsCodeMutation } from '../../services';
import { AuthenticationLayout } from '../../layouts';
import { Container, Row, Col, Spinner, ListGroup, Alert } from 'react-bootstrap';
import { IConsolidatedItem, IEPSGCoordRefSystem } from '../../services/types';
import GoToDashboard from './GoToDashboard';
import './styles.css';
import { useAppSelector } from '../../redux/hooks';
import DataSetListElement from './DataSetListElement';
import { scrollToElement } from '../../utils';

interface DashboardProjectDetailProps {
  projectId: string;
}

export default function DashboardProjectDetail({ projectId }: DashboardProjectDetailProps) {
  const [activeConsolidatedId, setActiveConsolidatedId] = useState<undefined | number>(undefined);
  const { data: consolidates, isFetching } = useGetConsolidatedFilesQuery(projectId);
  const [allConsolidates, setAllConsolidates] = useState<IConsolidatedItem[] | undefined>(undefined);
  const [visibleConsolidates, setVisibleConsolidates] = useState<IConsolidatedItem[] | undefined>(undefined);
  const formRef = useRef<HTMLDivElement | null>(null);
  const auth = useAppSelector((state) => state.auth);
  const isAdmin = auth.isAdmin;
  const [updateCrsCodeTrigger] = useSetCrsCodeMutation();
  const [updatingCrsCodeIds, setUpdatingCrsCodeIds] = useState<Set<number>>(new Set());

  const keywordsWGS84 = ['wgs', '84'];
  const keywordsPSAD56 = ['psad56'];
  const keywordsSIRGAS = ['sirgas', 'chile'];
  const pageSize = 1000;
  const { data: coordRefSystemsWGS84, isLoading: gettingCoordRefSystemsWGS84 } = useGetCoordRefSystemQuery({
    keywords: keywordsWGS84,
    pageSize,
  });
  const { data: coordRefSystemsPSAD56, isLoading: gettingCoordRefSystemsPSAD56 } = useGetCoordRefSystemQuery({
    keywords: keywordsPSAD56,
    pageSize,
  });
  const { data: coordRefSystemsSIRGAS, isLoading: gettingCoordRefSystemsSIRGAS } = useGetCoordRefSystemQuery({
    keywords: keywordsSIRGAS,
    pageSize,
  });

  const coordRefSystems: Array<IEPSGCoordRefSystem> = useMemo(() => {
    let result: Array<IEPSGCoordRefSystem> = [];
    if (coordRefSystemsWGS84) {
      result = [...result, ...coordRefSystemsWGS84];
    }
    if (coordRefSystemsPSAD56) {
      result = [...result, ...coordRefSystemsPSAD56];
    }
    if (coordRefSystemsSIRGAS) {
      result = [...result, ...coordRefSystemsSIRGAS];
    }
    return result;
  }, [coordRefSystemsWGS84, coordRefSystemsPSAD56, coordRefSystemsSIRGAS]);

  const handleListClick = (index: number) => {
    setActiveConsolidatedId(index);
    scrollToElement('go-to-dashboard-row');
  };

  useEffect(() => {
    if (consolidates) {
      setAllConsolidates(consolidates);
      setVisibleConsolidates(consolidates?.filter((consolidate: IConsolidatedItem) => consolidate.dashboard_enabled));
    }
  }, [consolidates]);

  const onChangeCrsCode = (selected: Array<any>, consolidatedId: number) => {
    if (selected.length === 1 && activeConsolidatedId) {
      const newValue = selected[0].code;
      const newConsolidates = allConsolidates?.map((item) =>
        item.id === activeConsolidatedId ? { ...item, crs_code: newValue } : item
      );
      setUpdatingCrsCodeIds((prev) => new Set(prev).add(consolidatedId)); // Add ID to the set
      updateCrsCodeTrigger({
        consolidatedId: activeConsolidatedId,
        crs_code: newValue,
      })
        .unwrap()
        .then(() => {
          setAllConsolidates(newConsolidates);
        })
        .finally(() => {
          setUpdatingCrsCodeIds((prev) => {
            const newSet = new Set(prev);
            newSet.delete(consolidatedId); // Remove ID from the set
            return newSet;
          });
        });
    }
  };

  const isLoadingSomething: boolean = useMemo(() => {
    return isFetching || gettingCoordRefSystemsPSAD56 || gettingCoordRefSystemsWGS84 || gettingCoordRefSystemsSIRGAS;
  }, [isFetching, gettingCoordRefSystemsPSAD56, gettingCoordRefSystemsSIRGAS, gettingCoordRefSystemsWGS84]);

  return (
    <AuthenticationLayout mustAuth={true} mustAdmin={false}>
      <Container className="px-3 my-3">
        {isLoadingSomething ? (
          <Row>
            <Col className="centered-horizontally my-1">
              <Spinner />
            </Col>
          </Row>
        ) : (
          <>
            <Container className="border p-4 my-4 list-container">
              <h2 className="list-title">Data Sets</h2>
              <Alert variant="info">Selecciona el resultado del dataset del que quieres analizar.</Alert>
              <ListGroup as="ol">
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                  variant="secondary"
                >
                  <Row className="w-100">
                    <Col>
                      <div className="fw-bold">Nombre</div>
                    </Col>
                    {isAdmin ? (
                      <>
                        <Col>
                          <div className="fw-bold">Sistema de coordenadas</div>
                        </Col>
                        <Col>
                          <div className="fw-bold">Visible por usuario</div>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                </ListGroup.Item>
                {isAdmin ? (
                  <>
                    {allConsolidates?.map((consolidated: IConsolidatedItem) => (
                      <DataSetListElement
                        consolidated={consolidated}
                        key={consolidated.id}
                        isActive={activeConsolidatedId === consolidated.id}
                        coordRefSystems={coordRefSystems}
                        onChangeCrsCode={onChangeCrsCode}
                        handleListClick={handleListClick}
                        updatingCrsCodeIds={updatingCrsCodeIds}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {visibleConsolidates?.map((consolidated: IConsolidatedItem) => (
                      <DataSetListElement
                        consolidated={consolidated}
                        key={consolidated.id}
                        isActive={activeConsolidatedId === consolidated.id}
                        coordRefSystems={coordRefSystems}
                        onChangeCrsCode={onChangeCrsCode}
                        handleListClick={handleListClick}
                        updatingCrsCodeIds={updatingCrsCodeIds}
                      />
                    ))}
                  </>
                )}
              </ListGroup>
            </Container>
            <Row className="d-flex flex-wrap" id="go-to-dashboard-row">
              <Container ref={formRef}>
                <GoToDashboard projectId={projectId} consolidatedId={activeConsolidatedId}></GoToDashboard>
              </Container>
            </Row>
          </>
        )}
      </Container>
    </AuthenticationLayout>
  );
}
