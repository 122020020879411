import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  IProject,
  IProjectItem,
  IDataSetItem,
  IDataSet,
  IStudy,
  IStudyInput,
  IConsolidated,
  IValidationError,
  ITaskStatus,
  IConsolidationTaskStatus,
  IConsolidatedItem,
  IStudyVariables,
  IStudySent,
  IStudyFile,
  IMapUrl,
  IMapData,
} from './types';

import {
  IProjectListItem,
  IProject as IProjectV2,
  ITotalCount,
  IConsolidatedFilesPerDataset,
} from '../definitions/projects';

import { parseResponseToStudy, parseResponseToResults } from '../utils';

import { getAccessToken } from './auth';
import { config } from '../config';

const baseUrl = `${config.BACKEND_URL}/api/`;

//// V2 Endpoints
export async function getProjectsList(): Promise<IProjectListItem[]> {
  const response = await fetch(`${baseUrl}projects/`, {
    method: 'get',
    headers: {
      authorization: await getAccessToken(),
    },
  });

  const data = await response.json();
  return data.map((el: any) => ({
    id: el.id,
    legibleId: el.legible_id,
    name: el.name,
    description: el.description,
    imageURL: el.image_file,
    companyLegibleId: el.company_legible_id,
    companyName: el.company_name,
    companyColorHex: el.company_color_hex,
    companyCountryCode: el.company_country_code,
    depvarName: el.depvar_name,
    depvarUnit: el.depvar_unit,
    depvarCut: el.depvar_cut,
    crsCode: el.crs_code,
  }));
}

export async function getProjectDetail(projectId: string): Promise<IProjectV2> {
  const response = await fetch(`${baseUrl}projects/${projectId}/`, {
    method: 'get',
    headers: {
      authorization: await getAccessToken(),
    },
  });
  const data = await response.json();
  return {
    id: data.id,
    legibleId: data.legible_id,
    name: data.name,
    description: data.description,
    imageURL: data.image_file,
    companyLegibleId: data.company_legible_id,
    companyName: data.company_name,
    companyColorHex: data.company_color_hex,
    companyCountryCode: data.company_country_code,
    depvarName: data.depvar_name,
    depvarUnit: data.depvar_unit,
    depvarCut: data.depvar_cut,
    crsCode: data.crs_code,
    elevationFilename: data.elevation_filename,
    elevationFileURL: data.elevation_file,
    areaOfInterestFilename: data.area_of_interest_filename,
    areaOfInterestFileURL: data.area_of_interest_file,
  };
}

export async function sendToGenerateSurface(projectId: string): Promise<string> {
  try {
    const response = await fetch(`${baseUrl}projects/${projectId}/generate-surface/`, {
      method: 'post',
      headers: {
        authorization: await getAccessToken(),
      },
    });
    if (response) {
      return 'Ok';
    } else {
      return '';
    }
  } catch {
    return 'Error';
  }
}

export async function getTotalCounter(projectId: string): Promise<ITotalCount> {
  const response = await fetch(`${baseUrl}projects/get-total-counter/?project_id=${projectId}`, {
    method: 'get',
    headers: {
      authorization: await getAccessToken(),
    },
  });
  if (response.ok) {
    const data = (await response.json()) as any;
    return {
      drillings: { valid: data.drillings.valid, notValid: data.drillings.not_valid },
      informationLayers: { valid: data.information_layers.valid, notValid: data.information_layers.not_valid },
      datasets: { consolidatedFiles: data.datasets.consolidated_files },
      machineLearning: {
        withResults: data.machine_learning.with_results,
        withoutResults: data.machine_learning.without_results,
      },
    };
  } else {
    throw response.text();
  }
}

export async function getConsolidatedFilesPerDataset(projectId: string): Promise<IConsolidatedFilesPerDataset[]> {
  const response = await fetch(`${baseUrl}projects/${projectId}/get-consolidated-files-per-dataset/`, {
    method: 'get',
    headers: {
      authorization: await getAccessToken(),
    },
  });
  if (response.ok) {
    const data = (await response.json()) as any;
    return Object.keys(data['dataset_with_consolidated_files']).map((key) => {
      return {
        id: key,
        name: data['dataset_with_consolidated_files'][key].name,
        consolidatedFiles: data['dataset_with_consolidated_files'][key].consolidated_files.map(
          (consolidatedFile: any) => ({ id: consolidatedFile.id, createdAt: consolidatedFile.created_at })
        ),
      };
    });
  } else {
    throw response.text();
  }
}

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers: Headers) => {
      const accessToken = await getAccessToken();
      headers.set('Authorization', accessToken);
      return headers;
    },
  }),
  tagTypes: [
    'ProjectsList',
    'Project',
    'ProjectDataSets',
    'ProjectResponses',
    'DataSetsList',
    'DataSetsWithoutProjectList',
    'DataSetDetail',
    'DataSetResults',
    'DataSetTasksStatus',
    'DataSetConsolidationTasksStatus',
    'Study',
    'Studies',
    'ConsolidatedFiles',
    'Map',
  ],
  endpoints: (builder) => ({
    // Projects
    getProjects: builder.query<Array<IProjectItem>, void>({
      query: () => ({
        url: 'projects/',
        method: 'GET',
      }),
      providesTags: ['ProjectsList'],
      transformResponse: (response: any) => {
        return response.map((projectItem: any) => {
          return {
            id: projectItem.id,
            legibleId: projectItem.legible_id,
            name: projectItem.name,
            description: projectItem.description,
            imageURL: projectItem.image_file,
            companyLegibleId: projectItem.company_legible_id,
            companyName: projectItem.company_name,
            companyColorHex: projectItem.company_color_hex,
            companyCountryCode: projectItem.company_country_code,
            depvarName: projectItem.depvar_name,
            depvarUnit: projectItem.depvar_unit,
            depvarCut: projectItem.depvar_cut,
            crsCode: projectItem.crs_code,
            createdAt: projectItem.created_at,
            updatedAt: projectItem.updated_at,
          };
        });
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    //Consolidate
    getConsolidatedFiles: builder.query<Array<IConsolidatedItem>, string>({
      query: (projectId: string) => ({
        url: `consolidates/project/${projectId}/`,
        method: 'GET',
      }),
      providesTags: ['ConsolidatedFiles'],
      transformResponse: (response: any) => {
        return response;
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getConsolidateDetail: builder.query<
      IConsolidatedItem,
      { consolidateId: string; numBins: number; blockIds: number[] }
    >({
      query: ({ consolidateId, numBins, blockIds }) => ({
        url: `consolidates/${consolidateId}/retrieve-data/`, // Adjusted URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          numBins: numBins,
          blockIds: blockIds,
        }),
      }),
      providesTags: ['ConsolidatedFiles'],
      transformResponse: (response: any) => {
        return response;
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getConsolidatedMapData: builder.query<IMapData, { consolidateId: string }>({
      query: ({ consolidateId }) => ({
        url: `consolidates/${consolidateId}/map-data/`,
        method: 'GET',
      }),
      providesTags: ['ConsolidatedFiles'],
      transformResponse: (response: any) => {
        return response;
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    setDashboardVisibility: builder.mutation<void, { consolidateId: number; dashboardEnabled: boolean }>({
      query: ({ consolidateId, dashboardEnabled }) => ({
        url: `consolidates/${consolidateId}/change-dashboard-visibility/`,
        method: 'POST',
        body: {
          dashboard_enabled: dashboardEnabled,
          consolidate_id: consolidateId,
        },
      }),
    }),
    setDashboardSettings: builder.mutation<void, { consolidateId: string; depVarName: string; interestGrade: number }>({
      query: ({ consolidateId, depVarName, interestGrade }) => ({
        url: `consolidates/${consolidateId}/change-dashboard-settings/`,
        method: 'POST',
        body: {
          depvar_name: depVarName,
          consolidate_id: consolidateId,
          interest_grade: interestGrade,
        },
      }),
      transformResponse: (response: any) => {
        return response;
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getPredictionModelDashboardUrl: builder.mutation<void, { projectId: string; modelId: string }>({
      query: ({ projectId, modelId }) => ({
        url: `prediction-models/${modelId}/get-dashboard-url/`,
        method: 'POST',
        prepareHeaders: (headers: any) => {
          headers.set('x-project-id', projectId);
          return headers;
        },
        headers: {
          'x-project-id': projectId,
        },
      }),
    }),

    setCrsCode: builder.mutation<void, { consolidatedId: number; crs_code: string }>({
      query: ({ consolidatedId, crs_code }) => ({
        url: `consolidates/${consolidatedId}/change-crs-code/`,
        method: 'POST',
        body: {
          crs_code: crs_code,
          consolidated_id: consolidatedId,
        },
      }),
    }),
    getStudySentDetail: builder.query<IStudySent, { studySentId: string }>({
      query: ({ studySentId }) => ({
        url: `study-sent/${studySentId}/`,
        method: 'GET',
      }),
      providesTags: ['Study'],
      transformResponse: (response: any) => {
        return response;
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getProjectDetail: builder.query<IProject, string>({
      query: (projectId: string) => ({
        url: `projects/${projectId}/`,
        method: 'GET',
      }),
      providesTags: (result: any, error, arg) => [{ type: 'Project', id: arg }],
      transformResponse: (response: any) => {
        return {
          id: response.id,
          legibleId: response.legible_id,
          elevationFilename: response.elevation_filename,
          elevationFileURL: response.elevation_file,
          areaOfInterestFilename: response.area_of_interest_filename,
          areaOfInterestFileURL: response.area_of_interest_file,
          name: response.name,
          description: response.description,
          imageURL: response.image_file,
          companyLegibleId: response.company_legible_id,
          companyName: response.company_name,
          companyColorHex: response.company_color_hex,
          companyCountryCode: response.company_country_code,
          depvarName: response.depvar_name,
          depvarUnit: response.depvar_unit,
          depvarCut: response.depvar_cut,
          crsCode: response.crs_code,
          createdAt: response.created_at,
          updatedAt: response.updated_at,
          dataSets: response.data_sets?.map((studyRequest: any) => {
            return {
              id: studyRequest.id,
              userName: studyRequest.user_name,
              createdAt: studyRequest.created_at,
              dataSetName: studyRequest.name,
              numberOfStudies: studyRequest.studies_count,
            };
          }),
          responses: response.responses?.map((projectResponse: any) => {
            return {
              id: projectResponse.id,
              filename: projectResponse.response_filename,
              fileURL: projectResponse.response_file,
              message: projectResponse.message,
              createdAt: projectResponse.created_at,
            };
          }),
          consolidatedFilesAssociations: response.consolidated_files?.map((association: any) => {
            return {
              projectId: association.project_id,
              finalFileURL: association.final_file,
              finalFileFilename: association.final_file_filename,
              id: association.id,
              notes: association.notes,
              consolidatedFile: {
                id: association.study_file.id,
                filename: association.study_file.filename,
                fileURL: association.study_file.generated_file,
                studyName: association.study_file.study_name,
                name: association.study_file.name,
                createdAt: association.study_file.created_at,
                datasetName: association.study_file.dataset_name,
              },
              studySentFileURL: association.study_sent_file,
              studySentFileFilename: association.study_sent_file_filename,
            };
          }),
          predictionModels: response.prediction_models?.map((model: any) => {
            return {
              id: model.id,
              name: model.name,
              modelType: model.model_type,
              modelFile: model.model_file,
              modelFilename: model.model_filename,
              crsCode: model.crs_code,
              pycaretVersion: model.pycaret_version,
              consolidatedFile: model.X_test_file,
              consolidatedFilename: model.X_test_filename,
              explainerFile: model.explainer_file,
              explainerFilename: model.explainer_filename,
              dashboardFile: model.dashboard_file,
              dashboardFilename: model.dashboard_filename,
              displayed: model.displayed,
              notes: model.notes,
              shapValuesFile: model.shap_values_file,
              shapValuesFilename: model.shap_values_filename,
              shapValuesTaskArn: model.shap_values_task_arn,
              shapValuesUpdatedAt: model.shap_values_updated_at,
              shapDrillingsValuesUpdatedAt: model.shap_drillings_values_updated_at,
              featureImportanceFile: model.feature_importance_file,
              featureImportanceFilename: model.feature_importance_filename,
              predictionFile: model.prediction_file,
              predictionFilename: model.prediction_filename,
              testFile: model.test_file,
              testFilename: model.test_filename,
              modelAlgorithm: model.model_algorithm,
              numRows: model.row_number,
              numCols: model.col_number,
            };
          }),
        };
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getProjectAllDataSets: builder.query<Array<IDataSetItem>, string>({
      query: (projectId: string) => ({
        url: `projects/${projectId}/all-data-sets/`,
        method: 'GET',
      }),
      providesTags: () => ['ProjectDataSets'],
      transformResponse: (response: any) => {
        return response.map((studyRequest: any) => {
          return {
            id: studyRequest.id,
            userName: studyRequest.user_name,
            createdAt: studyRequest.created_at,
            dataSetName: studyRequest.name,
            numberOfStudies: studyRequest.studies_count,
          };
        });
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getProjectMyDataSets: builder.query<Array<IDataSetItem>, string>({
      query: (projectId: string) => ({
        url: `projects/${projectId}/my-data-sets/`,
        method: 'GET',
      }),
      providesTags: () => ['ProjectDataSets'],
      transformResponse: (response: any) => {
        return response.map((studyRequest: any) => {
          return {
            id: studyRequest.id,
            userName: studyRequest.user_name,
            createdAt: studyRequest.created_at,
            dataSetName: studyRequest.name,
            numberOfStudies: studyRequest.studies_count,
          };
        });
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    deactivateDataSet: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `study-requests/${id}/deactivate/`,
        method: 'POST',
      }),
      invalidatesTags: () => ['ProjectDataSets'],
    }),
    duplicateDataSet: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `study-requests/${id}/duplicate/`,
        method: 'POST',
      }),
      invalidatesTags: () => ['ProjectDataSets'],
    }),
    createProjectResponse: builder.mutation<void, FormData>({
      query: (data: FormData) => ({
        url: 'project-responses/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => ['ProjectResponses', 'Project'],
    }),
    deleteProjectResponse: builder.mutation<void, { projectResponseId: string }>({
      query: ({ projectResponseId }) => ({
        url: `project-responses/${projectResponseId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['ProjectResponses', 'Project'],
    }),
    sendProjectResponseEmail: builder.mutation<string, { projectId: string; recipients: string[] }>({
      query: ({ projectId, recipients }: { projectId: string; recipients: string[] }) => ({
        url: `projects/${projectId}/send-response-email/`,
        method: 'POST',
        body: { recipients },
      }),
    }),
    addConsolidatedFile: builder.mutation<string, { projectId: string; dataSetId: string; consolidatedFileId: string }>(
      {
        query: ({
          projectId,
          consolidatedFileId,
        }: {
          projectId: string;
          dataSetId: string;
          consolidatedFileId: string;
        }) => ({
          url: `projects/${projectId}/add-consolidated-file/`,
          method: 'POST',
          body: {
            study_file_id: consolidatedFileId,
          },
        }),
        invalidatesTags: (result: any, _, arg) => [
          { type: 'Project', id: arg.projectId },
          { type: 'DataSetResults', id: arg.dataSetId },
        ],
      }
    ),
    removeConsolidatedFile: builder.mutation<string, { projectId: string; studyFileId: string }>({
      query: ({ projectId, studyFileId }: { projectId: string; studyFileId: string }) => ({
        url: `projects/${projectId}/remove-consolidated-file/`,
        method: 'POST',
        body: {
          study_file_id: studyFileId,
        },
      }),
      invalidatesTags: (result: any, _, arg) => [{ type: 'Project', id: arg.projectId }],
    }),
    updateNotesInConsolidatedFile: builder.mutation<
      string,
      {
        projectId: string;
        associationId: number;
        associationNotes: string;
      }
    >({
      query: ({
        projectId,
        associationId,
        associationNotes,
      }: {
        projectId: string;
        associationId: number;
        associationNotes: string;
      }) => ({
        url: `projects/${projectId}/update-notes-in-consolidated-file/`,
        method: 'POST',
        body: {
          association_id: associationId,
          association_notes: associationNotes,
        },
      }),
      invalidatesTags: (result: any, _, arg) => [{ type: 'Project', id: arg.projectId }],
    }),
    // DataSets
    getDataSets: builder.query<Array<IDataSetItem>, void>({
      query: () => ({
        url: 'study-requests/',
        method: 'GET',
      }),
      providesTags: ['DataSetsList'],
      transformResponse: (response: any) => {
        return response.map((studyRequest: any) => {
          return {
            id: studyRequest.id,
            userName: studyRequest.user_name,
            createdAt: studyRequest.created_at,
            dataSetName: studyRequest.name,
            numberOfStudies: studyRequest.studies_count,
          };
        });
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getMyDataSets: builder.query<Array<IDataSetItem>, void>({
      query: () => ({
        url: 'study-requests/my-data-sets/',
        method: 'GET',
      }),
      providesTags: ['DataSetsList'],
      transformResponse: (response: any) => {
        return response.map((studyRequest: any) => {
          return {
            id: studyRequest.id,
            userName: studyRequest.user_name,
            createdAt: studyRequest.created_at,
            dataSetName: studyRequest.name,
            numberOfStudies: studyRequest.studies_count,
          };
        });
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getDataSetsWithoutProject: builder.query<Array<IDataSetItem>, void>({
      query: () => ({
        url: 'study-requests/without-project/',
        method: 'GET',
      }),
      providesTags: ['DataSetsWithoutProjectList'],
      transformResponse: (response: any) => {
        return response.map((studyRequest: any) => {
          return {
            id: studyRequest.id,
            userName: studyRequest.user_name,
            createdAt: studyRequest.created_at,
            dataSetName: studyRequest.name,
            numberOfStudies: studyRequest.studies_count,
          };
        });
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getDataSetDetail: builder.query<IDataSet, string>({
      query: (dataSetId: string) => ({
        url: `study-requests/${dataSetId}/detail/`,
        method: 'GET',
      }),
      providesTags: (result: any, error, arg) => [{ type: 'DataSetDetail', id: arg }],
      transformResponse: (response: any) => {
        return {
          id: response['id'],
          name: response['name'],
          blocksFileURL: response['blocks_file'],
          blocksFilename: response['blocks_filename'],
          gridFileURL: response['grid_file'],
          gridFilename: response['grid_filename'],
          elevationGridFileURL: response['elevation_grid_file'],
          elevationGridFilename: response['elevation_grid_filename'],
          canSend: response['can_send'],
          searchMode: response['search_mode'],
          inputFileType: response['input_file_type'],
          validBlocksFile: response['valid_blocks_file'],
          validGridFile: response['valid_grid_file'],
          errors: response['errors'] ? response['errors'] : [],
        };
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getDataSetStatus: builder.query<boolean, string>({
      query: (dataSetId: string) => ({
        url: `study-requests/${dataSetId}/status/`,
        method: 'GET',
      }),
      transformResponse: (response: { can_send: boolean }) => response.can_send,
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    createDataSet: builder.mutation<string, FormData>({
      query: (data: FormData) => ({
        url: `study-requests/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['DataSetsList', 'ProjectDataSets'],
      transformResponse: (response: any) => {
        return response.id;
      },
      transformErrorResponse: (response: { status: number | string; data?: any }) => {
        return {
          blocksErrors: response.data?.blocks_errors || [],
          gridErrors: response.data?.grid_errors || [],
        };
      },
    }),
    updateStudyBlocksFile: builder.mutation<void, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `study-requests/${id}/update-study-blocks-file/`,
        method: 'POST',
        body,
      }),
    }),
    updateDataSet: builder.mutation<void, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `study-requests/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['ProjectDataSets', 'ConsolidatedFiles'],
    }),

    // Studies
    getStudies: builder.query<IStudy[], string>({
      query: (dataSetId: string) => ({
        url: `study-requests/${dataSetId}/studies/`,
        method: 'GET',
      }),
      providesTags: (result: any, error, arg) => {
        return result
          ? [
              ...result.map((study: any) => ({
                type: 'Study',
                id: study.id,
              })),
              { type: 'Studies', id: arg },
            ]
          : ['Study'];
      },
      transformResponse: (response: any) => response.studies.map((study: any) => parseResponseToStudy(study)),
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getStudy: builder.query<IStudy, { studyId: string; studyRequestId: string }>({
      query: ({ studyId }) => ({
        url: `studies/${studyId}/`,
        method: 'GET',
      }),
      transformResponse: (response: any) => parseResponseToStudy(response),
      transformErrorResponse: (response: { status: string | number }) => response.status,
      async onQueryStarted({ studyRequestId }, { dispatch, queryFulfilled }) {
        try {
          const { data: studyData } = await queryFulfilled;
          dispatch(
            projectApi.util.updateQueryData('getStudies', studyRequestId, (draft: any[]) => {
              for (let i = 0; i < draft.length; i++) {
                if (draft[i].id === studyData.id) {
                  draft[i] = studyData;
                }
              }
            })
          );
        } finally {
          /* continue */
        }
      },
    }),
    createStudy: builder.mutation<IStudy, FormData>({
      query: (data: FormData) => ({
        url: 'studies/',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: any) => {
        return parseResponseToStudy(response);
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: (result: any) => [{ type: 'Studies', id: result.requestId }],
    }),
    updateStudy: builder.mutation<IStudy, IStudyInput>({
      query: (studyInput: IStudyInput) => ({
        url: `studies/${studyInput.id}/`,
        method: 'PATCH',
        body: studyInput.data,
      }),
      transformResponse: (response: any) => {
        return parseResponseToStudy(response);
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: (result: any, _, arg) => [{ type: 'Study', id: arg.id }],
    }),
    validateStudy: builder.mutation<
      void,
      {
        studyId: string;
        variables: IStudyVariables;
        notImportedVariables: Array<string>;
      }
    >({
      query: ({ studyId, variables, notImportedVariables }) => ({
        url: `studies/${studyId}/validate-file/`,
        method: 'POST',
        body: {
          variables: variables,
          not_imported_variables: notImportedVariables,
        },
      }),
    }),
    deleteStudy: builder.mutation<void, { studyId: string; studyRequestId: string }>({
      query: ({ studyId }) => ({
        url: `studies/${studyId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result: any, _, arg) => [{ type: 'Studies', id: arg.studyRequestId }],
    }),
    //StudyFile
    getStudyFile: builder.query<IStudyFile, string>({
      query: (studyFileId: string) => ({
        url: `study-files/${studyFileId}/`,
        method: 'GET',
      }),
      providesTags: (result: any) => {
        return [{ type: 'Study', id: result.id }];
      },
      transformResponse: (response: any) => {
        return response;
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getMapFile: builder.query<IMapUrl, string>({
      query: (studyFileId: string) => ({
        url: `study-files/${studyFileId}/display_project_map/`,
        method: 'GET',
      }),
      providesTags: () => {
        return [{ type: 'Map' }];
      },
      transformResponse: (response: any) => {
        return response;
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    // Results
    getDataSetResults: builder.query<IConsolidated[], string>({
      query: (dataSetId: string) => ({
        url: `study-requests/${dataSetId}/results/`,
        method: 'GET',
      }),
      providesTags: (result: any, error, arg) => [{ type: 'DataSetResults', id: arg }],
      transformResponse: (response: any) => {
        return parseResponseToResults(response);
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    // Tasks status
    getDataSetTasksStatus: builder.query<ITaskStatus[], string>({
      query: (dataSetId: string) => ({
        url: `study-requests/${dataSetId}/tasks-status/`,
        method: 'GET',
      }),
      providesTags: (result: any, error, arg) => [{ type: 'DataSetTasksStatus', id: arg }],
      transformResponse: (response: any) => {
        return response.map((taskStatus: any) => {
          return {
            taskArn: taskStatus.task_arn,
            ecsLastStatus: taskStatus.ecs_last_status,
            state: taskStatus.state,
            studyName: taskStatus.study_name,
            studyId: taskStatus.study_id,
            studySentId: taskStatus.study_sent_id,
            createdAt: taskStatus.created_at,
            updatedAt: taskStatus.updated_at,
            ecsExitCode: taskStatus?.ecs_exit_code,
            ecsOverrides: taskStatus?.ecs_overrides,
            ecsStoppedReason: taskStatus?.ecs_stopped_reason,
            ecsFailureDetail: taskStatus?.ecs_failure_detail,
          };
        });
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    getDataSetConsolidationTasksStatus: builder.query<IConsolidationTaskStatus[], string>({
      query: (dataSetId: string) => ({
        url: `study-requests/${dataSetId}/consolidation-tasks-status/`,
        method: 'GET',
      }),
      providesTags: (result: any, error, arg) => [{ type: 'DataSetConsolidationTasksStatus', id: arg }],
      transformResponse: (response: any) => {
        return response.map((taskStatus: any) => {
          return {
            taskArn: taskStatus.task_arn,
            ecsLastStatus: taskStatus.ecs_last_status,
            state: taskStatus.state,
            studySentId: taskStatus.study_sent_id,
            createdAt: taskStatus.created_at,
            updatedAt: taskStatus.updated_at,
            ecsExitCode: taskStatus?.ecs_exit_code,
            ecsOverrides: taskStatus?.ecs_overrides,
            ecsStoppedReason: taskStatus?.ecs_stopped_reason,
            ecsFailureDetail: taskStatus?.ecs_failure_detail,
          };
        });
      },
      transformErrorResponse: (response: { status: string | number }) => response.status,
    }),
    createStudyResponse: builder.mutation<void, FormData>({
      query: (data: FormData) => ({
        url: 'study-responses/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result: any) => [{ type: 'DataSetResults', id: result?.request_id }],
    }),
    deleteStudyResponse: builder.mutation<void, { studyResponseId: string; studyRequestId: string }>({
      query: ({ studyResponseId }) => ({
        url: `study-responses/${studyResponseId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result: any, _, arg) => [{ type: 'DataSetResults', id: arg.studyRequestId }],
    }),
    getCompanyUsers: builder.query<string[], string>({
      query: (dataSetId: string) => ({
        url: `projects/${dataSetId}/get-company-users/`,
        method: 'POST',
      }),
      transformResponse: (response: any) => {
        return response['users'];
      },
    }),
    sendResponseEmail: builder.mutation<string, { studyRequestId: string; studySentId: string }>({
      query: ({ studyRequestId, studySentId }: { studyRequestId: string; studySentId: string }) => ({
        url: `study-requests/${studyRequestId}/send-response-email/`,
        method: 'POST',
        body: { study_sent: studySentId },
      }),
      invalidatesTags: (result: any, _, arg) => [{ type: 'DataSetDetail', id: arg.studyRequestId }],
    }),
    sendStudyRequest: builder.mutation<
      IValidationError[],
      {
        studyRequestId: string;
        searchMode: string;
        inputFileType: string;
        automaticGrid: boolean;
      }
    >({
      query: ({
        studyRequestId,
        searchMode,
        inputFileType,
        automaticGrid,
      }: {
        studyRequestId: string;
        searchMode: string;
        inputFileType: string;
        automaticGrid: boolean;
      }) => ({
        url: `study-requests/${studyRequestId}/send/`,
        method: 'POST',
        body: {
          search_mode: searchMode,
          input_file_type: inputFileType,
          automatic_grid: automaticGrid,
        },
      }),
      transformResponse: (response: any) => response.errors,
      invalidatesTags: (result: any, _, arg) => [{ type: 'DataSetDetail', id: arg.studyRequestId }],
    }),
    sendStudyRequestWithStudies: builder.mutation<
      IValidationError[],
      {
        studyRequestId: string;
        searchMode: string;
        inputFileType: string;
        automaticGrid: boolean;
        studiesIds: Array<string>;
      }
    >({
      query: ({
        studyRequestId,
        searchMode,
        inputFileType,
        automaticGrid,
        studiesIds,
      }: {
        studyRequestId: string;
        searchMode: string;
        inputFileType: string;
        automaticGrid: boolean;
        studiesIds: Array<string>;
      }) => ({
        url: `study-requests/${studyRequestId}/send-studies/`,
        method: 'POST',
        body: {
          search_mode: searchMode,
          input_file_type: inputFileType,
          automatic_grid: automaticGrid,
          studies_ids: studiesIds,
        },
      }),
      transformResponse: (response: any) => response.errors,
      invalidatesTags: (result: any, _, arg) => [{ type: 'DataSetDetail', id: arg.studyRequestId }],
    }),
    forceSendStudy: builder.mutation<any, { studyRequestId: string; studySentId: string; studyId: string }>({
      query: ({
        studyRequestId,
        studySentId,
        studyId,
      }: {
        studyRequestId: string;
        studySentId: string;
        studyId: string;
      }) => ({
        url: `study-requests/${studyRequestId}/force-send-study/`,
        method: 'POST',
        body: { study_sent_id: studySentId, study_id: studyId },
      }),
      transformResponse: (response: any) => response.study_sent,
      invalidatesTags: (result: any, _, arg) => [{ type: 'DataSetDetail', id: arg.studyRequestId }],
    }),
    forceSendStudies: builder.mutation<
      any,
      {
        studyRequestId: string;
        studySentId: string;
        studiesIds: Array<string>;
      }
    >({
      query: ({
        studyRequestId,
        studySentId,
        studiesIds,
      }: {
        studyRequestId: string;
        studySentId: string;
        studiesIds: Array<string>;
      }) => ({
        url: `study-requests/${studyRequestId}/force-send-studies/`,
        method: 'POST',
        body: { study_sent_id: studySentId, studies_ids: studiesIds },
      }),
      transformResponse: (response: any) => response.study_sent,
      invalidatesTags: (result: any, _, arg) => [{ type: 'DataSetDetail', id: arg.studyRequestId }],
    }),
    cancelConsolidation: builder.mutation<any, { studyRequestId: string }>({
      query: ({ studyRequestId }: { studyRequestId: string }) => ({
        url: `study-requests/${studyRequestId}/cancel-consolidation/`,
        method: 'POST',
      }),
      transformResponse: (response: any) => response.study_sent,
      invalidatesTags: (result: any, _, arg) => [{ type: 'DataSetDetail', id: arg.studyRequestId }],
    }),
    forceConsolidation: builder.mutation<any, { studyRequestId: string; studySentId: string }>({
      query: ({ studyRequestId, studySentId }: { studyRequestId: string; studySentId: string }) => ({
        url: `study-requests/${studyRequestId}/force-consolidation/`,
        method: 'POST',
        body: { study_sent_id: studySentId },
      }),
      transformResponse: (response: any) => response.study_sent,
      invalidatesTags: (result: any, _, arg) => [{ type: 'DataSetDetail', id: arg.studyRequestId }],
    }),
    forceConsolidationStudyFiles: builder.mutation<
      any,
      {
        studyRequestId: string;
        studySentId: string;
        studyFilesIds: Array<string>;
      }
    >({
      query: ({
        studyRequestId,
        studySentId,
        studyFilesIds,
      }: {
        studyRequestId: string;
        studySentId: string;
        studyFilesIds: Array<string>;
      }) => ({
        url: `study-requests/${studyRequestId}/force-consolidation-study-files/`,
        method: 'POST',
        body: {
          study_sent_id: studySentId,
          study_files_ids: studyFilesIds,
        },
      }),
      transformResponse: (response: any) => response.study_sent,
      invalidatesTags: (result: any, _, arg) => [{ type: 'DataSetDetail', id: arg.studyRequestId }],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectDetailQuery,
  useGetProjectAllDataSetsQuery,
  useLazyGetProjectMyDataSetsQuery,
  useDeactivateDataSetMutation,
  useDuplicateDataSetMutation,
  useCreateProjectResponseMutation,
  useDeleteProjectResponseMutation,
  useSendProjectResponseEmailMutation,
  useAddConsolidatedFileMutation,
  useRemoveConsolidatedFileMutation,
  useUpdateNotesInConsolidatedFileMutation,
  useGetDataSetsQuery,
  useLazyGetMyDataSetsQuery,
  useGetMyDataSetsQuery,
  useGetDataSetsWithoutProjectQuery,
  useGetDataSetDetailQuery,
  useGetStudiesQuery,
  useGetDataSetResultsQuery,
  useGetDataSetStatusQuery,
  useGetDataSetTasksStatusQuery,
  useGetDataSetConsolidationTasksStatusQuery,
  useGetCompanyUsersQuery,
  useCreateDataSetMutation,
  useUpdateStudyBlocksFileMutation,
  useUpdateDataSetMutation,
  useLazyGetStudyQuery,
  useCreateStudyMutation,
  useValidateStudyMutation,
  useUpdateStudyMutation,
  useDeleteStudyMutation,
  useCreateStudyResponseMutation,
  useDeleteStudyResponseMutation,
  useSendResponseEmailMutation,
  useSendStudyRequestMutation,
  useSendStudyRequestWithStudiesMutation,
  useForceSendStudyMutation,
  useForceSendStudiesMutation,
  useGetConsolidatedFilesQuery,
  useGetConsolidateDetailQuery,
  useGetConsolidatedMapDataQuery,
  useSetDashboardVisibilityMutation,
  useSetDashboardSettingsMutation,
  useGetPredictionModelDashboardUrlMutation,
  useSetCrsCodeMutation,
  useLazyGetConsolidateDetailQuery,
  useLazyGetStudySentDetailQuery,
  useGetStudySentDetailQuery,
  useCancelConsolidationMutation,
  useForceConsolidationMutation,
  useForceConsolidationStudyFilesMutation,
  useGetStudyFileQuery,
  useGetMapFileQuery,
} = projectApi;
