import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';

import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Amplify } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { store, persistor } from './redux/store';
import { SetS3Config } from './services';
import router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import awsSettings from './aws-exports';

import { SplashScreen } from './components';

// Google Analytics for production environment only
if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  ReactGA.initialize('G-S2YT2W36CV');
  hotjar.initialize({ id: 3526557, sv: 6 });
} else {
  console.log('Environment', process.env.REACT_APP_ENVIRONMENT);
}

Amplify.configure(awsSettings);
SetS3Config();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate
          loading={<SplashScreen />}
          persistor={persistor}
          onBeforeLift={() => new Promise((resolve) => setTimeout(resolve, 3000))}
        >
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
