import {
  IStudy,
  IConsolidatedFile,
  IConsolidated,
  IFeatureImportanceData,
  IFeatureImportanceVariable,
  IFeatureImportanceInfluenceByType,
  IStatsDataDefault,
  IStatsDataMulticlassifier,
} from '../services/types';

export function parseResponseToResults(response: any) {
  const results: IConsolidated[] = [];

  response.studies_sent.forEach((studySent: any) => {
    const filterStudyFiles = response.study_files.filter((studyFile: any) => studyFile.study_sent === studySent.id);
    const consolidatedFiles: Array<IConsolidatedFile> = filterStudyFiles.map((studyFile: any) => {
      const studyId = studyFile.study;
      const study = studySent.data_used.find((study: any) => study.id === studyId);
      return {
        id: studyFile.id,
        isAssociated: studyFile.is_associated,
        filename: studyFile.filename,
        fileURL: studyFile.generated_file,
        studyName: study?.name,
        studyId: studyId,
        name: studyFile.name,
        createdAt: studyFile.created_at,
      };
    });
    const filterStudyReponses = response.study_responses.filter(
      (studyResponse: any) => studyResponse.study_sent === studySent.id
    );
    const responseFiles = filterStudyReponses.map((studyResponse: any) => {
      return {
        id: studyResponse.id,
        filename: studyResponse.response_filename,
        fileURL: studyResponse.response_file,
        message: studyResponse.message,
        createdAt: studyResponse.created_at,
      };
    });
    results.push({
      id: studySent.id,
      createdBy: studySent.created_by,
      createdAt: studySent.created_at,
      studyRequestId: studySent.study_request,
      fileUsedURL: studySent.file_used,
      automaticGrid: studySent.automatic_grid,
      fileUsedFilename: studySent.file_used_filename,
      inputFileType: studySent.input_file_type,
      searchMode: studySent.search_mode,
      studiesUsed: studySent.data_used.map((study: any) => parseResponseToStudy(study)),
      consolidatedFiles,
      responseFiles,
    });
  });
  return results;
}

export function parseResponseToStudy(study: any): IStudy {
  const {
    holeIdComparison,
    m,
    semiMajorAxis,
    semiMinorAxis,
    semiMediumAxis,
    phi,
    height,
    theta,
    columnNoValuesCases,
    maxDistance,
    neighborsDetails,
    spacing,
    spacingX,
    spacingY,
    spacingZ,
  } = study.parameters;
  const volumeKind = study.parameters.volume_kind;
  const studyType = study.parameters.studyType ? study.parameters.studyType : 'OTROS';

  return {
    id: study.id,
    name: study.name,
    requestId: study.request,
    fileURL: study.input_file,
    filename: study.input_filename,
    dataset_name: study.dataset_name,
    consolidationAlgorithm: study.consolidation_algorithm,
    validFile: study.valid_file,
    isBlockStudy: study.is_block_study,
    processedFileURL: study.processed_file,
    parameters: {
      holeIdComparison,
      m: m?.toString(),
      semiMajorAxis: semiMajorAxis?.toString(),
      semiMediumAxis: semiMediumAxis?.toString(),
      semiMinorAxis: semiMinorAxis?.toString(),
      height: height ? height.toString() : '0',
      theta: theta ? theta.toString() : '0',
      phi: phi ? phi.toString() : '0',
      studyType,
      volumeKind,
      columnNoValuesCases,
      neighborsDetails,
      maxDistance: maxDistance ? maxDistance : '0',
      spacing: spacing ? spacing : '0',
      spacingX: spacingX ? spacingX : '0',
      spacingY: spacingY ? spacingY : '0',
      spacingZ: spacingZ ? spacingZ : '0',
    },
    variablesData: study.data ? study.data : {},
    includedAt: study.included_at,
    notImportedVariables: study.not_imported_variables ? study.not_imported_variables : [],
    informationLayerId: study.information_layer,
  };
}

export function parseResponseToFeatureImportanceData(response: any): IFeatureImportanceData {
  const variables: IFeatureImportanceVariable[] = response.variables.map((variable: any) => {
    return {
      feature: variable.feature,
      value: variable.value,
      type: variable.type,
      study: variable.study,
      model: variable.model,
    };
  });
  const influenceByType: IFeatureImportanceInfluenceByType[] = response.influence_by_type.map((influence: any) => {
    return {
      type: influence.type,
      value: influence.value,
    };
  });

  const variableTypes: { [key: string]: Array<string> } = {};
  Object.entries(response.variable_types).forEach(([key, value]) => {
    variableTypes[key] = value as string[];
  });

  return {
    variables: variables,
    influenceByType: influenceByType,
    variableTypes: variableTypes,
  };
}

export function parseResponseToStatsDataDefault(response: any): IStatsDataDefault {
  return {
    modelType: 'default',
    depvarName: response.depvar_name,
    depvarUnit: response.depvar_unit,
    depvarCut: response.depvar_cut,
    ll: response.ll,
    lu: response.lu,
    ul: response.ul,
    uu: response.uu,
    lllu: response.lllu,
    uluu: response.uluu,
    llul: response.llul,
    luuu: response.luuu,
    total: response.total,
    precisionL: response.precision_l,
    precisionU: response.precision_u,
    resolutionL: response.resolution_l,
    resolutionU: response.resolution_u,
    f1: response.f1,
    effectiveness: response.effectiveness,
    recall: response.recall,
    accuracy: response.accuracy,
    specificity: response.specificity,
  };
}

export function parseResponseToStatsDataMulticlassifier(response: any): IStatsDataMulticlassifier {
  return {
    modelType: 'multiclassifier',
    depvarName: response.depvar_name,
    depvarUnit: response.depvar_unit,
    depvarCut: response.depvar_cut,
    report: response.report,
  };
}
