import { apiGateway } from './apigateway';

export const {
  useLazyGetPredictionModelQuery,
  useCreatePredictionModelMutation,
  useUpdatePredictionModelMutation,
  useDeletePredictionModelMutation,
  // useGetPredictionModelDashboardUrlMutation,
  useGetPredictionModelDashboardStatusQuery,
  useGeneratePredictionModelShapValuesMutation,
  useGetPredictionModelShapValuesStatusQuery,
  useGetPredictionModelFeatureImportanceDataQuery,
  useGetPredictionModelPredictionDataQuery,
  useGetPredictionModelTestDataQuery,
  useGetPredictionLayersQuery,
} = apiGateway;

export {
  useGetProjectsQuery,
  useGetProjectDetailQuery,
  useGetProjectAllDataSetsQuery,
  useLazyGetProjectMyDataSetsQuery,
  useDeactivateDataSetMutation,
  useDuplicateDataSetMutation,
  useCreateProjectResponseMutation,
  useDeleteProjectResponseMutation,
  useSendProjectResponseEmailMutation,
  useAddConsolidatedFileMutation,
  useRemoveConsolidatedFileMutation,
  useUpdateNotesInConsolidatedFileMutation,
  useGetDataSetsQuery,
  useLazyGetMyDataSetsQuery,
  useGetMyDataSetsQuery,
  useGetDataSetsWithoutProjectQuery,
  useGetDataSetDetailQuery,
  useGetStudiesQuery,
  useGetDataSetResultsQuery,
  useGetDataSetStatusQuery,
  useGetDataSetTasksStatusQuery,
  useGetDataSetConsolidationTasksStatusQuery,
  useGetCompanyUsersQuery,
  useCreateDataSetMutation,
  useUpdateStudyBlocksFileMutation,
  useUpdateDataSetMutation,
  useLazyGetStudyQuery,
  useCreateStudyMutation,
  useUpdateStudyMutation,
  useValidateStudyMutation,
  useDeleteStudyMutation,
  useCreateStudyResponseMutation,
  useDeleteStudyResponseMutation,
  useSendResponseEmailMutation,
  useSendStudyRequestMutation,
  useSendStudyRequestWithStudiesMutation,
  useGetConsolidatedFilesQuery,
  useGetConsolidateDetailQuery,
  useSetDashboardVisibilityMutation,
  useGetPredictionModelDashboardUrlMutation,
  useSetCrsCodeMutation,
  useLazyGetConsolidateDetailQuery,
  useGetStudySentDetailQuery,
  useLazyGetStudySentDetailQuery,
  useForceSendStudyMutation,
  useForceSendStudiesMutation,
  useCancelConsolidationMutation,
  useForceConsolidationMutation,
  useForceConsolidationStudyFilesMutation,
  useGetStudyFileQuery,
  useGetMapFileQuery,
} from './project';

export {
  useStartGeochemicalProcessMutation,
  useProcessRenameVariablesFileMutation,
  useApplyModificationsMutation,
} from './dataManagement';

export { useUploadFileToS3Mutation, usePreprocessingFileInS3Mutation, usePreprocessingStatusQuery } from './fileUpload';

export { SetS3Config } from './s3';

export { useSendMessageMutation as useSendSlackMessageMutation } from './slack';

export { useGetCoordRefSystemQuery } from './epsg';
