import DOMPurify from 'dompurify';

export const arrayMinMax = (arr: Array<any>) =>
  arr.reduce(
    ([min, max], val) => [Math.min(min, val), Math.max(max, val)],
    [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
  );

export function createMarkup(html: string) {
  return {
    __html: DOMPurify.sanitize(html),
  };
}

export function scrollToElement(elementId: string, ms: number = 100) {
  setTimeout(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, ms);
}
