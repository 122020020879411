import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

export const getAccessToken = async (): Promise<string> => {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    const session = user.getSignInUserSession();
    if (session) {
      const accessToken = session.getAccessToken().getJwtToken();
      return 'Bearer ' + accessToken;
    }
    return '';
  } catch {
    return '';
  }
};

export const getIdToken = async (): Promise<string> => {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    const session = user.getSignInUserSession();
    if (session) {
      const idToken = session.getIdToken().getJwtToken();
      return idToken;
    }
    return '';
  } catch {
    return '';
  }
};
