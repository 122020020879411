import { Container, Card, Button, Offcanvas } from 'react-bootstrap';

import LollipopChartGrid from '../PlotTypes/LollipopChartGrid';

import { STUDY_TYPE_DICT } from '../../../utils';
import { IConsolidatedItem, IStudyData } from '../../../services/types';
import CustomTable from '../PlotTypes/CustomTable';
import { useMemo, useState } from 'react';
import WindRose from '../PlotTypes/WindRose';

interface PlotsGeneratorProps {
  studyData: IStudyData;
  depVarName: string;
  plotsPerRow: number;
  widthHeightRatio: number;
  width?: number;
  binSizes: number[];
  interestGrade: number;
  intervalBinsInfo: any;
  consolidatedObject: IConsolidatedItem | null;
  selectedData: string;
  xAxisDistribution: string;
  windRoseData?: any;
}

interface DataEntry {
  DEPVAR: number | null;
  [key: string]: number | null;
}

export default function PlotsGenerator({
  studyData,
  depVarName,
  plotsPerRow,
  width,
  binSizes,
  interestGrade,
  intervalBinsInfo,
  consolidatedObject,
  selectedData,
  xAxisDistribution,
  windRoseData,
}: PlotsGeneratorProps) {
  const colNames = !Array.isArray(studyData.data) || studyData.data.length === 0 ? [] : Object.keys(studyData.data[0]);
  const studyType = studyData.studyType;
  const studyName = studyData.name;
  const [showOffsetCanvas, setShowOffsetCanvas] = useState(false);
  const variableInfo = studyData.variableInfo;
  const handleCloseOffsetCanvas = () => setShowOffsetCanvas(false);
  const toggleShowOffsetCanvas = () => setShowOffsetCanvas((s) => !s);

  const getNeighborsData = (consolidated: IConsolidatedItem) => {
    const intervalsData: Record<string, number[]> = {};
    const quantileData: Record<string, number[]> = {};
    const naturalBreaksData: Record<string, number[]> = {};

    const intervalKeys: (keyof IConsolidatedItem)[] = [
      'maxs_interval_data',
      'mins_interval_data',
      'mean_interval_data',
    ];
    const quantileKeys: (keyof IConsolidatedItem)[] = [
      'mins_quantile_data',
      'maxs_quantile_data',
      'mean_quantile_data',
    ];
    const naturalBreaksKeys: (keyof IConsolidatedItem)[] = ['mins_jnb_data', 'maxs_jnb_data', 'mean_jnb_data'];
    const neighborsColumnName = '#NEIGHBORS'.concat('_', studyType, '_', studyName);
    for (const key of intervalKeys) {
      const dataObject = consolidated[key];
      const extractedData = dataObject.map((el: any) => el[neighborsColumnName]);
      intervalsData[key] = extractedData;
    }
    for (const key of quantileKeys) {
      const dataObject = consolidated[key];
      const extractedData = dataObject.map((el: any) => el[neighborsColumnName]);
      quantileData[key] = extractedData;
    }
    for (const key of naturalBreaksKeys) {
      const dataObject = consolidated[key];
      const extractedData = dataObject.map((el: any) => el[neighborsColumnName]);
      naturalBreaksData[key] = extractedData;
    }
    return { intervalsData, quantileData, naturalBreaksData };
  };
  const {
    intervalsData: intervalNeighborsData,
    quantileData: quantileNeighborsData,
    naturalBreaksData: naturalBreaksNeighborsData,
  } = useMemo(() => {
    if (consolidatedObject) {
      return getNeighborsData(consolidatedObject);
    }
    return { intervalsData: {}, quantileData: {}, naturalBreaksData: {} };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidatedObject]);

  const plotColNames: string[] = colNames.filter((colName) => !colName.startsWith('DEPVAR'));
  const replaceNullsInData = (data: any[], intervalBinsInfo: any[]) => {
    const newData = data.map((entry, i) => {
      if (entry.DEPVAR === null) {
        const newDEPVAR = (intervalBinsInfo[i].right + intervalBinsInfo[i].left) / 2;
        const newEntry: DataEntry = { DEPVAR: newDEPVAR };
        for (const key in entry) {
          if (key !== 'DEPVAR') {
            newEntry[key] = 0;
          }
        }
        return newEntry;
      } else {
        return entry;
      }
    });
    return newData;
  };

  const data = replaceNullsInData(studyData.data, intervalBinsInfo);
  const extractStudyColumn = (yName: string) => {
    const studyTypeRegex = Object.keys(STUDY_TYPE_DICT).join('|');
    const regex = new RegExp(`^(.*)(_(${studyTypeRegex})_)`);
    const match = yName.match(regex);
    if (match && match[1]) {
      return match[1].replace(/_/g, ' ');
    }
    return yName.replace(/_/g, ' ');
  };

  const generateTextLines = () => {
    if (!nonZeroColumns) {
      return [];
    }
    const filteredColNames = zeroColumns.map((colName) => extractStudyColumn(colName));
    const columnsText = joinWithAnd(filteredColNames);

    const introTextLines =
      `Los gráficos a continuación muestran cómo cambian las distintas variables de ${STUDY_TYPE_DICT[studyType]} pertenecientes al estudio "${studyName.toLocaleUpperCase()}" en la vecindad de las muestras de sondajes cuando el ${depVarName} en las muestras de sondajes cambia. En gris se destaca leyes sobre el corte de interés.`.split(
        '\n'
      );
    const columnsTextLine =
      columnsText.length === 0
        ? 'Ninguna columna ha sido omitida.'
        : `Las columnas ${filteredColNames.map((col) => `"${col}"`).join(', ')} no se muestran, pues no están presentes en ninguna vecindad de las muestras de sondaje, dada la vecindad definida para este estudio.`;

    return [...introTextLines, columnsTextLine];
  };

  const joinWithAnd = (array: string[]): string => {
    if (array.length === 0) {
      return '';
    }
    if (array.length === 1) {
      return array[0];
    }
    return array.slice(0, -1).join(', ') + ' y ' + array.slice(-1);
  };

  const selectData = (colName: string) => {
    return data.map((el: any) => el[colName]);
  };

  const lollipopChartImageUrl = process.env.PUBLIC_URL + `/offcanvas-images/Lollipop.png`;

  const filterColumnNames = () => {
    const nonZeroColumns: string[] = [];
    const zeroColumns: string[] = [];
    plotColNames.forEach((col) => {
      const newData = selectData(col);
      const newDataArray = Object.values(newData) as number[];
      const maxValue = Math.max(...newDataArray);
      const minValue = Math.min(...newDataArray);
      const difference = Math.abs(minValue - maxValue);
      if (difference < 0.0000001) {
        zeroColumns.push(col);
      } else {
        nonZeroColumns.push(col);
      }
    });
    return [zeroColumns, nonZeroColumns];
  };

  const [zeroColumns, nonZeroColumns] = filterColumnNames();

  return (
    <div id="studies-plots-div">
      <Card className="mb-2" id="">
        <Card.Header>{STUDY_TYPE_DICT[studyType].toUpperCase()}</Card.Header>
        <Card.Body>
          <Card.Title className="slide-title">Análisis de variables </Card.Title>
          {generateTextLines().map((line, index) => (
            <Card.Text className="slide-text" key={index}>
              {line}
            </Card.Text>
          ))}
        </Card.Body>
      </Card>
      <Offcanvas show={showOffsetCanvas} onHide={handleCloseOffsetCanvas}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Gráficos de Estudios</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>
            En esta sección podemos visualizar múltiples gráficos de tipo <strong>Lollipop</strong>. Estos gráficos,
            parecidos a los histogramas, tienen como objetivo ver cómo se relaciona la variable dependiente {depVarName}{' '}
            con las otras variables a estudiar. Dependiendo del tipo de grupos para muestras de sondaje elegidos, estos
            gráficos se actualizarán de manera acorde (ya sea por intervalos regulares o por cuantiles).{' '}
          </p>
          <p>
            Para hacer zoom al gráfico, se puede seleccionar la zona que se quiere visualizar con más detalle
            manteniendo presionado el click izquierdo y arrastrando sobre el área de interés.{' '}
          </p>
          <p>
            Por último, se puede ver un gráfico de barras en el fondo de la imagen. Estas barras están fijadas en el
            fondo con el objetivo de representar el tamaño de los bins que cada lollipop representa, por lo que no están
            alineados con los valores del eje X.
          </p>
          <img src={lollipopChartImageUrl} width={'95%'} alt={'Gráfico de ejemplo de Lollipop'} />
        </Offcanvas.Body>
      </Offcanvas>
      <Container className="d-flex justify-content-center align-items-center mb-3">
        <Button variant="info" onClick={toggleShowOffsetCanvas}>
          Guía de gráficos <i className="fa fa-question-circle" aria-hidden="true"></i>
        </Button>
      </Container>
      <Container className="chart-grid">
        <Container className="px-3 my-3 d-flex flex-column align-items-center justify-content-center">
          <LollipopChartGrid
            data={data}
            depVarName={depVarName}
            colNames={nonZeroColumns}
            studyType={studyType}
            plotsPerRow={plotsPerRow}
            width={width}
            widthHeightRatio={0.9}
            binSizes={binSizes}
            interestGrade={interestGrade}
            colInfo={variableInfo}
            xAxisDistribution={xAxisDistribution}
          />
        </Container>
      </Container>
      {windRoseData && studyType === 'EST' && (
        <Container>
          <WindRose windRoseData={windRoseData} />
        </Container>
      )}
      {quantileNeighborsData && intervalNeighborsData && naturalBreaksNeighborsData && (
        <CustomTable
          selectedData={selectedData}
          quantileNeighborsData={quantileNeighborsData}
          intervalNeighborsData={intervalNeighborsData}
          naturalBreaksNeighborsData={naturalBreaksNeighborsData}
        />
      )}
    </div>
  );
}
