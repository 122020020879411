import React from 'react';

import { Pagination } from 'react-bootstrap';

interface DataSetsPaginationProps {
  nPages: number;
  currentPage: number;
  setCurrentPage: any;
}

export default function DataSetsPagination({ nPages, currentPage, setCurrentPage }: DataSetsPaginationProps) {
  const pageNumbers = Array.from(Array(nPages + 1).keys()).slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <Pagination className="justify-content-center">
      <Pagination.Prev onClick={prevPage} />
      {pageNumbers.map((pgNumber) => (
        <Pagination.Item key={pgNumber} active={currentPage === pgNumber} onClick={() => setCurrentPage(pgNumber)}>
          {pgNumber}
        </Pagination.Item>
      ))}
      <Pagination.Next onClick={nextPage} />
    </Pagination>
  );
}
