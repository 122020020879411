import React, { useEffect, useState } from 'react';

import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { useAppSelector } from '../../redux/hooks';

import { Container, Row, Col, Form, Button, Alert, Accordion } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { Plus } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';

import { useNavigate, useLocation } from 'react-router-dom';

import { useGetProjectAllDataSetsQuery, useLazyGetProjectMyDataSetsQuery } from '../../services';
import { AuthenticationLayout } from '../../layouts';
import { IDataSetItem } from '../../services/types';
import { sortByDate, sortByString } from '../../utils';
import { DataSetsPagination, DataSetsRecords } from '../../components';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

registerLocale('es', es);

interface IOption {
  value: string;
  label: string;
}

const sortCases: Array<IOption> = [
  { value: 'createdAtDESC', label: 'Fecha de creación (DESC)' },
  { value: 'createdAtASC', label: 'Fecha de creación (ASC)' },
  { value: 'pNameDESC', label: 'Nombre del data set (DESC)' },
  { value: 'pNameASC', label: 'Nombre del data set (ASC)' },
  { value: 'uNameDESC', label: 'Nombre de usuario (DESC)' },
  { value: 'uNameASC', label: 'Nombre de usuario (ASC)' },
];

interface DataSetsProps {
  projectId: string;
}

export default function DataSets({ projectId }: DataSetsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAppSelector((state) => state.auth);

  const { data: allDataSets, isFetching, refetch: refetchDataSets } = useGetProjectAllDataSetsQuery(projectId);
  const [getMyDataSets, { data: myDataSets, isFetching: isFetchingMyDataSets }] = useLazyGetProjectMyDataSetsQuery();

  const [dataSets, setDataSets] = useState<Array<IDataSetItem>>([]);
  const [data, setData] = useState<Array<IDataSetItem>>([]);
  const [sortPreference, setSortPreference] = useState<string>('');
  const [filterText, setFilterText] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMyDataSets, setShowMyDataSets] = useState(false);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = dataSets.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataSets.length / recordsPerPage);

  const goToCreateDataSet = () => navigate(`/projects/${projectId}/new-data-set`);

  const chooseSort = (orderData: IDataSetItem[] | undefined, pref: string) => {
    if (orderData === undefined) {
      return;
    }
    if (pref === 'pNameASC') {
      sortByString(orderData, 'dataSetName', false);
    } else if (pref === 'pNameDESC') {
      sortByString(orderData, 'dataSetName', true);
    } else if (pref === 'uNameASC') {
      sortByString(orderData, 'userName', false);
    } else if (pref === 'uNameDESC') {
      sortByString(orderData, 'userName', true);
    } else if (pref === 'createdAtASC') {
      sortByDate(orderData, false);
    } else if (pref === 'createdAtDESC') {
      sortByDate(orderData, true);
    }
  };

  const changeSortBy = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const filteredNewDataSets = filterDataByName(filterText, data);
    const orderBy = e.target.value;
    chooseSort(filteredNewDataSets, orderBy);
    localStorage.setItem('sortingPreference', orderBy);
    setSortPreference(orderBy);
  };

  const filterDataByName = (text: string, data: IDataSetItem[] | undefined) => {
    if (data) {
      const filteredDataSets = data.filter((item) => {
        return (
          removeAccents(item.dataSetName).toLowerCase().indexOf(removeAccents(text).toLowerCase()) > -1 ||
          removeAccents(item.userName).toLowerCase().indexOf(removeAccents(text).toLowerCase()) > -1
        );
      });
      return filteredDataSets;
    }
  };

  const saveLastPage = (num: number) => {
    localStorage.setItem('LastDataSetPage', num.toString());

    if (dataSets.length <= recordsPerPage) {
      setCurrentPage(1);
    } else {
      setCurrentPage(num);
    }
  };

  const handleNameFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  const handleMyDataSetsSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowMyDataSets(e.target.checked);
    e.target.checked ? getMyDataSets(projectId) : refetchDataSets();
    localStorage.setItem('showMyDataSets', e.target.checked.toString());
  };

  const filterDataByDate = (data: IDataSetItem[] | undefined) => {
    if (!data) {
      return;
    }
    if (endDate) {
      endDate.setHours(23, 59, 59, 999);
    }

    const startDateFilter = data.filter((item) => {
      if (startDate) {
        const newDate = new Date(item.createdAt);
        return newDate >= startDate;
      }
      return true;
    });
    const endDateFilter = startDateFilter.filter((item) => {
      if (endDate) {
        const newDate = new Date(item.createdAt);
        return newDate <= endDate;
      }
      return true;
    });
    return endDateFilter;
  };

  const removeAccents = (text: string) => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  useEffect(() => {
    if (allDataSets && allDataSets.length > 0 && !showMyDataSets) {
      setData(allDataSets);
    }
  }, [allDataSets, showMyDataSets]);

  useEffect(() => {
    if (myDataSets && myDataSets.length > 0 && showMyDataSets) {
      setData(myDataSets);
    }
  }, [myDataSets, showMyDataSets]);

  useEffect(() => {
    if ((currentPage - 1) * recordsPerPage > dataSets.length) {
      setCurrentPage(1);
    }
  }, [dataSets, currentPage, recordsPerPage]);

  useEffect(() => {
    const storedPreference = localStorage.getItem('sortingPreference');
    if (data.length > 0) {
      const orderData = data.slice();
      const filteredByName = filterDataByName(filterText, orderData);
      const dateFiltered = filterDataByDate(filteredByName);
      if (storedPreference) {
        setSortPreference(storedPreference);
        chooseSort(dateFiltered, storedPreference);
      } else {
        sortByDate(dateFiltered, true);
      }
      setDataSets(dateFiltered ? dateFiltered : []);
      if (location.state !== null && location.state.comesFromDataSetPages) {
        const prevPage = Number(localStorage.getItem('LastDataSetPage'));
        setCurrentPage(prevPage);
      } else {
        setCurrentPage(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filterText, startDate, endDate, sortPreference, showMyDataSets]);

  //Use effect for handling currentPage border case when deleting dataSet and having no dataSet in current page
  useEffect(() => {
    if (dataSets) {
      if (dataSets.length % recordsPerPage === 0 && (currentPage - 1) * recordsPerPage + 1 > dataSets.length) {
        setCurrentPage(currentPage - 1);
      }
      if (dataSets.length <= recordsPerPage) {
        setCurrentPage(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currentPage, recordsPerPage, filterText]);

  return (
    <AuthenticationLayout mustAuth={true} mustAdmin={false}>
      <Container>
        <Row className="mt-4 align-items-center">
          <Col sm="4">
            <h2>Data Sets</h2>
          </Col>
          <Col sm={auth.isAdmin ? 4 : 6}>
            <Form.Select className="w-auto" value={sortPreference} onChange={changeSortBy}>
              {sortCases.map((option: IOption, i: number) => {
                return (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
          {auth.isAdmin && (
            <Col>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Mis data sets"
                checked={showMyDataSets}
                onChange={handleMyDataSetsSwitch}
              />
            </Col>
          )}
          <Col sm="2" className="float-end text-end">
            <Button className="d-flex flex-row align-items-center" variant="light" onClick={goToCreateDataSet}>
              <Plus size={32} />
              Nuevo data set
            </Button>
          </Col>
        </Row>
        {isFetching || isFetchingMyDataSets ? (
          <Row className="loading">
            <Col>
              <Spinner />
            </Col>
          </Row>
        ) : data ? (
          <>
            <Row className="align-items-center mt-2">
              <Col>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Filtros</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col>
                          <Form.Label className="parameter-label">Filtrar por usuario o nombre de data set</Form.Label>
                          <Form.Control
                            type="text"
                            aria-describedby="filterField"
                            onChange={handleNameFilterChange}
                            value={filterText}
                            className="parameter-input"
                          />
                        </Col>
                        <Col sm={2}>
                          <Form.Label className="parameter-label">Fecha de inicio</Form.Label>
                          <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd/MM/yyyy"
                            locale="es"
                            className="form-control parameter-input"
                          />
                        </Col>
                        <Col sm={2}>
                          <Form.Label className="parameter-label">Fecha final</Form.Label>
                          <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat="dd/MM/yyyy"
                            className="form-control parameter-input"
                          />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
            <DataSetsRecords projectId={projectId} dataSets={currentRecords} />
            <DataSetsPagination nPages={nPages} currentPage={currentPage} setCurrentPage={saveLastPage} />
          </>
        ) : (
          <Alert variant="danger">Lo sentimos: hubo un error.</Alert>
        )}
      </Container>
    </AuthenticationLayout>
  );
}
