import Plot from 'react-plotly.js';
import { Container } from 'react-bootstrap';
import { Layout } from 'plotly.js';

interface PlotlyProps {
  xData: any;
  yData: any;
  yName: string;
  depVarName: string;
  title?: string;
  widthHeightRatio: number;
  width: number;
  interestGrade?: number;
  isQuantile: boolean;
  maxDecimals?: number;
}

export default function PlotlyHistogram({
  xData,
  yData,
  yName,
  depVarName,
  title,
  widthHeightRatio,
  width,
  isQuantile,
  maxDecimals,
}: PlotlyProps) {
  const height = width * widthHeightRatio;
  const barWidth = isQuantile ? 0.95 : Math.abs(xData[1] - xData[0]);

  const trimValues = (data: number[], maxDecimals: number) => {
    return data.map((value: number) => {
      const stringValue = value.toString();
      if (stringValue.includes('.') && stringValue.split('.')[1].length > maxDecimals) {
        return parseFloat(value.toFixed(maxDecimals));
      }
      return value;
    });
  };

  function groupAndSumData(xData: any, yData: any) {
    const groupedData: any = {};
    const newXData = maxDecimals !== undefined ? trimValues(xData, maxDecimals) : xData;

    for (let i = 0; i < xData.length; i++) {
      const xValue = newXData[i];
      const yValue = yData[i];

      if (groupedData[xValue]) {
        groupedData[xValue] += yValue;
      } else {
        groupedData[xValue] = yValue;
      }
    }

    // Convert the object into an array of [key, value] pairs
    const groupedDataArray = Object.entries(groupedData).map(([key, value]) => ({
      key: parseFloat(key),
      value,
    }));

    // Sort the array by the numeric keys
    groupedDataArray.sort((a, b) => a.key - b.key);

    // Separate the keys and values back into individual arrays
    const sortedGroupedXData = groupedDataArray.map((item) => item.key);
    const sortedGroupedYData = groupedDataArray.map((item) => item.value);

    return { xData: sortedGroupedXData, yData: sortedGroupedYData };
  }

  const { xData: groupedXData, yData: groupedYData } = groupAndSumData(xData, yData);

  // let interestZoneShape: Partial<Shape> | undefined = undefined;
  // if (interestGrade !== undefined) {
  //   interestZoneShape = {
  //     type: 'rect',
  //     x0: interestGrade as Datum,
  //     x1: Math.max(...xData) * 1000,
  //     y0: -Math.max(...yData) * 1000,
  //     y1: Math.max(...yData) * 1000,
  //     fillcolor: 'rgba(0, 0, 0, 0.2)',
  //     line: {
  //       width: 0,
  //     },
  //     layer: 'below',
  //   };
  // }

  const layout: Partial<Layout> = {
    width: width,
    height: height,
    title: title ? title : 'Histograma Muestras de Sondajes',
    xaxis: {
      title: depVarName,
      range: isQuantile ? [-1, groupedXData.length] : [0, Math.max(...groupedXData)],
      type: isQuantile ? 'category' : undefined,
    },
    yaxis: {
      title: yName,
      range: [0, Math.max(...(groupedYData as number[]))],
      type: isQuantile ? undefined : 'log',
      autorange: true,
    },
    bargap: 0.1,
    bargroupgap: 0.1,
    barmode: 'overlay',
    autosize: true,
    // shapes: interestZoneShape && !isQuantile ? [interestZoneShape] : [],
  };
  return (
    <div>
      <Container className="chart">
        <Plot
          data={[
            {
              x: groupedXData,
              y: groupedYData as number[],
              mode: 'markers',
              type: 'bar',
              name: yName,
              marker: {
                color: 'orange', // Set the color of the bars
              },
              width: barWidth,
              hovertemplate: `<b>${yName}</b>: %{y}<br><b>${depVarName} promedio</b>: %{x}<extra></extra>`,
            },
          ]}
          layout={layout}
        />
      </Container>
    </div>
  );
}
