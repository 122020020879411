import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';

import {
  useGetConsolidateDetailQuery,
  useLazyGetStudySentDetailQuery,
  useSendSlackMessageMutation,
} from '../../services';
import { CaretUpFill, Trash } from 'react-bootstrap-icons';

import { AuthenticationLayout } from '../../layouts';

import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Alert,
  Card,
} from 'react-bootstrap';

import PopoverInfo from './PopoverInfo';
import PlotsGenerator from './CarouselPages/PlotsGenerator';
import GeneralInfoPlots from './CarouselPages/GeneralInfoPlots';

import { scrollToElement, STUDY_TYPE_DICT } from '../../utils';

import 'font-awesome/css/font-awesome.min.css';

import {
  addParagraph,
  addSubtitle,
  getBasicDocumentConfig,
  addChartImage,
  generateDocument,
  addSection,
  removeSection,
} from '../../utils';
import { IConsolidatedItem, IStudyData } from '../../services/types';
import { useSetDashboardSettingsMutation } from '../../services/project';

interface CardData {
  image: string;
  caption: string;
  description: string;
}

interface SectionInfo {
  [key: number]: string;
}

interface DashboardProps {
  projectId: string;
  consolidateId: string;
  blockIds: number[];
  numBins: number;
}

export default function Dashboard({ consolidateId, blockIds, numBins }: DashboardProps) {
  const auth: any = useAppSelector((state) => state.auth);
  const location: any = useLocation();
  const {
    data: consolidated,
    isFetching: isFetchingConsolidateDetail,
    isError: isErrorConsolidateDetail,
  } = useGetConsolidateDetailQuery({ consolidateId, numBins, blockIds });
  const [getStudySentDetailTrigger, { data: studySent }] = useLazyGetStudySentDetailQuery({});
  const [setDashboardSettings] = useSetDashboardSettingsMutation();
  const [saveSettingsError, setSaveSettingsError] = useState('');
  const [cards, setCards] = useState<CardData[] | null>(null);
  const [quantileBinnedData, setQuantileBinnedData] = useState<any[]>([]);
  const [intervalBinnedData, setIntervalBinnedData] = useState<any[]>([]);
  const [naturalBreaksData, setNaturalBreaksData] = useState<any[]>([]);
  const [windRoseData, setWindRoseData] = useState<any>(undefined);
  const [activeData, setActiveData] = useState<any[]>([]);
  const [studyData, setStudyData] = useState<IStudyData[] | null>(null);
  const [depVarName, setDepVarName] = useState('');
  const [interestGrade, setInterestGrade] = useState(0);
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [selectedRadio, setSelectedRadio] = useState('naturalBreaks');
  const [plotsPerRow, setPlotsPerRow] = useState<any>(2);
  const [xAxisDistribution, setXAxisDistribution] = useState<string>('log');
  const [newDocument, setNewDocument] = useState(() => getBasicDocumentConfig());
  const [paragraphCounter, setParagraphCounter] = useState(1);
  const [activeStudyName, setActiveStudyName] = useState('');
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const width = 400;
  const widthHeightRatio = 1 / 1.5;
  const [documentInfo, setDocumentInfo] = useState<SectionInfo>();
  const [binSizes, setBinSizes] = useState<any[]>([]);
  const [addingToDocument, setAddingToDocument] = useState(false);
  const [intervalBinsInfo, setIntervalBinsInfo] = useState(null);
  const [dataInfo, setDataInfo] = useState(null);
  const [consolidatedObject, setConsolidatedObject] = useState<IConsolidatedItem | null>(null);
  const [sendSlackMessageTrigger] = useSendSlackMessageMutation();
  const plotsRef = useRef<HTMLDivElement | null>(null);

  const handleSaveSettings = async () => {
    setIsSavingSettings(true);
    setSaveSettingsError('');
    try {
      await setDashboardSettings({
        consolidateId,
        depVarName: depVarName,
        interestGrade: interestGrade,
      }).unwrap();
    } catch (error) {
      console.error('Error setting dashboard settings:', error);
      // Assume error.message contains the error description
      setSaveSettingsError(
        'Error al intentar guardar configuración. Asegúrate de que ambos campos tengan valores válidos.'
      );
    } finally {
      setIsSavingSettings(false);
    }
  };
  const renderPlotsGenerator = () => {
    if (isFetchingConsolidateDetail) {
      return (
        <div className="centered-horizontally my-2">
          <Spinner />
        </div>
      );
    }

    if (!isErrorConsolidateDetail && (!studyData || activeCardIndex < 0 || activeCardIndex >= studyData.length)) {
      return (
        <div className="centered-horizontally my-2">
          <Spinner />
        </div>
      );
    }

    if (isErrorConsolidateDetail || !studyData) {
      return <Alert variant="danger">Hubo un error al consultar la data. Vuelva a intentar con nuevos valores.</Alert>;
    }

    const activeStudyData = studyData[activeCardIndex];
    if (activeCardIndex === 0) {
      return (
        <>
          <GeneralInfoPlots
            quantileBinnedData={quantileBinnedData}
            intervalBinnedData={intervalBinnedData}
            naturalBreaksData={naturalBreaksData}
            depVarName={depVarName}
            widthHeightRatio={widthHeightRatio}
            interestGrade={interestGrade}
            intervalBinsInfo={intervalBinsInfo}
            dataInfo={dataInfo}
            studyFile={consolidated?.study_file}
          />
          <Button variant="dark" className="float-end" onClick={() => scrollToElement('general-information-plots-div')}>
            <CaretUpFill />
          </Button>
        </>
      );
    }
    if (!intervalBinsInfo) {
      return <></>;
    }
    return (
      <>
        <PlotsGenerator
          studyData={activeStudyData}
          depVarName={depVarName}
          plotsPerRow={plotsPerRow ? plotsPerRow : 1}
          widthHeightRatio={widthHeightRatio}
          width={width}
          binSizes={binSizes}
          interestGrade={interestGrade}
          intervalBinsInfo={intervalBinsInfo}
          consolidatedObject={consolidatedObject}
          selectedData={selectedRadio}
          xAxisDistribution={xAxisDistribution}
          {...(windRoseData && { windRoseData })}
        />
        <Button variant="dark" className="float-end" onClick={() => scrollToElement('studies-plots-div')}>
          <CaretUpFill />
        </Button>
      </>
    );
  };

  const addToDocumentWrapper = async () => {
    setAddingToDocument(true);
    try {
      await addToDocument();
    } catch (error) {
      console.error('Error adding charts to document:', error);
    } finally {
      setAddingToDocument(false);
      updateDocumentInfo(activeStudyName.concat(' - Imagenes Separadas'));
    }
  };

  const addGridToDocumentWrapper = async () => {
    setAddingToDocument(true);
    try {
      await addGridToDocument();
    } catch (error) {
      console.error('Error adding charts to document:', error);
    } finally {
      setAddingToDocument(false);
      updateDocumentInfo(activeStudyName.concat(' - Imagenes en Grilla'));
    }
  };

  const updateDocumentInfo = (info: string) => {
    const lastIndex = newDocument.sections.length;
    const updatedInfo = {
      ...documentInfo,
      [lastIndex]: info,
    };
    setDocumentInfo(updatedInfo);
  };

  const addToDocument = async () => {
    const updatedDocument = addSection(newDocument);
    const title = document.getElementsByClassName('slide-title');
    const texts = document.getElementsByClassName('slide-text');
    const charts = document.getElementsByClassName('chart');
    const neighborsTable = document.getElementsByClassName('neighbors-table');
    const imageHeight = (charts[0] as HTMLElement).offsetHeight;
    const imageWidth = (charts[0] as HTMLElement).offsetWidth;
    const imageWidthHeightRatio = imageWidth / imageHeight;
    const maxWidth = 400;
    addSubtitle(updatedDocument, paragraphCounter, (title[0] as HTMLElement).innerText);
    for (let i = 0; i < texts.length; i++) {
      const text = texts[i] as HTMLElement;
      addParagraph(updatedDocument, text.innerText);
    }
    for (let j = 0; j < charts.length; j++) {
      const element = charts[j] as HTMLElement;
      await addChartImage(updatedDocument, element, maxWidth, maxWidth / imageWidthHeightRatio);
    }

    const table = neighborsTable[0] as HTMLElement;
    if (table) {
      addParagraph(updatedDocument, 'La información de los vecinos de los bins para este estudio es la siguiente:');
      const tableWidthHeightRatio = table.offsetWidth / table.offsetHeight;
      await addChartImage(updatedDocument, table, 600, 600 / tableWidthHeightRatio);
    }

    setParagraphCounter(paragraphCounter + 1);
    setNewDocument(updatedDocument);
  };

  const addGridToDocument = async () => {
    const updatedDocument = addSection(newDocument);
    const title = document.getElementsByClassName('slide-title');
    const texts = document.getElementsByClassName('slide-text');
    const chartRow = document.getElementsByClassName('grouped-chart-row');
    const neighborsTable = document.getElementsByClassName('neighbors-table');

    addSubtitle(updatedDocument, paragraphCounter, (title[0] as HTMLElement).innerText);
    for (let i = 0; i < texts.length; i++) {
      const text = texts[i] as HTMLElement;
      addParagraph(updatedDocument, text.innerText);
    }

    for (let j = 0; j < chartRow.length; j++) {
      const chartsInRow = chartRow[j].getElementsByClassName('chart-container').length;

      const element = chartRow[j] as HTMLElement;
      const gridHeight = (chartRow[j] as HTMLElement).offsetHeight;
      const gridWidth = (chartRow[j] as HTMLElement).offsetWidth;
      const gridWidthHeightRatio = gridWidth / gridHeight;
      const rowWidth = (600 * chartsInRow) / plotsPerRow;
      const rowHeight = rowWidth / gridWidthHeightRatio;
      await addChartImage(updatedDocument, element, rowWidth, rowHeight);
    }

    const table = neighborsTable[0] as HTMLElement;
    if (table) {
      addParagraph(updatedDocument, 'La información de los vecinos de los bins para este estudio es la siguiente');
      const tableWidthHeightRatio = table.offsetWidth / table.offsetHeight;
      await addChartImage(updatedDocument, table, 600, 600 / tableWidthHeightRatio);
    }

    setParagraphCounter(paragraphCounter + 1);
    setNewDocument(updatedDocument);
  };

  const createDocument = () => {
    generateDocument(newDocument);
  };

  const createCards = () => {
    const newCards: CardData[] = [];
    const totalImages = 7;

    const introSlide: CardData = {
      image: process.env.PUBLIC_URL + `/dashboard-carousel/img-intro.jpg`,
      description: 'Distribución de los datos',
      caption: 'Información General',
    };
    newCards.push(introSlide);

    if (studySent) {
      studySent.data_used.forEach((study, index) => {
        const imageIndex = 1 + (index % totalImages); // Start from index 1

        const newSlide: CardData = {
          image: process.env.PUBLIC_URL + `/dashboard-carousel/img${imageIndex}.jpg`,
          description: 'Estudio de tipo '.concat(STUDY_TYPE_DICT[study.parameters.studyType]),
          caption: study.name.toUpperCase(),
        };
        newCards.push(newSlide);
      });
      setCards(newCards);
    }
  };

  const divideData = () => {
    if (studySent && activeData) {
      const newStudies: IStudyData[] = [];
      const generalStudy: IStudyData = {
        name: 'Información General',
        studyType: '',
        colPrefixes: [],
        data: [],
        colTypes: [],
        variableInfo: undefined,
      };
      newStudies.push(generalStudy);
      studySent.data_used.forEach((study) => {
        const newStudy: IStudyData = {
          name: study.name,
          studyType: study.parameters.studyType,
          colPrefixes: Object.keys(study.data),
          data: getDataCallback(Object.keys(study.data), study.parameters.studyType.concat('_', study.name)),
          colTypes: Object.values(study.data),
          variableInfo: study.data,
        };
        newStudies.push(newStudy);
      });
      setStudyData(newStudies);
    }
  };

  const getDataCallback = useCallback(
    (colPrefixes: string[], endString: string) => {
      if (!activeData) {
        return null;
      }
      const newData = (activeData as any[]).map((obj) => {
        const selectedColumns: { [key: string]: any } = {};

        Object.keys(obj).forEach((colName) => {
          if (
            colName === 'DEPVAR' ||
            (colPrefixes.some((prefix) => colName.startsWith(prefix)) && colName.endsWith(endString))
          ) {
            selectedColumns[colName] = obj[colName];
          }
        });
        return selectedColumns;
      });

      return newData;
    },
    [activeData]
  );

  const handleDepVarNameChange = (event: any) => {
    setDepVarName(event.target.value);
  };

  const handleInterestGrade = (event: any) => {
    setInterestGrade(event.target.value);
  };

  const handlePlotsPerRow = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    if (isNaN(value)) {
      setPlotsPerRow('');
    } else {
      setPlotsPerRow(value);
    }
  };

  const handleRadioChange = (event: any) => {
    const value = event.target.value;
    setSelectedRadio(event.target.value);
    setActiveData(
      value === 'quantile' ? quantileBinnedData : value === 'regular' ? intervalBinnedData : naturalBreaksData
    );
  };
  const handleXAxisDistribution = (event: any) => {
    setXAxisDistribution(event.target.value);
  };
  const handleDeleteSection = (key: string) => {
    const keyValue = parseInt(key, 10);

    if (!isNaN(keyValue)) {
      const updatedDocumentInfo = { ...documentInfo };

      delete updatedDocumentInfo[keyValue];

      Object.keys(updatedDocumentInfo).forEach((index) => {
        const currentIndex = parseInt(index, 10);
        if (currentIndex > keyValue) {
          updatedDocumentInfo[currentIndex - 1] = updatedDocumentInfo[currentIndex];
          delete updatedDocumentInfo[currentIndex];
        }
      });

      setNewDocument(removeSection(newDocument, keyValue));
      setDocumentInfo(updatedDocumentInfo);
    }
  };

  useEffect(() => {
    if (consolidated) {
      setIntervalBinnedData(consolidated.mean_interval_data);
      if (consolidated.depvar_name) {
        setDepVarName(consolidated.depvar_name);
      }
      if (consolidated.interest_grade) {
        setInterestGrade(consolidated.interest_grade);
      }
      if (consolidated.mean_interval_data) {
        setIntervalBinsInfo(consolidated.mean_interval_data.map((el: any) => el['bin']));
      }
      setQuantileBinnedData(consolidated.mean_quantile_data);
      setNaturalBreaksData(consolidated.mean_jnb_data);
      setDataInfo(consolidated.data_info);
      if (consolidated.wind_rose_df && Object.keys(consolidated.wind_rose_df).length !== 0) {
        setWindRoseData(consolidated.wind_rose_df);
      }
      setConsolidatedObject(consolidated);
      if (consolidated.study_file) {
        getStudySentDetailTrigger({
          studySentId: consolidated.study_file.study_sent,
        });
      }
    }
  }, [consolidated, getStudySentDetailTrigger]);

  useEffect(() => {
    if (selectedRadio === 'quantile') {
      setActiveData(consolidated?.mean_quantile_data);
    } else if (selectedRadio === 'regular') {
      setActiveData(consolidated?.mean_interval_data);
    } else if (selectedRadio === 'naturalBreaks') {
      setActiveData(consolidated?.mean_jnb_data);
    }
    divideDataCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRadio, consolidated]);

  useEffect(() => {
    if (activeData) {
      const newBinSizes = activeData.map((bin: any) => {
        return bin['num_rows'];
      });
      setBinSizes(newBinSizes);
    }
  }, [activeData]);
  const divideDataCallback = useCallback(divideData, [studySent, activeData, getDataCallback]);

  useEffect(() => {
    createCards();
    divideDataCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studySent]);

  useEffect(() => {
    if (activeCardIndex === 0) {
      setActiveStudyName('INFORMACION GENERAL');
    } else {
      if (studySent) {
        setActiveStudyName(studySent.data_used[activeCardIndex - 1].name.toUpperCase());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDocument, activeCardIndex, documentInfo]);

  if (!cards) {
    <div className="centered-content">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      sendSlackMessageTrigger(
        `Usuario ingresó a Dashboard: ${auth.email} | ${location.pathname} | ${consolidated?.study_file?.dataset_name}`
      )
        .unwrap()
        .then(() => console.log('Message sent to slack successfully!'))
        .catch((err) => console.error('Error in message to slack', err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AuthenticationLayout mustAuth={true} mustAdmin={false}>
      <>
        {/* <ReactJoyride
                    continuous
                    run={runTutorial}
                    steps={steps}
                    callback={handleJoyrideCallback}
                    showProgress
                    showSkipButton
                /> */}

        <Container className="px-3 my-3">
          {isFetchingConsolidateDetail ? (
            <div className="centered-horizontally my-2">
              <Spinner />
            </div>
          ) : (
            <>
              <Row>
                <Col sm={2}>
                  <ListGroup>
                    {cards?.map((card, index) => (
                      <ListGroup.Item action key={index} onClick={() => setActiveCardIndex(index)}>
                        <span>{card.caption}</span>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  <h6 className="my-2">Configuración de gráficos</h6>
                  <Card className="small my-2">
                    <Card.Body>
                      <Form.Group controlId="depVarName" className="mb-3">
                        <Form.Label>
                          Nombre de la variable objetivo
                          <PopoverInfo
                            header={'Nombre de la variable objetivo'}
                            body={`Este campo identifica el nombre de la variable objetivo que se está estudiando, por ejemplo 'Cu ppm', 'Fe %'. Al darle un nombre a la variable objetivo, los gráficos se actualizan y muestran los títulos de los ejes correspondientes según la variable a estudiar. `}
                          />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={depVarName}
                          disabled={isFetchingConsolidateDetail || isSavingSettings}
                          onChange={handleDepVarNameChange}
                          size="sm"
                        />
                      </Form.Group>
                      <Form.Group controlId="interestGrade">
                        <Form.Label>
                          Ley de interés
                          <PopoverInfo
                            header={'Ley de interés'}
                            body={`La ley de interés se representa en los gráficos de los estudios como la zona delimitada por fondo gris. Esta zona representa los bins con una ley de ${depVarName} superior a la especificada en este campo.`}
                          />
                        </Form.Label>
                        <Form.Control
                          type="number"
                          value={interestGrade}
                          disabled={isFetchingConsolidateDetail || isSavingSettings}
                          onChange={handleInterestGrade}
                          min="0"
                          size="sm"
                        />
                      </Form.Group>
                      <Row className="my-3">
                        <Col>
                          {saveSettingsError && <Alert variant="danger">{saveSettingsError}</Alert>}
                          <Button
                            variant="primary"
                            onClick={handleSaveSettings}
                            className="mx-auto"
                            style={{
                              display: 'block',
                              width: 'auto',
                            }}
                            disabled={isSavingSettings}
                            size="sm"
                          >
                            {isSavingSettings ? (
                              <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                <span className="visually-hidden">Saving...</span>
                              </>
                            ) : (
                              <span>Guardar configuración</span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="small my-2">
                    <Card.Body>
                      <Form.Label>
                        Tipo de grupos para muestras de sondaje
                        <PopoverInfo
                          header={'Grupos para muestras de sondaje'}
                          body={`Esta selección define cómo se agruparán los datos. "Intervalos regulares" significa que los 
                                        datos se agruparán en intervalos fijos y tamaños de grupos variables, mientras que "Cuantiles" significa 
                                        que los datos se distribuirán en segmentos o grupos de igual 
                                        tamaño, lo que puede ayudar a analizar cómo se distribuyen los valores en el conjunto de datos.
                                        Por último, breaks naturales (algoritmo de Jenks) se usa para identificar los cortes más significativos 
                                        dentro de los datos, agrupando los valores en bins de manera que minimiza la varianza interna y maximiza la varianza 
                                        entre grupos. Esto permite una interpretación más intuitiva y detallada de las agrupaciones naturales y patrones significativos en los datos`}
                        />
                      </Form.Label>
                      <Form.Group controlId="drilling-samples-group-naturalBreaks">
                        <Form.Check
                          type="radio"
                          label="Breaks Naturales"
                          name="radioGroup"
                          value="naturalBreaks"
                          checked={selectedRadio === 'naturalBreaks'}
                          disabled={isFetchingConsolidateDetail}
                          onChange={handleRadioChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="drilling-samples-group-regular">
                        <Form.Check
                          type="radio"
                          label="Intervalos regulares"
                          name="radioGroup"
                          value="regular"
                          checked={selectedRadio === 'regular'}
                          disabled={isFetchingConsolidateDetail}
                          onChange={handleRadioChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="drilling-samples-group-quantile">
                        <Form.Check
                          type="radio"
                          label="Cuantiles"
                          name="radioGroup"
                          value="quantile"
                          checked={selectedRadio === 'quantile'}
                          disabled={isFetchingConsolidateDetail}
                          onChange={handleRadioChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="plotsPerRow" className="mt-2">
                        <Form.Label>
                          Número de gráficos por fila
                          <PopoverInfo
                            header={'Gráficos por fila'}
                            body={`Esta selección define cuántos gráficos se verán por fila en el dashboard. Este parámetro también define cómo se verá al añadir la página al informe en modo grilla, que mostrará los gráficos en filas según el número especificado aquí.`}
                          />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={plotsPerRow}
                          disabled={isFetchingConsolidateDetail}
                          onChange={handlePlotsPerRow}
                          size="sm"
                        />
                      </Form.Group>
                      <Form.Group controlId="x-axis-distribution-log" className="mt-2">
                        <Form.Label>
                          Tipo de distribución de datos en el eje X
                          <PopoverInfo
                            header={'Distribución en el eje X'}
                            body={`Esta selección define cómo se mostrarán los datos a lo largo del eje X. Cuando los datos tienen un 
                                                gran rango entre bins es recomendable utilizar la distribución logarítmica para poder apreciar de mejor manera 
                                                las diferencias en los valores`}
                          />
                        </Form.Label>
                        <Form.Check
                          type="radio"
                          label="Logarítmica"
                          value="log"
                          checked={xAxisDistribution === 'log'}
                          disabled={isFetchingConsolidateDetail}
                          onChange={handleXAxisDistribution}
                        />
                      </Form.Group>
                      <Form.Group controlId="x-axis-distribution-linear" className="mb-3">
                        <Form.Check
                          type="radio"
                          label="Lineal"
                          value="linear"
                          checked={xAxisDistribution === 'linear'}
                          disabled={isFetchingConsolidateDetail}
                          onChange={handleXAxisDistribution}
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={10}>
                  <div ref={plotsRef}>{renderPlotsGenerator()}</div>
                </Col>
              </Row>
              {cards && (
                <Row>
                  <Col className="col-container">
                    <Container className="border p-4 my-4">
                      <h2 className="text-center">
                        Generador de Informe
                        <PopoverInfo
                          header={'Generador de informe'}
                          body={`Esta sección se utiliza para generar informes en base a lo que se está mostrando por pantalla actualmente. El botón de "Agregar a documento" inserta en un documento (.docx) los gráficos de la sección actual del documento, de manera sucesiva en dirección vertical. La opción "Agregar a documento (Grilla)" agrega los gráficos en una misma línea según el número de gráficos por linea especificado anteriormente. Finalmente, "Exportar Documento" permite descargar el documento generado. Al añadir elementos al informe, se muestran en una lista que puede ser editada posteriormente.`}
                        />
                      </h2>
                      {addingToDocument ? (
                        <div className="centered-horizontally">
                          <Spinner />
                        </div>
                      ) : (
                        <Container>
                          <div className="centered-buttons">
                            <Button onClick={addToDocumentWrapper} className="button-container">
                              Agregar a documento
                            </Button>
                            <Button onClick={addGridToDocumentWrapper} className="button-container">
                              Agregar a documento (Grilla)
                            </Button>
                            <Button onClick={createDocument} className="button-container">
                              Exportar documento (.docx)
                            </Button>
                          </div>
                          <Container>
                            <ListGroup className="custom-list-group">
                              {documentInfo &&
                                Object.entries(documentInfo).map(([key, value]) => (
                                  <ListGroup.Item key={key} className="list-item d-flex justify-content-between">
                                    <span>
                                      Seccion {key}: {value}
                                    </span>
                                    <div>
                                      <OverlayTrigger
                                        key={`overlay-tooltip-delete-${key}`}
                                        placement="top"
                                        overlay={
                                          <Tooltip id={`tooltip-delete-${key}`} className="custom-tooltip">
                                            Eliminar
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="outline-danger"
                                          onClick={() => handleDeleteSection(key)}
                                          className="trash-button"
                                        >
                                          <Trash />
                                        </Button>
                                      </OverlayTrigger>
                                    </div>
                                  </ListGroup.Item>
                                ))}
                            </ListGroup>
                          </Container>
                        </Container>
                      )}
                    </Container>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Container>
      </>
    </AuthenticationLayout>
  );
}
