import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../redux/hooks';

interface AuthenticationLayoutProps {
  mustAuth?: boolean;
  mustAdmin?: boolean;
  noAuth?: boolean;
  children: JSX.Element;
}

export default function AuthenticationLayout({ mustAuth, mustAdmin, noAuth, children }: AuthenticationLayoutProps) {
  const auth = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (mustAuth && !auth.isAuth) {
      navigate('/');
    } else if (mustAdmin && !auth.isAdmin) {
      if (auth.isAuth) {
        navigate('/projects');
      } else {
        navigate('/');
      }
    } else if (noAuth && auth.isAuth) {
      navigate('/projects');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return <>{children}</>;
}
