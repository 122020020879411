import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = 'https://slack.com/api/';
const token = process.env.REACT_APP_SLACK_BOT_TOKEN;
const usersNotificationsChannelId = process.env.REACT_APP_SLACK_USERS_NOTIFICATIONS_CHANNEL_ID;

export const slackApi = createApi({
  reducerPath: 'slackApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers: Headers) => {
      headers.set('Content-Type', 'application/x-www-form-urlencoded');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    sendMessage: builder.mutation<any, string>({
      query: (message: string) => ({
        url: 'chat.postMessage',
        method: 'POST',
        body: `token=${token}&channel=${usersNotificationsChannelId}&text=${message}`,
      }),
    }),
  }),
});

export const { useSendMessageMutation } = slackApi;
