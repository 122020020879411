import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IEPSGCoordRefSystem } from './types';

import { getAccessToken } from './auth';
import { config } from '../config';

const baseUrl = `${config.BACKEND_URL}/api/epsg/`;

export const epsgApi = createApi({
  reducerPath: 'epsgApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers: Headers) => {
      const accessToken = await getAccessToken();
      headers.set('Authorization', accessToken);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCoordRefSystem: builder.query<Array<IEPSGCoordRefSystem>, { keywords: string[]; pageSize: number }>({
      query: ({ keywords, pageSize }) => ({
        url: `coord-ref-systems/?keywords=${keywords.join('+')}&pageSize=${pageSize}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.map((crs: any) => {
          return { ...crs, dataSource: crs.data_source };
        });
      },
    }),
  }),
});

export const { useGetCoordRefSystemQuery } = epsgApi;
