import { useState, useEffect } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { Person, BoxArrowRight } from 'react-bootstrap-icons';

import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { clearCache } from '../../redux';
import { changeAuthState } from '../../features';

import logo from '../../assets/logo.png';

import './style.css';

export default function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState<string>('');
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth.isAuth) {
      Auth.currentAuthenticatedUser().then((data: any) => {
        setEmail(data.attributes.email);
      });
    }
  }, [auth.isAuth]);

  const navigateTo = (to: string) => () => {
    if (to !== location.pathname) {
      navigate(to);
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
    } finally {
      dispatch(
        changeAuthState({
          isAuth: false,
          isAdmin: false,
          email: 'no-email',
        })
      );
      clearCache(dispatch);
    }
  };

  return (
    <Navbar expand="lg" bg="dark" variant="dark" className="px-3">
      <Navbar.Brand href={auth.isAuth ? '/projects' : '/'}>
        <img src={logo} alt="Mineral Forecast" />
      </Navbar.Brand>
      {auth.isAuth && (
        <>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="px-3 col justify-content-between">
            <Nav>
              <Nav.Item className={`${location.pathname === '/projects' ? 'bg-secondary' : ''} rounded`}>
                <Nav.Link onClick={navigateTo('/projects')}>Proyectos</Nav.Link>
              </Nav.Item>
              {auth.isAdmin && (
                <NavDropdown
                  className={`${location.pathname === '/data-management/geochemical' || location.pathname === '/data-management/rename-variables' ? 'bg-secondary' : ''} rounded`}
                  title="Gestión de Datos"
                >
                  <Dropdown.Item
                    className={`${location.pathname === '/data-management/categorical-variables-homologation' ? 'bg-secondary' : ''}`}
                    onClick={navigateTo('/data-management/categorical-variables-homologation')}
                  >
                    Homologación de variables categóricas
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={`${location.pathname === '/data-management/geochemical' ? 'bg-secondary' : ''} `}
                    onClick={navigateTo('/data-management/geochemical')}
                  >
                    Geoquímica
                  </Dropdown.Item>
                </NavDropdown>
              )}
            </Nav>
            <Nav>
              <Nav.Item>
                <Navbar.Text className="nav-link">
                  <Person size={20} /> {email}
                </Navbar.Text>
              </Nav.Item>
              <Nav.Item onClick={logout}>
                <Nav.Link>
                  <BoxArrowRight size={20} /> Logout
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </>
      )}
    </Navbar>
  );
}
