import { useEffect, useRef, useState } from 'react';
import { Container, Spinner, Row, Col, Button } from 'react-bootstrap';
import '../styles.css';
import Plot from 'react-plotly.js';
import { useGetConsolidatedMapDataQuery } from '../../../services/project';
import { arrayMinMax } from '../../../utils';

interface DrillingsMapProps {
  consolidatedId: string;
  onDataUpdate: (data: number[]) => void;
}

export default function DrillingsMap({ consolidatedId, onDataUpdate }: DrillingsMapProps) {
  const [mapData, setMapData] = useState<any>(null);
  const [center, setCenter] = useState<{ lat: number; lon: number }>({
    lat: 0,
    lon: 0,
  });

  const [blockIds, setBlockIds] = useState<any>(null);
  const { data: consolidatedData, isFetching } = useGetConsolidatedMapDataQuery({ consolidateId: consolidatedId });
  const tempSelectedBlockIDs = useRef<number[]>([]);

  useEffect(() => {
    if (consolidatedData) {
      const dataSize = consolidatedData.lats.length;
      const lats = consolidatedData['lats'].slice(0, dataSize);
      const longs = consolidatedData['longs'].slice(0, dataSize);
      const depvars = consolidatedData['depvars'].slice(0, dataSize);
      setBlockIds(consolidatedData['blockids'].slice(0, dataSize));

      const avgLat = lats.reduce((a, b) => a + b, 0) / lats.length;
      const avgLon = longs.reduce((a, b) => a + b, 0) / longs.length;

      setCenter({ lat: avgLat, lon: avgLon });
      const minMaxDepVars = arrayMinMax(depvars);
      const minDepVars = minMaxDepVars[0];
      const maxDepVars = minMaxDepVars[1];

      const mapData = [
        {
          type: 'scattermapbox',
          text: depvars,
          lon: longs,
          lat: lats,
          marker: {
            color: depvars,
            colorscale: 'Hot',
            autosize: true,
            cmin: minDepVars,
            cmax: maxDepVars,
            colorbar: {
              title: 'Valor de DEPVAR',
              titleside: 'right',
              tickmode: 'array',
              tickvals: [minDepVars, maxDepVars],
              ticktext: [minDepVars, maxDepVars],
              ticks: 'outside',
            },
          },
        },
      ];
      setMapData(mapData);
    }
  }, [consolidatedData]);

  const handleSelection = (event: Plotly.PlotSelectionEvent) => {
    if (!event.points) return; // Guard clause if no points are selected
    // Map selected points to their indices
    const selectedPointIndices: number[] = event.points.map((point) => point.pointIndex);
    // Retrieve BLOCKID for each selected index
    const selectedBlockIDs: number[] = selectedPointIndices.map((index) => blockIds[index]);
    tempSelectedBlockIDs.current = selectedBlockIDs;
  };

  const confirmSelection = () => {
    onDataUpdate(tempSelectedBlockIDs.current);
  };

  return isFetching ? (
    <div className="d-flex justify-content-center align-items-center" style={{ width: '800px', height: '800px' }}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : !mapData ? (
    <div className="d-flex justify-content-center align-items-center" style={{ width: '800px', height: '800px' }}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <Container>
      <Plot
        data={mapData}
        layout={{
          width: 800,
          height: 800,
          mapbox: {
            style: 'open-street-map',
            zoom: 11,
            center: center,
          },
        }}
        onSelected={handleSelection}
      />
      <Row className="mt-4 justify-content-center">
        <Col xs={12} md={4} className="text-center">
          <Button onClick={confirmSelection} className="w-100">
            Confirmar Selección
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
