import {
  AlignmentType,
  Document,
  Header,
  HeadingLevel,
  HorizontalPositionRelativeFrom,
  ImageRun,
  Packer,
  Paragraph,
  TextRun,
  VerticalPositionRelativeFrom,
} from 'docx';
import html2canvas from 'html2canvas';
import { Buffer } from 'buffer';
import logoImageBase64 from '../assets/logoblack.png';

export const getBasicDocumentConfig = () => {
  const logoImage = new ImageRun({
    data: logoImageBase64,
    transformation: {
      width: 170 * 0.7, //Preserve aspect ratio
      height: 119 * 0.7,
    },
    floating: {
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
        offset: 2000,
      },
      verticalPosition: {
        relative: VerticalPositionRelativeFrom.TOP_MARGIN,
        offset: 2000,
      },
    },
  });
  return {
    sections: [
      {
        headers: {
          default: new Header({
            children: [new Paragraph({ children: [logoImage] })],
          }),
        },
        properties: {
          page: {
            margin: {
              top: 1000,
              right: 1000,
              bottom: 1000,
              left: 1000,
            },
          },
        },
        children: [],
      },
    ],
  };
};

export const addSection = (documentConfig: any) => {
  const logoImage = new ImageRun({
    data: logoImageBase64,
    transformation: {
      width: 170 * 0.7, //Preserve aspect ratio
      height: 119 * 0.7,
    },
    floating: {
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
        offset: 2000,
      },
      verticalPosition: {
        relative: VerticalPositionRelativeFrom.TOP_MARGIN,
        offset: 2000,
      },
    },
  });

  const newSection = {
    headers: {
      default: new Header({
        children: [new Paragraph({ children: [logoImage] })],
      }),
    },
    properties: {
      page: {
        margin: {
          top: 1000,
          right: 1000,
          bottom: 1000,
          left: 1000,
        },
      },
    },
    children: [],
  };

  return {
    ...documentConfig,
    sections: [...documentConfig.sections, newSection],
  };
};

export const removeSection = (documentConfig: any, index: number) => {
  // Check if the index is valid
  if (index >= 0 && index < documentConfig.sections.length) {
    // Delete the section at the specified index
    documentConfig.sections.splice(index, 1);

    // Update the indexes of remaining sections
    documentConfig.sections.forEach((section: any, i: number) => {
      section.index = i;
    });

    return documentConfig;
  }
};

export const addMainTitle = (basicConf: any, title: string) => {
  const lastSectionIndex = basicConf.sections.length - 1;
  basicConf.sections[lastSectionIndex].children.push(
    new Paragraph({
      children: [
        new TextRun({
          text: title,
          bold: true,
          color: '000000',
          font: 'Calibri',
        }),
      ],
      heading: HeadingLevel.TITLE,
      alignment: AlignmentType.CENTER,
    })
  );
};

export const addSubtitle = (basicConf: any, numberSubTitle: number, paragraphText: string) => {
  const lastSectionIndex = basicConf.sections.length - 1;
  basicConf.sections[lastSectionIndex].children.push(
    new Paragraph({
      children: [
        new TextRun({
          text: `${numberSubTitle}. ${paragraphText}`,
          bold: true,
          color: '000000',
          font: 'Calibri',
        }),
      ],
      alignment: AlignmentType.JUSTIFIED,
      heading: HeadingLevel.HEADING_1,
      indent: { left: 800 },
    })
  );
};

export const addParagraph = (basicConf: any, paragraphText: string) => {
  const lastSectionIndex = basicConf.sections.length - 1;
  basicConf.sections[lastSectionIndex].children.push(
    new Paragraph({
      children: [
        new TextRun({
          text: paragraphText,
          font: 'Calibri',
        }),
      ],
    })
  );
};

export const addTable = () => {
  //To be implemented
};

export const addChartImage = async (basicConf: any, imageContainer: any, width: number, height: number) => {
  const canvasOptions = {
    logging: false,
    useCORS: true,
  };
  const canvas = await html2canvas(imageContainer, canvasOptions);
  const canvasToBlob = (cv: any) =>
    new Promise((resolve) => {
      return cv.toBlob((blob: any) => resolve(blob), 'image/png');
    });
  const blob: any = await canvasToBlob(canvas);
  const response = await fetch(URL.createObjectURL(blob));
  const buffer = await response.arrayBuffer();
  const imageBuffer = Buffer.from(buffer);
  const image = new ImageRun({
    data: imageBuffer,
    transformation: { width, height },
  });
  const lastSectionIndex = basicConf.sections.length - 1;
  basicConf.sections[lastSectionIndex].children.push(
    new Paragraph({
      children: [image],
      spacing: { before: 100, after: 100 },
      alignment: AlignmentType.CENTER,
    })
  );
};

export const addVarTitle = (basicConf: any, varName: string) => {
  const lastSectionIndex = basicConf.sections.length - 1;
  basicConf.sections[lastSectionIndex].children.push(
    new Paragraph({
      children: [
        new TextRun({
          text: `${varName}:`,
          bold: true,
          color: 'F79646',
        }),
      ],
      alignment: AlignmentType.JUSTIFIED,
      heading: HeadingLevel.HEADING_6,
    })
  );
};

const replaceNumber = (originalString: string, paragraphNumber: number): string => {
  const replacedString = originalString.replace(/^\d+\./, `${paragraphNumber}.`);
  return replacedString;
};

export const generateDocument = (documentConf: any) => {
  if (documentConf.sections.length > 0) {
    documentConf.sections.shift(); // Remove the first section
  }

  let paragraphCount = 1;

  for (let i = 0; i < documentConf.sections.length; i++) {
    const currSection = documentConf.sections[i];
    const firstChild = currSection.children[0];
    if (firstChild) {
      const text = firstChild.root[1].root[1].root[1];
      firstChild.root[1].root[1].root[1] = replaceNumber(text, paragraphCount);
      paragraphCount += 1;
    }
  }

  const doc = new Document(documentConf);
  Packer.toBlob(doc).then((blob) => {
    const currentDatetime = new Date().toISOString().replace(/[^0-9]/g, ''); // Remove non-numeric characters from datetime
    const filename = `NuevoReporte_${currentDatetime}.docx`;

    const responseFile = new File([blob], filename, {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    const url = window.URL.createObjectURL(responseFile);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.style.display = 'none'; // Hide the link element
    document.body.appendChild(a); // Append the link to the DOM
    a.click(); // Simulate click to trigger download
    document.body.removeChild(a); // Remove the link from the DOM
    URL.revokeObjectURL(url);
  });
};
