import React, { useState, useEffect, useMemo } from 'react';

import {
  Accordion,
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Stack,
} from 'react-bootstrap';
import { Plus, ArrowCounterclockwise, Table, InfoCircle } from 'react-bootstrap-icons';

import { useNavigate, useParams } from 'react-router-dom';

import Study from './Study';
import Result from './Result';
import StudyForm from './StudyForm';
import { AuthenticationLayout } from '../../layouts';
import {
  useGetDataSetDetailQuery,
  useGetStudiesQuery,
  useGetDataSetStatusQuery,
  useGetDataSetResultsQuery,
  useGetDataSetTasksStatusQuery,
  useGetDataSetConsolidationTasksStatusQuery,
  useSendStudyRequestMutation,
  useSendStudyRequestWithStudiesMutation,
  useCreateStudyMutation,
  useCancelConsolidationMutation,
  useUpdateStudyBlocksFileMutation,
  useUpdateDataSetMutation,
} from '../../services';
import { FileUploader } from '../../components';

import { useAppSelector } from '../../redux/hooks';
import { IConsolidated, IStudy } from '../../services/types';
import { handleInputChange, INPUT_FILE_TYPE_DICT, formatInputFileType } from '../../utils';

import './style.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const templateBlocksOrGrid = require('../../assets/templates/TemplateBlocksOrGrid.csv');

interface DataSetDetailProps {
  projectId?: string;
  dataSetId: string;
  noProject?: boolean;
  projectResponsesCount?: number;
  changeActiveTab?: React.Dispatch<React.SetStateAction<string>>;
}

const defaultNewStudyParameters = {
  semiMajorAxis: '100',
  semiMediumAxis: '100',
  semiMinorAxis: '100',
  height: '100',
  theta: '0',
  phi: '0',
  m: '2',
  studyType: 'OTROS',
  volume_kind: 'cylinder',
  holeIdComparison: 'none',
  columnNoValuesCases: false,
  maxDistance: '10',
  neighborsDetails: false,
  spacing: '10',
  spacingX: '10',
  spacingY: '10',
  spacingZ: '10',
};

export default function DataSetDetail({
  projectId = '',
  dataSetId = '',
  noProject = false,
  projectResponsesCount = 0,
  changeActiveTab,
}: DataSetDetailProps) {
  const navigate = useNavigate();
  const auth: any = useAppSelector((state) => state.auth);
  const params = useParams();

  if (noProject) {
    dataSetId = params.dataSetId ? params.dataSetId : '';
  }

  const [dataSetName, setDataSetName] = useState<string>('');
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [canSend, setCanSend] = useState<boolean>(false);
  const [sendStudyRequestConfirm, setSendStudyRequestConfirm] = useState<boolean>(false);
  const [searchMode, setSearchMode] = useState<string>('legacypandas');
  const [inputFileType, setInputFileType] = useState<string>('blocks');
  const [automaticGrid, setAutomaticGrid] = useState<boolean>(false);
  const [performingUpdate, setPerformingUpdate] = useState<boolean>(false);
  const [createdStudy, setEditingStudy] = useState<IStudy | undefined>(undefined);
  const [studiesIds, setStudiesIds] = useState<Array<string>>([]);
  const [allStudiesCheckedInSendStudiesModal, setAllStudiesCheckedInSendStudiesModal] = useState<boolean>(false);

  const { data: dataSetStatusData } = useGetDataSetStatusQuery(dataSetId, {
    pollingInterval: 10000,
    skip: canSend,
  });
  const {
    data: dataSetData,
    isLoading: gettingDataSetData,
    refetch: refetchDataSetDetail,
  } = useGetDataSetDetailQuery(dataSetId);
  const { data: dataSetStudies, isFetching: gettingStudies, refetch: refetchStudies } = useGetStudiesQuery(dataSetId);
  const { data: dataSetResults } = useGetDataSetResultsQuery(dataSetId, {
    pollingInterval: 10000,
  });
  const { data: dataSetTasksStatus, isFetching: gettingDataSetTasksStatus } = useGetDataSetTasksStatusQuery(dataSetId, {
    pollingInterval: 10000,
  });
  const { data: dataSetConsolidationTasksStatus, isFetching: gettingDataSetConsolidationTasksStatus } =
    useGetDataSetConsolidationTasksStatusQuery(dataSetId, {
      pollingInterval: 10000,
    });

  const [sendStudyRequestTrigger, { isLoading: sendingStudyRequest, isError: isErrorSendingStudyRequest }] =
    useSendStudyRequestMutation();

  const [
    sendStudyRequestWithStudiesTrigger,
    { isLoading: sendingStudyRequestWithStudies, isError: isErrorSendingStudyRequestWithStudies },
  ] = useSendStudyRequestWithStudiesMutation();

  const [createStudyTrigger, { isLoading: creatingStudy }] = useCreateStudyMutation();
  const [updateBlockStudyTrigger] = useUpdateStudyBlocksFileMutation();
  const [
    updateDataSetTrigger,
    {
      isLoading: isUpdatingDataSet,
      isError: isErrorUpdatingDataSet,
      isSuccess: isSuccessUpdatingDataSet,
      reset: resetUpdateDataSet,
    },
  ] = useUpdateDataSetMutation();

  const openCreateStudyForm = () => {
    const form = new FormData();
    form.append('name', 'Nuevo estudio');
    form.append('parameters', JSON.stringify(defaultNewStudyParameters));
    form.append('request', dataSetId ? dataSetId : '');
    createStudyTrigger(form)
      .unwrap()
      .then((data: IStudy) => {
        setEditingStudy(data);
        setShowCreateForm(true);
      });
  };
  const closeCreateStudyForm = () => setShowCreateForm(false);
  const openSendStudyRequestConfirm = () => {
    setStudiesIds([]);
    setAllStudiesCheckedInSendStudiesModal(true);
    setSendStudyRequestConfirm(true);
  };
  const closeSendStudyRequestConfirm = () => setSendStudyRequestConfirm(false);

  useEffect(() => {
    if (dataSetData) {
      setDataSetName(dataSetData.name);
      setSearchMode(dataSetData.searchMode);
      setCanSend(dataSetData.canSend);
      setInputFileType(dataSetData.inputFileType);
    }
  }, [dataSetData]);

  useEffect(() => {
    if (dataSetStatusData !== undefined && dataSetStatusData !== canSend) {
      setCanSend(dataSetStatusData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSetStatusData]);

  const goBack = () => {
    const goBackPath = noProject ? `/projects/` : `/projects/${projectId}/`;
    navigate(goBackPath, { state: { comesFromDataSetPages: true } });
  };

  const sendStudyRequest = () => {
    if (dataSetData) {
      if (allStudiesCheckedInSendStudiesModal) {
        sendStudyRequestTrigger({
          studyRequestId: dataSetData.id,
          searchMode,
          inputFileType,
          automaticGrid,
        })
          .unwrap()
          .then(() => {
            closeSendStudyRequestConfirm();
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        sendStudyRequestWithStudiesTrigger({
          studyRequestId: dataSetData.id,
          searchMode,
          inputFileType,
          automaticGrid,
          studiesIds,
        })
          .unwrap()
          .then(() => {
            closeSendStudyRequestConfirm();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  const studiesWithoutFile = useMemo(() => dataSetStudies?.some((study: IStudy) => !study.fileURL), [dataSetStudies]);

  const validInputFile = useMemo(() => {
    if (inputFileType === 'blocks' && dataSetData?.validBlocksFile) {
      return true;
    } else if (inputFileType === 'grid' && dataSetData?.validGridFile) {
      return true;
    }
    return false;
  }, [inputFileType, dataSetData?.validBlocksFile, dataSetData?.validGridFile]);

  const validStudies = useMemo(() => {
    return !dataSetStudies?.some((study: IStudy) => !study.validFile);
  }, [dataSetStudies]);

  const [showPopoverBlocks, setShowPopoverBlocks] = useState(false);
  const handleOnMouseEnterBlocks = () => setShowPopoverBlocks(true);
  const handleOnMouseLeaveBlocks = () => setShowPopoverBlocks(false);

  const [showPopoverGrid, setShowPopoverGrid] = useState(false);
  const handleOnMouseEnterGrid = () => setShowPopoverGrid(true);
  const handleOnMouseLeaveGrid = () => setShowPopoverGrid(false);

  const [showPopoverElevationGrid, setShowPopoverElevationGrid] = useState(false);
  const handleOnMouseEnterElevationGrid = () => setShowPopoverElevationGrid(true);
  const handleOnMouseLeaveElevationGrid = () => setShowPopoverElevationGrid(false);

  const hasBlockStudy = useMemo(() => {
    if (dataSetStudies) {
      return dataSetStudies.some((study: IStudy) => study.isBlockStudy);
    }
    return false;
  }, [dataSetStudies]);

  const popoverBlocks = (
    <Popover
      id="popover-template-blocks"
      onMouseEnter={handleOnMouseEnterBlocks}
      onMouseLeave={handleOnMouseLeaveBlocks}
    >
      <Popover.Header as="h3">Plantilla para Bloques</Popover.Header>
      <Popover.Body>
        <p>Al completarla, asegúrate que los campos X, Y, Z y LENGTH sean numéricos.</p>
        <p>
          <a href={templateBlocksOrGrid} download="TemplateBlocksOrGrid.csv" target="_blank" rel="noreferrer">
            Descargar archivo
          </a>
        </p>
      </Popover.Body>
    </Popover>
  );

  const popoverGrid = (
    <Popover id="popover-template-grid" onMouseEnter={handleOnMouseEnterGrid} onMouseLeave={handleOnMouseLeaveGrid}>
      <Popover.Header as="h3">Plantilla para Grilla</Popover.Header>
      <Popover.Body>
        <p>Al completarla, asegúrate que los campos X, Y, Z y LENGTH sean numéricos.</p>
        <p>
          <a href={templateBlocksOrGrid} download="TemplateBlocksOrGrid.csv" target="_blank" rel="noreferrer">
            Descargar archivo
          </a>
        </p>
      </Popover.Body>
    </Popover>
  );

  const popoverElevationGrid = (
    <Popover
      id="popover-elevation-grid"
      onMouseEnter={handleOnMouseEnterElevationGrid}
      onMouseLeave={handleOnMouseLeaveElevationGrid}
    >
      <Popover.Header as="h3">Grilla de Elevación</Popover.Header>
      <Popover.Body>
        <p>
          Como archivo de grilla de elevación se espera un archivo comprimido (zip o rar) con los archivos necesarios
          para cargar el raster
        </p>
      </Popover.Body>
    </Popover>
  );

  const [showCancelConsolidationConfirmation, setShowCancelConsolidationConfirmation] = useState<boolean>(false);

  const [
    cancelConsolidationTrigger,
    { isLoading: isCancelingConsolidation, isError: isErrorCancelingConsolidation, reset: resetCancelConsolidation },
  ] = useCancelConsolidationMutation();

  const openCancelConsolidationConfirmation = () => {
    resetCancelConsolidation();
    setShowCancelConsolidationConfirmation(true);
  };
  const closeCancelConsolidationConfirmation = () => setShowCancelConsolidationConfirmation(false);

  const cancelConsolidation = () => {
    cancelConsolidationTrigger({ studyRequestId: dataSetId })
      .unwrap()
      .then(() => {
        closeCancelConsolidationConfirmation();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const blockUploadCallback = () => {
    refetchDataSetDetail();
    const form = new FormData();
    updateBlockStudyTrigger({ id: dataSetId, body: form })
      .unwrap()
      .then(() => {
        refetchStudies();
      });
  };

  const fileUploadCallback = () => {
    refetchDataSetDetail();
  };

  const handleStudyIdCheckbox = (studyId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = studiesIds.indexOf(studyId);
    let newStudiesIds = [...studiesIds];

    if (e.target.checked) {
      if (index === -1) {
        newStudiesIds = [...newStudiesIds, studyId];
      }
    } else {
      if (index !== -1) {
        newStudiesIds.splice(index, 1);
      }
    }

    setStudiesIds(newStudiesIds);
  };

  const updateDataSet = () => {
    resetUpdateDataSet();
    const form = new FormData();
    form.append('name', dataSetName);
    updateDataSetTrigger({ id: dataSetId, body: form })
      .unwrap()
      .then(() => {
        setDataSetName(dataSetName);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <AuthenticationLayout mustAdmin={false} mustAuth={true} noAuth={false}>
      <Container className="px-3 my-3">
        <Row>
          <Col>
            <Button
              className="d-flex flex-row align-items-center"
              onClick={goBack}
              variant="outline-secondary"
              size="sm"
            >
              <ArrowCounterclockwise size={32} />
              {noProject ? 'Volver a Proyectos' : 'Volver a Data Sets'}
            </Button>
          </Col>
        </Row>
        {gettingDataSetData ? (
          <Row className="loading">
            <Col>
              <Spinner />
            </Col>
          </Row>
        ) : (
          dataSetData && (
            <>
              {noProject && (
                <Row className="my-4">
                  <Alert variant="warning">
                    <Alert.Heading>Data Set sin Proyecto</Alert.Heading>
                    <p>Si desea asociar este DataSet a un Proyecto, por favor contacte al administrador.</p>
                    <p>Por ahora no puede ser modificado ni eliminado.</p>
                  </Alert>
                </Row>
              )}
              <Row className="my-4">
                <Col md={8}>
                  <Form.Label>
                    <h5>Nombre del data set</h5>
                  </Form.Label>
                  <Form.Control
                    value={dataSetName}
                    onChange={handleInputChange(setDataSetName)}
                    type="text"
                    className="parameter-input"
                    disabled={noProject || !canSend || isUpdatingDataSet}
                  />
                </Col>
                <Col className="d-flex align-items-end justify-content-end">
                  <Button variant="primary" onClick={updateDataSet} disabled={isUpdatingDataSet}>
                    Modificar nombre
                    {isUpdatingDataSet && <Spinner as="span" size="sm" />}
                  </Button>
                </Col>
              </Row>
              {isErrorUpdatingDataSet && <Alert variant="danger">Lo sentimos: hubo un error. Intente otra vez.</Alert>}
              {isSuccessUpdatingDataSet && <Alert variant="success">Modificación exitosa.</Alert>}
              <Row>
                <Col>
                  <h5>Búsqueda de vecinos</h5>
                  <p className="text-muted">Cargar archivos csv y parámetros de vecindad</p>
                </Col>
              </Row>
              <FileUploader
                fileDescription={
                  <Col xs={2} className="d-flex align-items-center gap-1">
                    Bloques{' '}
                    {dataSetData.validBlocksFile ? (
                      <Badge bg="success">Validado</Badge>
                    ) : (
                      <Badge bg="danger">No validado</Badge>
                    )}
                  </Col>
                }
                fileAttributeName="blocks_file"
                filename={dataSetData.blocksFilename}
                fileURL={dataSetData.blocksFileURL}
                model="dataset"
                id={dataSetData.id}
                preprocessingOnSuccessCallback={blockUploadCallback}
                preprocessingOnErrorCallback={blockUploadCallback}
                fileExtension=".csv"
                setPerformUpdate={setPerformingUpdate}
                extraCol={
                  <OverlayTrigger show={showPopoverBlocks} placement="bottom" overlay={popoverBlocks}>
                    <Button
                      variant="outline-secondary"
                      onMouseEnter={handleOnMouseEnterBlocks}
                      onMouseLeave={handleOnMouseLeaveBlocks}
                    >
                      <span>
                        <Table /> Plantilla
                      </span>
                    </Button>
                  </OverlayTrigger>
                }
              />
              {auth.isAdmin && (
                <FileUploader
                  fileDescription={
                    <Col xs={2} className="d-flex align-items-center gap-1">
                      Grilla{' '}
                      {dataSetData?.gridFilename &&
                        (dataSetData.validGridFile ? (
                          <Badge bg="success">Validado</Badge>
                        ) : (
                          <Badge bg="danger">No validado</Badge>
                        ))}
                    </Col>
                  }
                  fileAttributeName="grid_file"
                  filename={dataSetData.gridFilename}
                  fileURL={dataSetData.gridFileURL}
                  model="dataset"
                  id={dataSetData.id}
                  preprocessingOnSuccessCallback={fileUploadCallback}
                  preprocessingOnErrorCallback={fileUploadCallback}
                  setPerformUpdate={setPerformingUpdate}
                  fileExtension=".csv"
                  extraCol={
                    <OverlayTrigger show={showPopoverGrid} placement="bottom" overlay={popoverGrid}>
                      <Button
                        variant="outline-secondary"
                        onMouseEnter={handleOnMouseEnterGrid}
                        onMouseLeave={handleOnMouseLeaveGrid}
                      >
                        <span>
                          <Table /> Plantilla
                        </span>
                      </Button>
                    </OverlayTrigger>
                  }
                />
              )}
              <FileUploader
                fileDescription={
                  <Col xs={2} className="d-flex align-items-center gap-1">
                    <OverlayTrigger show={showPopoverElevationGrid} placement="top" overlay={popoverElevationGrid}>
                      <span
                        onMouseEnter={handleOnMouseEnterElevationGrid}
                        onMouseLeave={handleOnMouseLeaveElevationGrid}
                      >
                        Grilla de Elevación <InfoCircle />
                      </span>
                    </OverlayTrigger>
                  </Col>
                }
                fileAttributeName="elevation_grid_file"
                filename={dataSetData?.elevationGridFilename}
                fileURL={dataSetData?.elevationGridFileURL}
                model="dataset"
                setPerformUpdate={setPerformingUpdate}
                id={dataSetData.id}
                preprocessingOnSuccessCallback={fileUploadCallback}
                preprocessingOnErrorCallback={fileUploadCallback}
                fileExtension=".zip, .rar"
              />
            </>
          )
        )}
        {dataSetData && gettingStudies ? (
          <Row className="my-2">
            <Col className="centered-horizontally">
              <p>
                Obteniendo estudios ... <Spinner as="span" size="sm" />
              </p>
            </Col>
          </Row>
        ) : (
          dataSetStudies?.map((study: IStudy, i: number) => {
            return (
              <Study
                key={i}
                study={study}
                noProject={noProject}
                canSend={canSend}
                dataSetName={dataSetName}
                elevationGridFilename={dataSetData?.elevationGridFilename}
              />
            );
          })
        )}
        <Row className="my-2">
          <Col>
            {showCreateForm && (
              <StudyForm
                key={'create'}
                dataSetName={dataSetName}
                studyData={createdStudy}
                show={showCreateForm}
                onHide={closeCreateStudyForm}
                elevationGridFilename={dataSetData?.elevationGridFilename}
              />
            )}
            {creatingStudy ? (
              <Spinner />
            ) : (
              dataSetData &&
              !gettingStudies &&
              !performingUpdate && (
                <Button className="d-flex flex-row align-items-center" onClick={openCreateStudyForm} variant="light">
                  <Plus size={32} />
                  Agregar estudio
                </Button>
              )
            )}
          </Col>
        </Row>

        {!!dataSetData &&
        dataSetStudies?.length &&
        canSend &&
        !sendingStudyRequest &&
        !sendingStudyRequestWithStudies ? (
          <>
            <Row className="py-4">
              <Col>
                <Stack className="col-md-5 mx-auto">
                  <Modal
                    show={sendStudyRequestConfirm}
                    onHide={closeSendStudyRequestConfirm}
                    centered
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Generar consolidado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {sendingStudyRequest || sendingStudyRequestWithStudies ? (
                        <Row>
                          <Col className="centered-horizontally">
                            <Spinner />
                          </Col>
                        </Row>
                      ) : !isErrorSendingStudyRequest && !isErrorSendingStudyRequestWithStudies ? (
                        <>
                          <p>
                            ¿Está seguro que quiere enviar a generar un consolidado usando el archivo de{' '}
                            <b>{formatInputFileType(inputFileType)}</b>?
                          </p>
                          <p>Seleccione los estudios a considerar:</p>
                          {dataSetStudies?.map((study: IStudy) => {
                            return (
                              <Form.Check
                                key={`option-${study.id}`}
                                type="checkbox"
                                id={`option-${study.id}`}
                                label={study.name}
                                onChange={handleStudyIdCheckbox(study.id)}
                                disabled={allStudiesCheckedInSendStudiesModal}
                              />
                            );
                          })}
                          {
                            <Form.Check
                              key={`option-all-studies`}
                              type="checkbox"
                              id={`ooption-all-studies`}
                              label="Considerar todos"
                              onChange={() => setAllStudiesCheckedInSendStudiesModal((state) => !state)}
                              className="fw-semibold"
                              defaultChecked={allStudiesCheckedInSendStudiesModal}
                            />
                          }
                        </>
                      ) : (
                        <Row>
                          <Col>
                            <Alert variant="danger" className="my-1">
                              Lo sentimos: hubo un error.
                            </Alert>
                          </Col>
                        </Row>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      {sendingStudyRequest || sendingStudyRequestWithStudies ? (
                        <Spinner />
                      ) : (
                        <>
                          <Button variant="secondary" onClick={closeSendStudyRequestConfirm}>
                            NO
                          </Button>
                          <Button
                            className="ms-1"
                            variant="primary"
                            onClick={sendStudyRequest}
                            disabled={!(allStudiesCheckedInSendStudiesModal || studiesIds.length > 0)}
                          >
                            SI
                          </Button>
                        </>
                      )}
                    </Modal.Footer>
                  </Modal>
                  {auth.isAdmin && (
                    <>
                      <Form.Label className="mx-auto">Generar consolidado considerando:</Form.Label>
                      <Form.Group className="mx-auto">
                        {Object.keys(INPUT_FILE_TYPE_DICT).map((key) => (
                          <Form.Check
                            type="radio"
                            value={key}
                            checked={inputFileType === key}
                            label={INPUT_FILE_TYPE_DICT[key]}
                            onChange={handleInputChange(setInputFileType)}
                            disabled={(key === 'grid' && !dataSetData.gridFileURL) || noProject}
                            key={`input_file_type_${key}`}
                          />
                        ))}
                        <Form.Check
                          type="switch"
                          id={'automatic-grid'}
                          label="Automática"
                          checked={automaticGrid}
                          onChange={() => setAutomaticGrid(!automaticGrid)}
                          disabled={inputFileType !== 'grid'}
                        />
                      </Form.Group>
                    </>
                  )}
                  <Button
                    variant="success"
                    size="lg"
                    onClick={openSendStudyRequestConfirm}
                    disabled={
                      studiesWithoutFile ||
                      noProject ||
                      !validInputFile ||
                      !validStudies ||
                      !hasBlockStudy ||
                      performingUpdate
                    }
                  >
                    Generar consolidado
                  </Button>
                </Stack>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        {!hasBlockStudy && !gettingStudies && (
          <Row>
            <Col className="centered-horizontally">
              <Alert variant="danger">
                Falta generar el archivo de estudio de bloques (Vuelve a subir el archivo de bloques para generarlo).
              </Alert>
            </Col>
          </Row>
        )}
        {((!!dataSetStudies?.length && !canSend) || sendingStudyRequest || sendingStudyRequestWithStudies) && (
          <>
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <Spinner />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="d-flex justify-content-center">
                <Stack className="col-md-5 mx-auto">
                  <p className="mx-auto">Estamos generando tu consolidado ...</p>
                  <Modal
                    show={showCancelConsolidationConfirmation}
                    onHide={closeCancelConsolidationConfirmation}
                    centered
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Cancelar generación de consolidado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {isCancelingConsolidation ? (
                        <Row>
                          <Col className="centered-horizontally">
                            <Spinner />
                          </Col>
                        </Row>
                      ) : !isErrorCancelingConsolidation ? (
                        <span>¿Estás seguro que deseas cancelar la más vigente generación del consolidado?</span>
                      ) : (
                        <Row>
                          <Col>
                            <Alert variant="danger" className="my-1">
                              Lo sentimos: hubo un error.
                            </Alert>
                          </Col>
                        </Row>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        disabled={isCancelingConsolidation}
                        onClick={closeCancelConsolidationConfirmation}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="primary"
                        disabled={isCancelingConsolidation}
                        onClick={() => cancelConsolidation()}
                      >
                        Aceptar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Button
                    className="mx-auto"
                    variant="danger"
                    onClick={() => openCancelConsolidationConfirmation()}
                    disabled={isCancelingConsolidation}
                  >
                    Cancelar generación de consolidado
                  </Button>
                </Stack>
              </Col>
            </Row>
          </>
        )}
        {/* TODO: check that this line is necessary ... it's annoying now
          auth.isAdmin && dataSetData && gettingResults &&
          <Row className='my-2'>
            <Col className="centered-horizontally">
              <p>Actualizando resultados ... <Spinner as='span' size='sm' /></p>
            </Col>
          </Row>
        */}
        {dataSetData && projectResponsesCount > 0 && canSend && (
          <Alert variant="info" className="my-2 text-center">
            Existen resultados en la pestaña Respuestas. Puedes acceder{' '}
            <a href="#!" onClick={() => changeActiveTab && changeActiveTab('responses')}>
              aquí
            </a>
            .
          </Alert>
        )}
        {dataSetData && (!canSend || (canSend && projectResponsesCount === 0)) && (
          <Alert variant="info" className="my-2 text-center">
            Estamos generando resultados. Te llegará un e-mail de aviso cuando éstos hayan sido generados.
          </Alert>
        )}
        <Accordion defaultActiveKey="0">
          {dataSetResults &&
            dataSetResults.map((result: IConsolidated, i: number) => {
              if (auth.isAdmin || result.responseFiles.length > 0)
                return (
                  <Result
                    key={i}
                    index={i}
                    result={result}
                    projectId={projectId}
                    dataSetId={dataSetId}
                    tasksStatus={dataSetTasksStatus?.filter((taskStatus) => taskStatus.studySentId === result.id)}
                    gettingTasksStatus={gettingDataSetTasksStatus}
                    consolidationTasksStatus={dataSetConsolidationTasksStatus?.filter(
                      (taskStatus) => taskStatus.studySentId === result.id
                    )}
                    gettingConsolidationTasksStatus={gettingDataSetConsolidationTasksStatus}
                    dataSetStudies={dataSetStudies}
                  />
                );
              else return null;
            })}
        </Accordion>
      </Container>
    </AuthenticationLayout>
  );
}
