interface IEnv {
  BACKEND_URL: string;
  API_GATEWAY_URL: string;
}

const environments: { [key: string]: IEnv } = {
  local: {
    BACKEND_URL: 'http://localhost:8000',
    API_GATEWAY_URL: 'http://localhost:8000',
  },
  dev: {
    BACKEND_URL: 'https://api.dev.mf-tech.ai',
    API_GATEWAY_URL: 'https://api.dev.mf-tech.ai/api',
  },
  staging: {
    BACKEND_URL: 'http://mf-staging-lb-500722257.us-east-1.elb.amazonaws.com',
    API_GATEWAY_URL: 'https://zso39uezl6.execute-api.us-east-1.amazonaws.com/staging',
  },
  prod: {
    BACKEND_URL: 'https://mf-tech.ai',
    API_GATEWAY_URL: 'https://5obnijtfr1.execute-api.us-east-1.amazonaws.com/prod',
  },
};

export const config: IEnv = environments[process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'dev'];
