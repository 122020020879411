import { configureStore, combineReducers, MiddlewareAPI, Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import { projectApi } from '../services/project';
import { fileUploadApi } from '../services/fileUpload';
import { dataManagementApi } from '../services/dataManagement';
import { slackApi } from '../services/slack';
import { apiGateway } from '../services/apigateway';
import { epsgApi } from '../services/epsg';

import { authSlice, changeAuthState } from '../features';

const persistConfig = {
  key: 'root',
  storage,
  whilelist: ['auth'],
  blacklist: [
    projectApi.reducerPath,
    dataManagementApi.reducerPath,
    fileUploadApi.reducerPath,
    slackApi.reducerPath,
    apiGateway.reducerPath,
    epsgApi.reducerPath,
  ],
};

const rootReducer = combineReducers({
  auth: authSlice,
  [projectApi.reducerPath]: projectApi.reducer,
  [dataManagementApi.reducerPath]: dataManagementApi.reducer,
  [fileUploadApi.reducerPath]: fileUploadApi.reducer,
  [slackApi.reducerPath]: slackApi.reducer,
  [apiGateway.reducerPath]: apiGateway.reducer,
  [epsgApi.reducerPath]: epsgApi.reducer,
});

export const clearCache = (dispatch: any) => {
  dispatch(projectApi.util.resetApiState());
  dispatch(dataManagementApi.util.resetApiState());
  dispatch(fileUploadApi.util.resetApiState());
  dispatch(slackApi.util.resetApiState());
  dispatch(epsgApi.util.resetApiState());
};

const notAuthorizedHandler: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload === 401) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (!user) {
            // Force a logout if is not authenticated
            api.dispatch(
              changeAuthState({
                isAuth: false,
                isAdmin: false,
                email: 'no-email',
              })
            );
          } else {
            let email = 'no-email';
            user.getUserAttributes((err: any, attributes: any) => {
              if (attributes) {
                const emailAttribute = attributes.find((attr: any) => attr.Name === 'email');
                if (emailAttribute) {
                  email = emailAttribute.Value;
                }
              }
            });

            // If is authenticated but get 401, revoke isAdmin permission
            const data = user.getSignInUserSession();
            let isAdmin = false;
            if (data) {
              const tokenPayload = data.getAccessToken().decodePayload();
              if (tokenPayload['cognito:groups']) {
                const groups: string[] = tokenPayload['cognito:groups'];
                if (groups.includes(process.env['REACT_APP_ADMIN_GROUP'] || '')) {
                  isAdmin = true;
                }
              }
            }
            api.dispatch(
              changeAuthState({
                isAuth: true,
                isAdmin: isAdmin,
                email: email,
              })
            );
          }
          clearCache(api.dispatch);
        })
        .catch(() =>
          api.dispatch(
            changeAuthState({
              isAuth: false,
              isAdmin: false,
              email: 'no-email',
            })
          )
        );
      // After a 401 reset cache
    }
  }
  return next(action);
};

const middlewares = [
  projectApi.middleware,
  dataManagementApi.middleware,
  fileUploadApi.middleware,
  slackApi.middleware,
  apiGateway.middleware,
  epsgApi.middleware,
  notAuthorizedHandler,
];

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewares),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
