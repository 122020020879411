import { useState, useEffect, useCallback } from 'react';
import { Container, Button, Modal, Form } from 'react-bootstrap';
import MiniLollipopChart from './MiniLollipopChart';
import { IVariableInfo } from '../../../services/types';

interface Props {
  data: any;
  depVarName: string;
  colNames: string[];
  studyType: string;
  plotsPerRow: number;
  widthHeightRatio: number;
  width?: number;
  binSizes: number[];
  interestGrade: number;
  colInfo: undefined | IVariableInfo;
  xAxisDistribution: string;
}

export default function LollipopChartGrid({
  data,
  depVarName,
  colNames,
  plotsPerRow,
  widthHeightRatio,
  width,
  binSizes,
  interestGrade,
  colInfo,
  xAxisDistribution,
}: Props) {
  const [modalShow, setModalShow] = useState(false);
  const [selectedColName, setSelectedColName] = useState('');
  const [containerWidth, setContainerWidth] = useState(300);
  const [correctedData, setCorrectedData] = useState<any>();
  const [updatedColNames, setUpdatedColNames] = useState<string[]>([]);
  const [showInstructions, setShowInstructions] = useState<boolean>(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false);

  useEffect(() => {
    const hasSeenInstructions = localStorage.getItem('hasSeenLollipopInstructions');
    const instructionsSeenBool = hasSeenInstructions === 'true';
    if (!instructionsSeenBool) {
      // Optionally, automatically show the instructions the first time
      setShowInstructions(true);
    }
  }, []);
  const selectData = (colName: string) => {
    return correctedData.map((el: any) => el[colName]);
  };

  const convertToCorrectValues = () => {
    if (!data || !colInfo) {
      return;
    }

    const categoricalCols: string[] = [];
    Object.entries(colInfo).forEach(([colPrefix, colType]) => {
      if (colType === 'C') {
        categoricalCols.push(colPrefix);
      }
    });

    const correctedData = data.map((el: any) => {
      const correctedRow: { [key: string]: any } = {};
      for (const key in el) {
        if (Object.prototype.hasOwnProperty.call(el, key)) {
          let updated = false;
          for (const prefix of categoricalCols) {
            if (key.startsWith(prefix)) {
              correctedRow[key.concat(' (%)')] = el[key] * 100;
              updated = true;
              break;
            }
          }
          if (!updated) {
            correctedRow[key] = el[key];
          }
        }
      }
      return correctedRow;
    });
    setCorrectedData(correctedData);
    const newColNames = colNames.map((colName) => {
      for (const prefix of categoricalCols) {
        if (colName.includes(prefix)) {
          return colName + ' (%)';
        }
      }
      return colName;
    });
    setUpdatedColNames(newColNames);
  };

  const updateContainerWidth = useCallback(() => {
    const container = document.getElementsByClassName('chart-grid container')[0] as HTMLElement;

    if (container) {
      const totalWidth = container.offsetWidth;
      setContainerWidth(totalWidth / plotsPerRow - 20);
    }
  }, [plotsPerRow]);

  useEffect(() => {
    convertToCorrectValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, colNames]);

  useEffect(() => {
    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    return () => window.removeEventListener('resize', updateContainerWidth);
  }, [plotsPerRow, updateContainerWidth]);

  return (
    <>
      <Modal show={showInstructions} onHide={() => setShowInstructions(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cómo utilizar los gráficos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Arrastra el mouse en el gráfico para ajustar el eje Y. Para reiniciar a la vista inicial, haz doble click en
            el gráfico.
          </p>
          <p>Esto permite examinar con mayor precisión los puntos en la data y las tendencias de cada gráfico.</p>
          <div style={{ textAlign: 'center' }}>
            {' '}
            {/* Center the GIF */}
            <img
              src={`${process.env.PUBLIC_URL}/gifs/lollipopInstructions.gif`}
              alt="Instrucciones"
              style={{ maxWidth: '70%', height: 'auto' }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Form.Group controlId="dontShowAgainCheckbox" style={{ marginBottom: 0 }}>
              <Form.Check
                type="checkbox"
                label="No mostrar de nuevo"
                onChange={(e) => setDoNotShowAgain(e.target.checked)}
              />
            </Form.Group>
            <Button
              variant="primary"
              onClick={() => {
                if (doNotShowAgain) {
                  localStorage.setItem('hasSeenLollipopInstructions', 'true');
                }
                setShowInstructions(false);
              }}
            >
              ¡Entendido!
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {correctedData &&
        correctedData.length > 0 &&
        Array.from({ length: Math.ceil(colNames.length / plotsPerRow) }, (_, groupIndex) => (
          <div className="grouped-chart-row" key={groupIndex} style={{ display: 'flex' }}>
            {updatedColNames.slice(groupIndex * plotsPerRow, (groupIndex + 1) * plotsPerRow).map((colName) => (
              <div
                className="chart-container"
                key={colName}
                style={{
                  width: containerWidth,
                  margin: '5px',
                  padding: '5px',
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  position: 'relative',
                }}
              >
                <div className="centered-content">
                  <MiniLollipopChart
                    depVarName={depVarName}
                    yName={colName}
                    xData={selectData('DEPVAR')}
                    yData={selectData(colName)}
                    binSizes={binSizes}
                    width={containerWidth - 10}
                    height={(containerWidth - 10) * widthHeightRatio}
                    interestGrade={interestGrade}
                    xAxisDistribution={xAxisDistribution}
                  />
                  <Button
                    size="sm"
                    onClick={() => {
                      setSelectedColName(colName);
                      setModalShow(true);
                    }}
                    style={{
                      position: 'absolute',
                      top: '1px',
                      right: '1px',
                      zIndex: 1,
                      cursor: 'pointer',
                      width: '20px',
                      height: '20px',
                      display: 'flex', // Use flex display
                      alignItems: 'center', // Center vertically
                      justifyContent: 'center', // Center horizontally
                      fontSize: '16px', // Adjust the font size as needed
                    }}
                  >
                    +
                  </Button>
                </div>
              </div>
            ))}
            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>{depVarName.concat(' vs ', selectedColName)}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ padding: '5px' }}>
                <Container className="px-3 my-3 d-flex flex-column align-items-center justify-content-center">
                  <MiniLollipopChart
                    depVarName={depVarName}
                    yName={selectedColName}
                    xData={selectData('DEPVAR')}
                    yData={selectData(selectedColName)}
                    binSizes={binSizes}
                    width={width ? containerWidth - 10 : 400}
                    height={width ? (containerWidth - 10) * widthHeightRatio : 400}
                    interestGrade={interestGrade}
                    xAxisDistribution={xAxisDistribution}
                  />
                </Container>
              </Modal.Body>
            </Modal>
          </div>
        ))}
    </>
  );
}
