import { multiply } from 'mathjs';
import { Data } from 'plotly.js';

// Expression in radians of 360°
const RADIANS360 = 2 * Math.PI;
// Number of initial sample to generate points of surface. Total number = INITIAL_SAMPLE^2
const INITIAL_SAMPLE = 50;

function degreeToRadians(degreeAngle: number): number {
  return (degreeAngle * Math.PI) / 180;
}

function linespace(startValue: number, stopValue: number, cardinality: number) {
  const arr = [];
  const step = (stopValue - startValue) / (cardinality - 1);
  for (let i = 0; i < cardinality; i++) {
    arr.push(startValue + step * i);
  }
  return arr;
}

function drawAzimuthTraces(xAxis: number, yAxis: number, azimuthDegree: number): any[] {
  const azimuth = degreeToRadians(azimuthDegree);
  const u = linespace(Math.PI / 2, Math.PI / 2 - azimuth, INITIAL_SAMPLE);

  const radius = 1.25 * Math.max(xAxis, yAxis);
  const cosU = u.map(Math.cos);
  const sinU = u.map(Math.sin);

  const azimuthX = cosU.map((v) => {
    return radius * v;
  });
  const azimuthY = sinU.map((v) => {
    return radius * v;
  });
  const azimuthZ = new Array(INITIAL_SAMPLE).fill(0);

  const plotType = 'scatter3d';
  const mode = 'lines';
  const color = 'blue';
  const width = 3;
  const name = 'Azimut';

  const azimuthTrace = {
    x: azimuthX,
    y: azimuthY,
    z: azimuthZ,
    type: plotType,
    mode: mode,
    line: {
      color: color,
      width: width,
    },
    name: name,
  };

  const extremeTrace = {
    x: [0, azimuthX.slice(-1)[0]],
    y: [0, azimuthY.slice(-1)[0]],
    z: [0, 0],
    type: plotType,
    mode: mode,
    line: {
      color: color,
      width: width,
    },
    showlegend: false,
  };

  return [azimuthTrace, extremeTrace];
}

function drawArrowTraces(allX: number[], allY: number[], allZ: number[]): any[] {
  const maxX = Math.max(...allX);
  const maxY = Math.max(...allY);
  const maxZ = Math.max(...allZ);

  const length = Math.max(maxX, maxY, maxZ) * 1.25;
  const arrowEndPoints = [
    [length, 0, 0, 'Eje x'],
    [0, length, 0, 'Eje y'],
    [0, 0, length, 'Eje z'],
  ];

  const arrows: any[] = [];

  for (const endpoint of arrowEndPoints) {
    const arrowTrace = {
      x: [0, endpoint[0]],
      y: [0, endpoint[1]],
      z: [0, endpoint[2]],
      type: 'scatter3d',
      mode: 'lines',
      line: {
        color: 'black',
        width: 3,
      },
      showlegend: false,
    };
    arrows.push(arrowTrace);
  }

  return arrows;
}

function drawNegativeArrowTraces(allX: number[], allY: number[], allZ: number[]): any[] {
  const maxX = Math.max(...allX);
  const maxY = Math.max(...allY);
  const maxZ = Math.max(...allZ);

  const length = Math.max(maxX, maxY, maxZ) * 1.25;
  const arrowEndPoints = [
    [length, 0, 0, 'Eje x'],
    [0, length, 0, 'Eje y'],
    [0, 0, length, 'Eje z'],
  ];

  const arrows: any[] = [];

  for (const endpoint of arrowEndPoints) {
    const arrowTrace = {
      x: [-endpoint[0], 0],
      y: [-endpoint[1], 0],
      z: [-endpoint[2], 0],
      type: 'scatter3d',
      mode: 'lines',
      line: {
        color: 'black',
        width: 0,
      },
      showlegend: false,
    };
    arrows.push(arrowTrace);
  }

  return arrows;
}

function pointsForSurface(xRot: number[], yRot: number[], zRot: number[]) {
  const xPts: number[][] = [];
  const yPts: number[][] = [];
  const zPts: number[][] = [];

  let i = 0;
  let xTemp: number[] = [];
  let yTemp: number[] = [];
  let zTemp: number[] = [];

  while (i < xRot.length) {
    xTemp.push(xRot[i]);
    yTemp.push(yRot[i]);
    zTemp.push(zRot[i]);

    i++;

    if (i % INITIAL_SAMPLE === 0) {
      xPts.push(xTemp);
      yPts.push(yTemp);
      zPts.push(zTemp);
      xTemp = [];
      yTemp = [];
      zTemp = [];
    }
  }

  return [xPts, yPts, zPts];
}

export function drawEllipsoid(
  xAxis: number,
  yAxis: number,
  zAxis: number,
  azimuthDegree: number,
  dipDegree: number
): Data[] {
  const azimuth = degreeToRadians(azimuthDegree),
    dip = degreeToRadians(dipDegree);
  // Generate a grid of points on the ellipsoid
  const u = linespace(0, RADIANS360, INITIAL_SAMPLE);
  const v = linespace(0, Math.PI, INITIAL_SAMPLE);
  const cosU = u.map(Math.cos);
  const sinU = u.map(Math.sin);
  const cosV = v.map(Math.cos);
  const sinV = v.map(Math.sin);

  const x: number[][] = new Array(INITIAL_SAMPLE).fill(0).map(() => new Array(INITIAL_SAMPLE).fill(0));
  const y: number[][] = new Array(INITIAL_SAMPLE).fill(0).map(() => new Array(INITIAL_SAMPLE).fill(0));
  const z: number[][] = new Array(INITIAL_SAMPLE).fill(0).map(() => new Array(INITIAL_SAMPLE).fill(0));

  for (let i = 0; i < INITIAL_SAMPLE; i++) {
    for (let j = 0; j < INITIAL_SAMPLE; j++) {
      x[i][j] = xAxis * cosU[i] * sinV[j];
      y[i][j] = yAxis * sinU[i] * sinV[j];
      z[i][j] = zAxis * cosV[j];
    }
  }
  let xFlatten: number[] = [];
  let yFlatten: number[] = [];
  let zFlatten: number[] = [];
  for (let i = 0; i < INITIAL_SAMPLE; i++) {
    xFlatten = xFlatten.concat(x[i]);
    yFlatten = yFlatten.concat(y[i]);
    zFlatten = zFlatten.concat(z[i]);
  }
  const xyz: number[][] = [xFlatten, yFlatten, zFlatten]; // shape (3, 50^2)

  const R_azimuth = [
    [Math.cos(azimuth), Math.sin(azimuth), 0],
    [-Math.sin(azimuth), Math.cos(azimuth), 0],
    [0, 0, 1],
  ];

  const RDip = [
    [Math.cos(dip), 0, Math.sin(dip)],
    [0, 1, 0],
    [-Math.sin(dip), 0, Math.cos(dip)],
  ];

  // IMPORTANT: order matters
  const xyzRot = multiply(multiply(R_azimuth, RDip), xyz);

  const [xPts, yPts, zPts] = pointsForSurface(xyzRot[0], xyzRot[1], xyzRot[2]);

  const ellipsoid = {
    x: xPts,
    y: yPts,
    z: zPts,
    type: 'surface',
    showscale: false,
    opacity: 0.9,
    name: 'Elipsoide',
  };

  const azimuthTraces = drawAzimuthTraces(xAxis, yAxis, azimuthDegree);
  const arrowTraces = drawArrowTraces(xFlatten, yFlatten, zFlatten);
  const negativeArrowTraces = drawNegativeArrowTraces(xFlatten, yFlatten, zFlatten);

  return [ellipsoid, ...azimuthTraces, ...arrowTraces, ...negativeArrowTraces];
}

export function drawCylinder(xAxis: number, yAxis: number, height: number, azimuthDegree: number): Data[] {
  const azimuth = degreeToRadians(azimuthDegree);
  // Create arrays of coordinates in cylindrical coordinates; 50 points
  const u = linespace(0, RADIANS360, INITIAL_SAMPLE);
  const z = linespace(-height / 2, height / 2, INITIAL_SAMPLE);
  // Meshgrid(u,z)
  const uMatrix: number[][] = z.map(() => new Array(u.length).fill(0));
  const zMatrix: number[][] = z.map(() => new Array(u.length).fill(0));
  // For u matrix
  for (let i = 0; i < u.length; i++) {
    for (let j = 0; j < u.length; j++) {
      uMatrix[i][j] = u[j];
      zMatrix[j][i] = z[j];
    }
  }

  const uMatrixCos = uMatrix.map((row) => row.map(Math.cos));
  const uMatrixSin = uMatrix.map((row) => row.map(Math.sin));

  const r = uMatrix.map((row, i) =>
    row.map((_, j) =>
      Math.sqrt(
        Math.pow(xAxis * yAxis, 2) / (Math.pow(yAxis * uMatrixCos[i][j], 2) + Math.pow(xAxis * uMatrixSin[i][j], 2))
      )
    )
  );
  const x: number[][] = [];
  const y: number[][] = [];
  for (let i = 0; i < INITIAL_SAMPLE; i++) {
    const xRow: number[] = [];
    const yRow: number[] = [];
    for (let j = 0; j < INITIAL_SAMPLE; j++) {
      xRow.push(r[i][j] * uMatrixCos[i][j]);
      yRow.push(r[i][j] * uMatrixSin[i][j]);
    }
    x.push(xRow);
    y.push(yRow);
  }

  // Define rotation matrix
  const RAzimuth = [
    [Math.cos(azimuth), Math.sin(azimuth), 0],
    [-Math.sin(azimuth), Math.cos(azimuth), 0],
    [0, 0, 1],
  ];
  // Apply rotation to coordinates
  let xFlatten: number[] = [];
  let yFlatten: number[] = [];
  let zFlatten: number[] = [];
  for (let i = 0; i < INITIAL_SAMPLE; i++) {
    xFlatten = xFlatten.concat(x[i]);
    yFlatten = yFlatten.concat(y[i]);
    zFlatten = zFlatten.concat(zMatrix[i]);
  }
  const cylCoords = [xFlatten, yFlatten, zFlatten];
  const rotatedCoords = multiply(RAzimuth, cylCoords);

  const xRot = rotatedCoords[0];
  const yRot = rotatedCoords[1];
  const zRot = rotatedCoords[2];

  const [xPts, yPts, zPts] = pointsForSurface(xRot, yRot, zRot);

  const cylinder = {
    x: xPts,
    y: yPts,
    z: zPts,
    type: 'surface',
    showscale: false,
    opacity: 0.6,
    name: 'Cilindro',
  };

  const azimuthTraces = drawAzimuthTraces(xAxis, yAxis, azimuthDegree);
  const arrowTraces = drawArrowTraces(xFlatten, yFlatten, zFlatten);
  const negativeArrowTraces = drawNegativeArrowTraces(xFlatten, yFlatten, zFlatten);

  return [cylinder, ...azimuthTraces, ...arrowTraces, ...negativeArrowTraces];
}
