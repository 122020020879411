export const EPSG_DICT: { [key: number]: string } = {
  4326: 'WGS 84 / WGS84',
  24878: 'PSAD56 / UTM zone 18S',
  24879: 'PSAD56 / UTM zone 19S',
  29188: 'SAD69 / UTM zone 18S',
  29189: 'SAD69 / UTM zone 19S',
  32613: 'WGS 84 / UTM zone 13N',
  32719: 'WGS 84 / UTM zone 19S',
};

export function formatCrsCode(code: number): string {
  if (code in EPSG_DICT) {
    return EPSG_DICT[code];
  }
  return code.toString();
}
