import React from 'react';

export const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => {
  return (e: React.ChangeEvent<HTMLInputElement>) => setter(e.target.value);
};

export const handleInputNumberChange = (setter: React.Dispatch<React.SetStateAction<number>>) => {
  return (e: React.ChangeEvent<HTMLInputElement>) => setter(+e.target.value);
};

export const handleSelectChange = (setter: React.Dispatch<React.SetStateAction<string>>) => {
  return (e: React.ChangeEvent<HTMLSelectElement>) => setter(e.target.value);
};
